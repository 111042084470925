import React, { useState } from "react"
import dashboardStore from "src/GlobalStore"
import {
  CAvatar,
  CCloseButton,
  CFormSwitch,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CListGroup,
  CListGroupItem,
  CProgress,
  CSidebar,
  CSidebarHeader,
  CButton,
  CButtonGroup,
  CFormCheck
} from "@coreui/react-pro"
import CIcon from "@coreui/icons-react"
import { cibSkype, cilCalendar, cilHome, cilList, cilLocationPin, cilSettings, cilSpeech } from "@coreui/icons"

const AppAside = () => {
  const { asideShow, setAsideShow, setSiteKrLanguage, setSiteEnLanguage } = dashboardStore()
  const [activeKey, setActiveKey] = useState(3)

  return (
    <CSidebar
      colorScheme="light"
      size="lg"
      overlaid
      placement="end"
      visible={asideShow}
      onVisibleChange={(visible) => setAsideShow(visible)}
    >
      <CSidebarHeader className="bg-transparent p-0">
        <CNav variant="underline" style={{ height: "47.5px" }}>
          <CNavItem>
            <CNavLink
              href="#"
              style={{ minHeight: "3.72em" }}
              active={activeKey === 3}
              onClick={(e) => {
                e.preventDefault()
                setActiveKey(3)
              }}
            >
              <CIcon icon={cilSettings} alt="CoreUI Icons Settings" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink
              href="#"
              style={{ minHeight: "3.72em" }}
              active={activeKey === 2}
              onClick={(e) => {
                e.preventDefault()
                setActiveKey(2)
              }}
            >
              <CIcon icon={cilList} alt="CoreUI Icons Speech" />
            </CNavLink>
          </CNavItem>
          <CNavItem className="ms-auto me-2 d-flex align-items-center">
            <CCloseButton onClick={() => setAsideShow(false)} />
          </CNavItem>
        </CNav>
      </CSidebarHeader>
      <CTabContent>
        <CTabPane className="p-0" visible={activeKey === 3}>
          <CListGroup flush>
            <CListGroupItem className="list-group-item border-start-4 border-start-secondary bg-light dark:bg-white dark:bg-opacity-10 dark:text-medium-emphasis text-center fw-bold text-medium-emphasis text-uppercase small">
              언어설정
            </CListGroupItem>
            <CListGroupItem href="#" className="border-start-4 border-start-warning">
              <div className="gap-2 col-6 mx-auto">
                <CButton onClick={() => setSiteKrLanguage()} className="me-2 px-3" size="sm" variant="outline" color="primary">
                  한글
                </CButton>
                <CButton onClick={() => setSiteEnLanguage()} className="px-3" size="sm" variant="outline" color="primary">
                  영어
                </CButton>
              </div>
            </CListGroupItem>
            <CListGroupItem className="border-start-4 border-start-secondary bg-light dark:bg-white dark:bg-opacity-10 dark:text-medium-emphasis text-center fw-bold text-medium-emphasis text-uppercase small">
              옵션설정
            </CListGroupItem>
            <CListGroupItem accent="danger" href="#" className="border-start-4 border-start-danger">
              <div>
                <div className="clearfix mt-1">
                  <CFormSwitch size="lg" label="Option 1" id="Option1" defaultChecked />
                </div>
                <div>
                  <small className="text-medium-emphasis">옵션설정1</small>
                </div>
              </div>
            </CListGroupItem>
            <CListGroupItem href="#" className="border-start-4 border-start-success">
              <div>
                <div className="clearfix mt-1">
                  <CFormSwitch size="lg" label="Option 2" id="Option1" defaultChecked />
                </div>
                <div>
                  <small className="text-medium-emphasis">옵션설정2</small>
                </div>
              </div>
            </CListGroupItem>
            <CListGroupItem href="#" className="border-start-4 border-start-primary border-bottom">
              <div>
                <div className="clearfix mt-1">
                  <CFormSwitch size="lg" label="Option 3" id="Option1" />
                </div>
                <div>
                  <small className="text-medium-emphasis">옵션설정3</small>
                </div>
              </div>
            </CListGroupItem>
            <CListGroupItem href="#" className="border-start-4 border-start-info border-bottom">
              <div>
                <div className="clearfix mt-1">
                  <CFormSwitch size="lg" label="Option 4" id="Option1" />
                </div>
                <div>
                  <small className="text-medium-emphasis">옵션설정4</small>
                </div>
              </div>
            </CListGroupItem>
          </CListGroup>
          `
        </CTabPane>
      </CTabContent>
    </CSidebar>
  )
}

export default React.memo(AppAside)
