import { useImageDelete } from "src/common/api/CommonApi"

const useCloudService = () => {
  const { trigger: reqDeleteImg } = useImageDelete()

  const deleteCloudImg = async (imgIds) => {
    if (imgIds.length > 0) {
      try {
        await Promise.all(imgIds.map(async (id) => await reqDeleteImg(id)))
      } catch (error) {
        console.error("delete CloudFlare Img fail...", error)
      }
    }
  }

  return {
    deleteCloudImg
  }
}

export default useCloudService
