import axiosSpring from "src/common/AxiosSpring"
import useSWR from "swr"
import useSWRMutation from "swr/mutation"

export const useClsInfoList = (useYn) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(`/admin/cls/list?use_yn=${useYn}`, fetcher)
  return { data: data?.res_data || [], error, isLoading, mutate, isValidating }
}

//상세
export const useClsDetail = (action, cls_id) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    action !== "insert" && cls_id ? `/admin/cls/detail/${cls_id}` : null,
    fetcher
  )
  return { data: data?.res_data || {}, error, isLoading, mutate, isValidating }
}

//갱신
export const useClsUpdate = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/cls/update`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//삭제
export const useClsDelete = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/cls/delete`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//clsDtl
export const useClsDtlList = (useYn, mem_id) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    mem_id ? `/admin/cls/dtl/list?use_yn=${useYn}&mem_id=${mem_id}` : null,
    fetcher
  )
  return { data: data?.res_data || [], error, isLoading, mutate, isValidating }
}

//갱신
export const useClsDtlUpdate = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/cls/dtl/update`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

export const useClsDtlDetail = (action, cls_dtl_id) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    action !== "insert" && cls_dtl_id ? `/admin/cls/dtl/detail?cls_dtl_id=${cls_dtl_id}` : null,
    fetcher
  )
  return { data: data?.res_data || [], error, isLoading, mutate, isValidating }
}
