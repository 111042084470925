import SITE_KR from "src/assets/language/site.kr"
import SITE_EN from "src/assets/language/site.en"
import GRID_KR from "src/assets/language/grid.kr"
import GRID_EN from "src/assets/language/grid.en"
import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { persist, createJSONStorage } from "zustand/middleware"

const GlobalStore = (set) => ({
  //Language
  lang: "kr",
  siteLanguage: SITE_KR,
  gridLanguage: GRID_KR,
  setSiteKrLanguage: () =>
    set((state) => {
      return { lang: "kr", siteLanguage: SITE_KR, gridLanguage: GRID_KR }
    }),
  setSiteEnLanguage: () =>
    set((state) => {
      return { lang: "en", siteLanguage: SITE_EN, gridLanguage: GRID_EN }
    }),
  //Site Configuration
  nav: "navCompliance",
  theme: "default",
  gridTheme: "",
  sidebarShow: true,
  asideShow: false,
  sidebarUnfoldable: true,
  setNav: (updateNav) => set((state) => ({ nav: updateNav })),
  setTheme: (siteTheme, gridTheme) => set((state) => ({ theme: siteTheme, gridTheme: gridTheme })),
  setSidebarShow: () => set((state) => ({ sidebarShow: !state.sidebarShow })),
  setAsideShow: (updateAside) => set((state) => ({ asideShow: updateAside })),
  setSidebarUnfoldable: () => set((state) => ({ sidebarUnfoldable: !state.sidebarUnfoldable }))
})

const persistConfig = {
  name: "GlobalStore",
  //getStorage: () => localStorage,  //sessionStorage
  //onRehydrateStorage: () => async () => sessionStorage,  //localStorage
  storage: createJSONStorage(() => sessionStorage)
}

const useGlobalStore = create(devtools(persist(GlobalStore, persistConfig), { name: persistConfig.name }))

export default useGlobalStore
