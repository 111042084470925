import React, { useCallback, useEffect, useRef } from "react"
import { v4 as uuidv4 } from "uuid"
import {
  CForm,
  CButton,
  CCard,
  CCardBody,
  CTable,
  CTableBody,
  CTableRow,
  CTableDataCell,
  CFormSelect,
  CFormTextarea,
  CFormInput,
  CModalBody
} from "@coreui/react-pro"

//lib
import moment from "moment"
import "moment/locale/ko"
import { useForm } from "react-hook-form"
import { actionType } from "src/common/grid/GridUtils"

//store
import useGlobalStore from "src/GlobalStore"
import useLoginStore from "src/views/login/store/LoginStore"
import useServiceStore from "src/views/svcInfo/store/ServiceStore"
import useModalStore from "../store/ModalStore"

//service
import { useServiceOne } from "src/views/svcInfo/api/ServiceApi"
import useService from "src/views/svcInfo/hook/useService"

import AppLoading from "src/components/AppLoading"

const ServiceDetailModal = ({ modalData, callDataApi }) => {
  //utils
  const { siteLanguage } = useGlobalStore()
  //store
  const { serviceInfo, setServiceInfo } = useServiceStore()
  const { setCloseModal, modalAction, setModalAction } = useModalStore()
  const { login } = useLoginStore()

  //data
  const { data, isLoading, mutate } = useServiceOne(modalAction, serviceInfo?.svc_id)

  //service
  const { updateService, insertService } = useService()

  const ReadOnly = actionType.SELECT === modalAction ? true : false

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue
  } = useForm({ mode: "onSubmit" })

  const registerOptions = {
    svc_nm: { required: "서비스명은 필수값입니다." },
    svc_prc: { required: "서비스 단가값은 필수값입니다" },
    svc_exp: { required: "서비스 유효기간을 입력해주세요." }
  }

  const onSubmit = useCallback(
    async (submitData, event) => {
      if (modalAction === actionType.UPDATE) {
        submitData["svc_id"] = serviceInfo?.svc_id
        submitData["upd_mem_id"] = login.mem_id
        await updateService(submitData)
      }

      if (modalAction === actionType.INSERT) {
        submitData["reg_mem_id"] = login.mem_id
        submitData["upd_mem_id"] = login.mem_id
        submitData["svc_ord"] = modalData?.length + 1
        const svcId = await insertService(submitData)
        setServiceInfo({ svc_id: svcId })
      }

      mutate(submitData)
      if (callDataApi) {
        await callDataApi()
        setCloseModal()
      }
      setModalAction("select")
    },
    [serviceInfo, modalAction, login, modalData]
  )

  const onError = useCallback(async (e) => {
    console.log(e)
  }, [])

  const clickInfoUpdate = useCallback((e) => {
    e.preventDefault()
    reset()
    setModalAction(actionType.UPDATE)
  }, [])

  const clickInfoReset = useCallback((e) => {
    e.preventDefault()
    reset()
    setModalAction(actionType.SELECT)
  }, [])

  useEffect(() => {
    if (window.opener !== null) {
      document.body.classList.add("popup-no-scrollbars")
    }
  }, [])

  // console.log("모달 높이", modal.current.clientHeight)

  //e:action event =================================================

  if (isLoading) {
    return <AppLoading heightSize={255} />
  }

  return (
    <>
      <CModalBody className="p-1" style={{ width: "1115px" }}>
        <CCard className="shadow-none mb-4">
          <CCardBody className="p-0">
            <CForm onSubmit={handleSubmit(onSubmit, onError)}>
              <CTable color="light" bordered small>
                <CTableBody>
                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray" style={{ width: "130px" }}>
                      서비스명 *{/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormInput
                        {...register("svc_nm", registerOptions.svc_nm)}
                        defaultValue={data?.svc_nm}
                        size="sm"
                        className="lh-15"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      />
                      {errors.svc_nm && <span>{errors.svc_nm.message}</span>}
                    </CTableDataCell>

                    <CTableDataCell className="sf-label" color="gray" style={{ width: "130px" }}>
                      서비스 단가{/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormInput
                        {...register("svc_prc", registerOptions.svc_prc, { valueAsNumber: true })}
                        defaultValue={data?.svc_prc}
                        type="number"
                        size="sm"
                        className="lh-15"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      />
                      {errors.svc_prc && <span>{errors.svc_prc.message}</span>}
                    </CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray" style={{ width: "130px" }}>
                      서비스 설명 {/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormTextarea
                        {...register("svc_dsc")}
                        defaultValue={data?.svc_dsc}
                        size="sm"
                        className="lh-15 ps-2"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      />
                    </CTableDataCell>

                    <CTableDataCell className="sf-label" color="gray" style={{ width: "130px" }}>
                      비고 {/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormTextarea
                        {...register("etc")}
                        defaultValue={data?.etc}
                        size="sm"
                        className="lh-15 ps-2"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      />
                    </CTableDataCell>
                  </CTableRow>

                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray" style={{ width: "130px" }}>
                      사용여부 *{/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormSelect
                        {...register("use_yn")}
                        key={uuidv4()}
                        defaultValue={data?.use_yn}
                        className="form-select lh-18"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      >
                        <option value="Y">Y</option>
                        <option value="N">N</option>
                        <option value="D">D</option>
                      </CFormSelect>
                    </CTableDataCell>

                    <CTableDataCell className="sf-label ms-1" color="gray" style={{ width: "130px" }}>
                      서비스 유효기간 {/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormInput
                        {...register("svc_exp", registerOptions.svc_exp, { valueAsNumber: true })}
                        defaultValue={data?.svc_exp}
                        type="number"
                        size="sm"
                        className="lh-15"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      />
                      {errors.svc_exp && <span>{errors.svc_exp.message}</span>}
                    </CTableDataCell>
                  </CTableRow>

                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray" style={{ width: "130px" }}>
                      등록일 {/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <span className="p-2">{data?.reg_dtm && moment.utc(data?.reg_dtm).locale("ko").format("YYYY-MM-DD")}</span>
                    </CTableDataCell>

                    <CTableDataCell className="sf-label ms-1" color="gray" style={{ width: "130px" }}>
                      수정일 {/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <span className="p-2">{data?.upd_dtm && moment.utc(data?.upd_dtm).locale("ko").format("YYYY-MM-DD")}</span>
                    </CTableDataCell>
                  </CTableRow>
                </CTableBody>
              </CTable>
              <div className="d-flex justify-content-center">
                {
                  {
                    insert: (
                      <span>
                        <CButton onClick={handleSubmit(onSubmit)} color="primary" className="px-4 mx-1" size="sm">
                          {siteLanguage.btnName.save}
                        </CButton>
                        <CButton onClick={() => setCloseModal()} className="px-4" size="sm" color="dark">
                          닫기
                        </CButton>
                      </span>
                    ),
                    select: (
                      <span>
                        <CButton onClick={(e) => clickInfoUpdate(e)} color="primary" className="px-4 mx-1" size="sm">
                          {siteLanguage.btnName.update}
                        </CButton>
                        <CButton onClick={() => setCloseModal()} className="px-4" size="sm" color="dark">
                          닫기
                        </CButton>
                      </span>
                    ),
                    update: (
                      <span>
                        <CButton onClick={handleSubmit(onSubmit)} color="primary" size="sm" className="px-4 mx-1">
                          {siteLanguage.btnName.save}
                        </CButton>
                        <CButton onClick={(e) => clickInfoReset(e, "select", true)} color="dark" className="px-4" size="sm">
                          {siteLanguage.btnName.cancel}
                        </CButton>
                      </span>
                    )
                  }[modalAction]
                }
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CModalBody>
    </>
  )
}

export default ServiceDetailModal
