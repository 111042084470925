import { useVocaCatInsert, useVocaCatUpdate, useVocaCatDelete, useVocaCatOrder, useVocaCatImgDelete } from "../api/VocaCatApi"
import { useMitt } from "react-mitt"
import SITE_MESSAGE from "src/assets/language/site.message"

const useVocaCatService = () => {
  const { trigger: requestInsert, isMutating: isInserting } = useVocaCatInsert()
  const { trigger: requestUpdate, isMutating: isUpdateing } = useVocaCatUpdate()
  const { trigger: requestImgDelete } = useVocaCatImgDelete()
  const { trigger: requestDelete } = useVocaCatDelete()
  const { trigger: requestOrder } = useVocaCatOrder()

  const { emitter } = useMitt()

  const insertVocaCat = async (data) => {
    try {
      const response = await requestInsert(data)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.insert_success, type: 200 })
      }

      return response?.data?.res_data
    } catch (error) {
      console.error("insertService error:", error)
      emitter.emit("alert", { message: SITE_MESSAGE.insert_fail, type: 500 })
    }
  }

  const updateVocaCat = async (data) => {
    try {
      const response = await requestUpdate(data)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.update_success, type: 200 })
      }
    } catch (error) {
      console.error("updateService error:", error)
      emitter.emit("alert", { message: SITE_MESSAGE.update_fail, type: 500 })
    }
  }

  const deleteVocaCat = async (idList) => {
    try {
      if (idList.length === 0) {
        window.alert("삭제할 데이터를 선택해주세요")
        return
      }

      const response = await requestDelete(idList)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.delete_success, type: 200 })
      }
    } catch (error) {
      console.error("deleteService error:", error)
      emitter.emit("alert", { message: SITE_MESSAGE.delete_fail, type: 500 })
    }
  }

  const reOrderThenMutate = async (gridRef, mutate) => {
    const toOrders = gridRef.current.api
      .getModel()
      .rowsToDisplay.map((item) => item.data)
      .map((item, index) => ({ cat_id: item.cat_id, cat_ord: index + 1 }))

    if (toOrders.length === 0) {
      emitter.emit("alert", { message: "정렬할 데이터가 존재하지 않습니다.", type: 500 })
    }
    try {
      await requestOrder(toOrders)

      await mutate()
    } catch (error) {
      console.error("reOrderCatList fail: ", error)
      emitter.emit("alert", { message: "카테고리 리스트 정렬실패했습니다, 관리자에게 문의해주세요.", type: 500 })
    }
  }

  const deleteCategoryImg = async (data) => {
    try {
      const response = await requestImgDelete(data)
      console.log(response)
    } catch (error) {
      console.error("deleteCategoryImg fail: ", error)
      emitter.emit("alert", { message: "이미지 삭제에 실패했습니다.", type: 500 })
    }
  }

  return {
    insertVocaCat,
    updateVocaCat,
    deleteVocaCat,
    isInserting,
    isUpdateing,
    reOrderThenMutate,
    deleteCategoryImg
  }
}

export default useVocaCatService
