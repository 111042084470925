export const gridCommonOptions = () => {
  return {
    animateRows: true,
    pagination: true,
    rowHeight: 30,
    groupHeaderHeight: 30,
    headerHeight: 30,
    floatingFiltersHeight: 30,
    pivotHeaderHeight: 30,
    pivotGroupHeaderHeight: 30,
    enableRangeSelection: true,
    suppressMultiRangeSelection: true,
    tooltipShowDelay: 500,
    tooltipHideDelay: 10000
  }
}

export const svcGridCommonOptions = () => {
  return {
    animateRows: false,
    pagination: true,
    rowHeight: 60,
    groupHeaderHeight: 30,
    headerHeight: 30,
    floatingFiltersHeight: 30,
    pivotHeaderHeight: 30,
    pivotGroupHeaderHeight: 30,
    enableRangeSelection: true,
    suppressMultiRangeSelection: true,
    tooltipShowDelay: 500,
    tooltipHideDelay: 10000
  }
}

export const vocaCatMapCommonOptions = () => {
  return {
    animateRows: false,
    pagination: false,
    rowHeight: 60,
    groupHeaderHeight: 30,
    headerHeight: 30,
    floatingFiltersHeight: 30,
    pivotHeaderHeight: 30,
    pivotGroupHeaderHeight: 30,
    enableRangeSelection: true,
    suppressMultiRangeSelection: true,
    tooltipShowDelay: 500,
    tooltipHideDelay: 10000
  }
}

export const gridSfdCommonOptions = () => {
  return {
    animateRows: true,
    pagination: false,
    rowHeight: 40,
    groupHeaderHeight: 30,
    headerHeight: 30,
    floatingFiltersHeight: 30,
    pivotHeaderHeight: 30,
    pivotGroupHeaderHeight: 30,
    enableRangeSelection: true,
    suppressMultiRangeSelection: true,
    tooltipShowDelay: 500,
    tooltipHideDelay: 10000
  }
}
export const gridSrpOptions = () => {
  return {
    animateRows: true,
    pagination: true,
    rowHeight: 35,
    groupHeaderHeight: 30,
    headerHeight: 30,
    floatingFiltersHeight: 30,
    pivotHeaderHeight: 30,
    pivotGroupHeaderHeight: 30,
    enableRangeSelection: true,
    suppressMultiRangeSelection: true,
    tooltipShowDelay: 500,
    tooltipHideDelay: 10000
  }
}

export const gridReplyOptions = () => {
  return {
    animateRows: true,
    pagination: false,
    rowHeight: 120,
    groupHeaderHeight: 0,
    headerHeight: 0,
    floatingFiltersHeight: 0,
    pivotHeaderHeight: 0,
    pivotGroupHeaderHeight: 0,
    enableRangeSelection: true,
    suppressMultiRangeSelection: true,
    tooltipShowDelay: 500,
    tooltipHideDelay: 10000
  }
}
// 120
export const gridCmtOptions = () => {
  return {
    animateRows: true,
    pagination: false,
    rowHeight: 160,
    groupHeaderHeight: 0,
    headerHeight: 30,
    floatingFiltersHeight: 0,
    pivotHeaderHeight: 0,
    pivotGroupHeaderHeight: 0,
    enableRangeSelection: true,
    suppressMultiRangeSelection: true,
    tooltipShowDelay: 500,
    tooltipHideDelay: 10000
  }
}

export const gridSimpleOptions = () => {
  return {
    animateRows: true,
    pagination: false,
    rowHeight: 26,
    headerHeight: 26,
    enableRangeSelection: true,
    suppressMultiRangeSelection: true,
    tooltipShowDelay: 500,
    tooltipHideDelay: 10000
  }
}

export const gridNoPagingOptions = () => {
  return {
    animateRows: false,
    pagination: false,
    rowHeight: 60,
    groupHeaderHeight: 30,
    headerHeight: 30,
    floatingFiltersHeight: 30,
    pivotHeaderHeight: 30,
    pivotGroupHeaderHeight: 30,
    enableRangeSelection: false,
    suppressMultiRangeSelection: true
  }
}

export const sideBarConfig = () => {
  return {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true
        }
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressExpandAll: true,
          suppressFilterSearch: true
        }
      }
    ],
    defaultToolPanel: "none"
  }
}

export const defaultColConfig = () => {
  return {
    sortable: true,
    resizable: true,
    flex: true,
    suppressMovable: false,
    filter: false,
    floatingFilter: true,
    suppressMenu: true,
    enablePivot: true
  }
}

export const simpleColConfig = () => {
  return {
    sortable: true,
    resizable: true,
    flex: true,
    suppressMovable: false,
    filter: false,
    floatingFilter: false,
    suppressMenu: true,
    enablePivot: false
  }
}

export const editColConfig = () => {
  return {
    sortable: true,
    resizable: true,
    flex: true,
    suppressMovable: false,
    filter: false,
    floatingFilter: true,
    suppressMenu: true,
    enablePivot: true,
    editable: true
    /*
    커스텀셀만들고 이벤트에의해 
    다른셀 실시간변경
    https://www.ag-grid.com/react-data-grid/master-detail-master-rows/
  
  
    suppressKeyboardEvent: (params) => {
      //console.log(params)
          
      const KEY_ENTER = 'Enter'
      const KEY_ESCAPE = 'Escape'
      const key = params.event.key
      let suppress = false
      
      if (key === KEY_ENTER) {
        suppress = true
      }
  
      if (key === KEY_ESCAPE) {
        //params.api.stopEditing(true)
        suppress = true
      }
  
      return suppress
    }*/
  }
}

export const noFloatFilterColConfig = () => {
  return {
    sortable: true,
    resizable: true,
    flex: true,
    suppressMovable: false,
    filter: false,
    floatingFilter: true,
    suppressMenu: true,
    enablePivot: true
  }
}

export const autoGroupColumnDef = {
  headerName: "Group",
  width: 100,
  minWidth: 100,
  cellRenderer: "agGroupCellRenderer",
  cellClass: "centerCell",
  showRowGroup: true,
  cellRendererParams: {
    checkbox: true
  }
}

export const excellStyles = () => {
  return [
    {
      id: "pupleBackGround",
      interior: {
        color: "#ffdead",
        pattern: "Solid"
      },
      borders: {
        borderBottom: {
          color: "#000000",
          lineStyle: "Continuous",
          weight: 1
        }
      }
    },
    {
      id: "greenBackGround",
      interior: {
        color: "#fffacd",
        pattern: "Solid"
      },
      borders: {
        borderBottom: {
          color: "#000000",
          lineStyle: "Continuous",
          weight: 1
        }
      }
    }
  ]
}
