import React, { Suspense } from "react"
import { HashRouter, Route, Routes } from "react-router-dom"
import "./scss/style.scss"
import { SWRConfig } from "swr"
import Modal from "src/views/modal/Modal"
import useModalStore from "src/views/modal/store/ModalStore"
import { actionType } from "./common/grid/GridUtils"

import AppLoading from "./components/AppLoading"
import DefaultLayout from "./layout/DefaultLayout"

const loading = (
  <div className="pt-3 text-center d-flex">
    <div className="justify-content-center sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
//const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"))

// Pages
const Login = React.lazy(() => import("./views/login/Login"))
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"))
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"))

const App = () => {
  const { setShowModal } = useModalStore()

  const onErrorRetry = (error, key, config, revalidate, { retryCount }) => {
    console.log(retryCount)
    if (error?.status === 404) return

    if (retryCount === 1) {
      //하나 쉴드와 Spring서버 에러응답 메시지가 달라 분기처리함
      if (error.config.url !== `http://${process.env.REACT_APP_URL_HANASHIELD}`) {
        setShowModal({
          modalName: "ErrorModal",
          showModal: true,
          modalData: error,
          modalSize: "lg",
          modalTopPosition: "20px",
          modalAction: actionType.SELECT
        })
      }
    }

    if (retryCount >= 2) return
    setTimeout(() => revalidate({ retryCount }), 5000)
  }

  return (
    <>
      <SWRConfig value={{ onErrorRetry: onErrorRetry, revalidateOnFocus: true, suspense: false, revalidateOnMount: true }}>
        <HashRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/admin_login" name="Login Page" element={<Login />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </HashRouter>
      </SWRConfig>
      <Modal />
    </>
  )
}

export default App
