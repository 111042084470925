import { create } from "zustand"
import { devtools } from "zustand/middleware"

const GridStore = (set, get) => ({
  pageName: "",
  useYn: { yn: "Y", desc: "사용여부 - 사용" },
  dataSearch: "",
  windowHeight: window.innerHeight,

  searchToggle: [
    { id: 1, name: "사용여부 - 사용", value: "Y", dsc: "사용여부 - 사용" },
    { id: 2, name: "사용여부 - 미사용", value: "N", dsc: "사용여부 - 미사용" },
    { id: 3, name: "사용여부 - 삭제", value: "D", dsc: "사용여부 - 삭제" },
    { id: 4, name: "사용여부 - 모든", value: "All", dsc: "사용여부 - 모든" }
  ],

  defaultOption: [
    { value: "Y", label: "Y" },
    { value: "N", label: "N" },
    { value: "D", label: "D" }
  ],

  setDataSearch: (_search) => set((state) => ({ dataSearch: _search })),
  setWindowHeight: (_windowHeight) => set((state) => ({ windowHeight: _windowHeight })),

  /* S: Grid Store ========================================================================*/
  gridObject: {
    rowPosition: 0,
    pageState: 0,
    gridState: null,
    groupState: null,
    gridFilterState: null
  },

  resetAllState: () =>
    set((state) => ({
      gridObject: { rowPosition: 0, pageState: 0, gridState: null, groupState: null, gridFilterState: null },
      useYn: { yn: "Y", desc: "사용여부 - 사용" },
      dataSearch: ""
    })),

  setGridStateOnReload: (_gridRef) => {
    _gridRef.current.api.setFilterModel(null)
    _gridRef.current.columnApi.resetColumnState()
    _gridRef.current.api.onFilterChanged()
    _gridRef.current.api.deselectAll()
    _gridRef.current.columnApi.applyColumnState({
      defaultState: { sort: null }
    })
    _gridRef.current.api.setDeltaSort(true)
    _gridRef.current.api.setQuickFilter("")
    _gridRef.current.api.sizeColumnsToFit()
    _gridRef.current.api.stopEditing(true)
    _gridRef.current.api.ensureIndexVisible(0)
    _gridRef.current.api.paginationGoToPage(0)
    set((state) => ({
      gridObject: {
        rowPosition: 0,
        pageState: 0,
        gridState: null,
        groupState: null,
        gridFilterState: null
      },
      use_yn: { useyn: "Y", desc: "사용여부 - 사용" },
      dataSearch: ""
    }))
  },

  setRowDoubleClicked: (_params) => {
    set((state) => ({
      gridObject: {
        pageState: _params.api.paginationGetCurrentPage(),
        gridState: _params.columnApi.getColumnState(),
        groupState: _params.columnApi.getColumnGroupState(),
        gridFilterState: _params.api.getFilterModel(),
        rowPosition: _params.rowIndex
      }
    }))
  },

  onGridReadyStore: (_params, _pageName) => {
    console.log(`${_pageName} - onGridReady`)
    set((state) => {
      state.pageName = _pageName
      _params.api.paginationGoToPage(state.gridObject.pageState)

      return state
    })
  },

  onFirstDataRenderedStore: (_params) => {
    set((state) => {
      console.log(`${state.pageName} - onFirstDataRendered`)

      if (state.gridObject.groupState) {
        _params.columnApi.setColumnGroupState(state.gridObject.groupState)
      }
      // _params.columnApi.applyColumnState({
      //   state: state.gridObject.gridState,
      //   applyOrder: true
      // })
      _params.api.ensureIndexVisible(state.gridObject.rowPosition, "middle")
      _params.api.setFilterModel(state.gridObject.gridFilterState)
      _params.api.setQuickFilter(state.dataSearch)

      return state
    })
  },

  onRowDataUpdatedStore: (_isLoading, _params) => {
    set((state) => {
      console.log(`${state.pageName} - rowDataUpdated`)
      /*if (_isLoading) {
        _params.api.showLoadingOverlay()
      }*/

      return state
    })
  },

  onModelUpdatedStore: (_params) => {
    set((state) => {
      console.log(`${state.pageName} - onModelUpdated`)
      if (_params && _params.api) {
        _params.api.sizeColumnsToFit()
      }
      return state
    })
  },

  onSortChanged: (_params) => {
    set((state) => {
      console.log(`${state.pageName} - onSortChanged`)
      _params.api.refreshCells()
      return state
    })
  },

  onFilterChanged: (_params) => {
    set((state) => {
      console.log(`${state.pageName} - onFilterChanged`)
      _params.api.refreshCells()
      return state
    })
  },

  onGridSizeChanged: (_params) => {
    set((state) => {
      console.log(`${state?.pageName} - onGridSizeChanged`)
      if (_params) {
        _params?.api?.sizeColumnsToFit(_params)
      }
      return state
    })
  },

  onColumnRowGroupChanged: (_params) => {
    console.log("onColumnRowGroupChanged")
    set((state) => {
      console.log(`${state.pageName} - onColumnRowGroupChanged`)
      if (_params.columns.length === 0) {
        _params.columnApi.setColumnVisible("id", true)
      } else {
        _params.columnApi.setColumnVisible("id", false)
      }
      _params.api.sizeColumnsToFit()
      return state
    })
  },

  onToolPanelVisibleChanged: (_params) => {
    console.log("onToolPanelVisibleChanged")
    if (_params) {
      _params.api.sizeColumnsToFit()
    }
  },

  onUseYnChange: (_useyn, _desc) => {
    set((state) => {
      console.log(`${state.pageName} - onUseYnChange`, _useyn, _desc)
      return { useYn: { yn: _useyn, desc: _desc } }
    })
  },

  onSearchChange: (_event) => {
    _event.preventDefault()
    set((state) => {
      console.log(`${state.pageName} - onSearchChange`, _event.target.value)
      return { dataSearch: _event.target.value }
    })
  },

  onKeyDown: (_event, _gridRef) => {
    if (_event.key === "Enter") {
      _gridRef.current.api.setQuickFilter(_event.target.value)
    } else if (_event.target.value === "") {
      _gridRef.current.api.setQuickFilter("")
    }
  }
  /* e: Grid Store ========================================================================*/
})

const useGridStore = create(devtools(GridStore, { name: "GridStore" }))

export default useGridStore
