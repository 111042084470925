import React, { useCallback, useMemo, useEffect, forwardRef, useRef } from "react"
import { useMitt } from "react-mitt"
//ui component
import { CRow, CCol, CContainer, CModalBody, CButton } from "@coreui/react-pro"

import { AgGridReact } from "ag-grid-react"
import "ag-grid-enterprise"
import "ag-grid-enterprise/dist/styles/ag-grid.css"
import "ag-grid-enterprise/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise/dist/styles/ag-theme-alpine-dark.css"

//utiles
import { sideBarConfig, defaultColConfig, autoGroupColumnDef, svcGridCommonOptions } from "src/common/grid/GridConfig"
import sysCodeInfo from "src/assets/system/system.info.json"

//store
import useGlobalStore from "src/GlobalStore"
import useGridStore from "src/common/grid/store/GridStore"
import useLoginStore from "src/views/login/store/LoginStore"
import useVocaStore from "src/views/vocaInfo/store/VocaStore"
import useModalStore from "../store/ModalStore"

//api
import { useServiceCatList } from "src/views/svcInfo/api/ServiceApi"
import { useServiceList } from "src/views/svcInfo/api/ServiceApi"
import { useCatListVocaMapInsert, useSyncVocaCatInfo } from "src/views/vocaCatMap/api/VocaCatMapApi"

//componet
import { withSearch } from "src/common/board/page/BoardParts"

const MasterDetailGrid = forwardRef(({ callDataApi, modalData }, ref) => {
  const pageName = "MasterDetailGridModal"

  const { emitter } = useMitt()
  const { siteLanguage, gridLanguage } = useGlobalStore()
  const { trigger: requestDetailData } = useServiceCatList()
  const { action, setVocaCatInfo } = useVocaStore()
  const { setCloseModal } = useModalStore()

  const auth = "S"

  const {
    useYn,
    windowHeight,
    setWindowHeight,
    onColumnRowGroupChanged,
    onRowDataUpdatedStore,
    onSortChanged,
    onFilterChanged,
    onGridSizeChanged,
    onModelUpdatedStore
  } = useGridStore()

  const { data: listData, isLoading: listLoading, mutate: listMutate } = callDataApi(useYn.yn)
  const { trigger: reqMapping } = useCatListVocaMapInsert()
  const { trigger: reqSync } = useSyncVocaCatInfo()

  const { login } = useLoginStore()

  const columnDefs = useMemo(
    () => [
      {
        field: "",
        width: 30,
        minWidth: 30,
        maxWidth: 30,
        editable: false,
        hide: false
        // headerCheckboxSelection: (params) => {
        //   return true
        // },
        //checkboxSelection: (params) => !params.node.group
        // checkboxSelection: (params) => true
      },
      {
        headerName: siteLanguage.gridDefult.no,
        field: "id",
        cellStyle: { textAlign: "right", paddingLeft: "2px", paddingRight: "2px" },
        valueGetter: "node.rowIndex + 1",
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        cellRenderer: (params) => {
          return <div className="mt-2">{params.value}</div>
        },
        getQuickFilterText: () => ""
      },
      {
        headerName: "ID",
        field: "svc_id",
        hide: true,
        width: 100,
        minWidth: 100,
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "서비스 명",
        field: "svc_nm",
        tooltipField: "svc_nm",
        width: 120,
        minWidth: 120,
        enableRowGroup: true
        // cellRenderer: "agGroupCellRenderer"
        // rowGroup: true
        // cellRenderer: (params) => {
        //   return <strong>{params.value}</strong>
        // },
        // filter: "agTextColumnFilter"
      },
      {
        headerName: "서비스 내용",
        field: "svc_dsc",
        tooltipField: "svc_dsc",
        width: 240,
        minWidth: 240,
        enableRowGroup: true,
        cellRenderer: (params) => {
          return <div>{params.value}</div>
        },
        filter: "agTextColumnFilter"
      },
      {
        headerName: "서비스 단가",
        field: "svc_prc",
        tooltipField: "svc_prc",
        width: 100,
        minWidth: 100,
        enableRowGroup: true,
        cellRenderer: (params) => {
          return <div>{`${params?.value?.toLocaleString("ko-KR")} 원`}</div>
        },
        filter: "agTextColumnFilter"
      },
      {
        headerName: "서비스 유효기간",
        field: "svc_exp",
        tooltipField: "svc_exp",
        width: 100,
        minWidth: 100,
        enableRowGroup: true,
        cellRenderer: (params) => {
          return <div>{`${params.value} 개월`}</div>
        },
        filter: "agTextColumnFilter"
      }
      // {
      //   headerName: siteLanguage.gridDefult.useYn,
      //   field: "use_yn",
      //   headerClass: "ag-center-header",
      //   width: 60,
      //   minWidth: 60,
      //   maxWidth: 80,
      //   filter: true,
      //   hide: auth ? false : true,
      //   cellRenderer: (params) => {
      //     return <div className="mt-2">{params.value}</div>
      //   },
      //   getQuickFilterText: () => ""
      // }
    ],
    [siteLanguage, auth]
  )

  //s 필수 Grid처리 ===============================================================================
  const onGridReady = useCallback(
    (params) => {
      //onGridReadyStore(params, pageName)
    },
    [listData]
  )

  const onFirstDataRendered = useCallback(
    (params) => {
      // onFirstDataRenderedStore(params)
    },
    [listData, ref]
  )

  const onRowDataUpdated = useCallback(
    (params) => {
      onRowDataUpdatedStore(listLoading, params)
    },
    [listLoading]
  )

  const onModelUpdated = useCallback((params) => {
    onModelUpdatedStore(params)
  }, [])

  const onRowDoubleClicked = useCallback((params) => {
    if (params.data) {
    }
  }, [])

  const getCheckboxs = async () => {
    let list = []
    ref.current.api.forEachDetailGridInfo((d) => {
      d.api.forEachNode((n) => {
        if (n.isSelected()) {
          list.push(n.data)
        }
      })
    })
    //insert 일때는... id 값을 받아와야 한다.
    const formatData = list.map((item) => {
      return { cat_id: item.cat_id, voca_id: Number(modalData.voca_id), reg_mem_id: login.mem_id, upd_mem_id: login.mem_id }
    })

    if (action === "insert") {
      console.log("여기는 등록 공간입니다. 임시 저장해서 단어 등록 후 보낼 데이터 입니다.")
      console.log(formatData)
      setVocaCatInfo(formatData)
      emitter.emit("alert", { message: "연동되었습니다.", type: 200 })
    }

    if (action !== "insert") {
      try {
        const response = await reqSync(formatData)

        response.data.res_code === "200" && emitter.emit("alert", { message: "해당 내용이 반영되었습니다.", type: 200 })
        await modalData.mutate()
      } catch (error) {
        console.error(error)
        emitter.emit("alert", { message: "반영에 실패했습니다. 관리자에게 문의해주세요.", type: 500 })
      }
    }

    setCloseModal()
  }

  //e 필수 Grid처리 ===============================================================================

  useEffect(() => {
    window.addEventListener("resize", () => setWindowHeight(window.innerHeight))
    if (window.opener !== null) {
      document.body.classList.add("popup-no-scrollbars")
    }
    return () => {
      window.removeEventListener("resize", () => setWindowHeight(window.innerHeight))
    }
  }, [window.innerHeight])

  const detailCellRendererParams = useMemo(
    (gridParams) => {
      // console.log(ref)
      return {
        detailGridOptions: {
          rowSelection: "multiple",
          suppressRowClickSelection: true,
          enableRangeSelection: true,
          // suppressMultiRangeSelection: true,
          // detail grid columns
          columnDefs: [
            {
              field: "",
              width: 30,
              minWidth: 30,
              maxWidth: 30,
              editable: false,
              autoHeight: true,
              headerCheckboxSelection: () => {
                return true
              },
              checkboxSelection: (params) => true
            },
            {
              headerName: "ID",
              field: "cat_id",
              hide: true,
              width: 100,
              minWidth: 100,
              filter: "agNumberColumnFilter"
            },
            {
              headerName: "유형",
              field: "cat_typ",
              tooltipField: "cat_typ",
              width: 100,
              minWidth: 100,
              filter: "agTextColumnFilter",
              cellRenderer: (params) => {
                const catTypeObject = sysCodeInfo?.filter((code) => code.cod_mgr_no === "CAT0001" && code.cod_cd === params.value)
                return (
                  <div
                    className={`rounded-pill ${catTypeObject[0]?.cod_etc} text-white h-50 d-flex justify-content-center align-items-center`}
                    style={{ width: "80px" }}
                  >
                    {catTypeObject[0]?.cod_dsc}
                  </div>
                )
              }
            },
            {
              headerName: "카테고리 명",
              field: "cat_nm",
              tooltipField: "cat_nm",
              width: 100,
              minWidth: 120,
              enableRowGroup: true,
              filter: "agTextColumnFilter",
              cellRenderer: (params) => {
                return (
                  <strong
                    className="align-items-center"
                    style={{
                      border: "0px solid silver",
                      borderRadius: 20,
                      padding: "3px 10px",
                      // backgroundColor: "white",
                      color: "black"
                    }}
                  >
                    <span>{params.value}</span>
                  </strong>
                )
              }
            },
            {
              headerName: "설명",
              field: "cat_dsc",
              tooltipField: "cat_dsc",
              width: 250,
              minWidth: 120,
              enableRowGroup: true,
              filter: "agTextColumnFilter",
              cellRenderer: (params) => {
                return (
                  <div
                    className="align-items-center"
                    style={{
                      border: "0px solid silver",
                      borderRadius: 20,
                      padding: "3px 10px",
                      // backgroundColor: "white",
                      color: "black"
                    }}
                  >
                    <span>{params.value}</span>
                  </div>
                )
              }
            }
          ],
          defaultColDef: {
            flex: 1,
            rowSelection: "multiple"
          },
          onFirstDataRendered: (params) => {
            if (action !== "insert") {
              ref.current.api.forEachDetailGridInfo((parent) => {
                parent.api.forEachNode((node) => {
                  modalData.mapData.forEach((item) => {
                    if (item.cat_id === node.data.cat_id) {
                      node.setSelected(true)
                    }
                  })
                })
              })
            }
          }
        },
        getDetailRowData: async (params) => {
          const response = await requestDetailData({ svc_id: params.data.svc_id, use_yn: "Y" })
          params.successCallback(response.data.res_data)
        }
      }
    },
    [siteLanguage]
  )

  if (listData.length === 0) {
    ref.current?.api?.showNoRowsOverlay()
  }

  return (
    <>
      <CModalBody className="pt-1">
        <CContainer>
          <CRow>
            <div className="px-0 d-flex justify-content-end mb-1">
              <CButton onClick={() => getCheckboxs()} size="sm" color="primary" className="px-4" style={{ width: "80px" }}>
                적용
              </CButton>
            </div>
            <CCol className="p-0">
              <div className="ag-theme-alpine" style={{ height: `${windowHeight - 239}px` }}>
                <AgGridReact
                  ref={ref}
                  rowData={listData}
                  localeText={gridLanguage}
                  //Grid Config
                  columnDefs={columnDefs}
                  sideBar={useMemo(() => sideBarConfig())}
                  defaultColDef={useMemo(() => defaultColConfig())}
                  gridOptions={useMemo(() => svcGridCommonOptions())}
                  rowGroupPanelShow="always"
                  //Grid Group Config
                  autoGroupColumnDef={autoGroupColumnDef}
                  //groupMaintainOrder={true}
                  //groupDisplayType={'singleColumn'}
                  //suppressDragLeaveHidesColumns={true}
                  //suppressMakeColumnVisibleAfterUnGroup={true}
                  //suppressRowGroupHidesColumns={true}
                  //groupHideOpenParents={true}
                  //masterDetail={false}
                  groupSelectsChildren={true}
                  suppressAggFuncInHeader={true}
                  // onColumnRowGroupChanged={onColumnRowGroupChanged}

                  //Grid 이벤트
                  onRowDataUpdated={onRowDataUpdated}
                  onGridReady={onGridReady}
                  onFirstDataRendered={onFirstDataRendered}
                  onGridSizeChanged={onGridSizeChanged}
                  onRowDoubleClicked={onRowDoubleClicked}
                  onSortChanged={onSortChanged}
                  onFilterChanged={onFilterChanged}
                  onColumnRowGroupChanged={onColumnRowGroupChanged}
                  onModelUpdated={onModelUpdated}
                  //onPaginationChanged={useCallback((params) => onPaginationChanged(params))}
                  //onCellClicked={onCellClicked}
                  //Master, Detail설정
                  masterDetail={true}
                  detailCellRendererParams={detailCellRendererParams}
                  groupDefaultExpanded={-1}
                  detailRowAutoHeight={true}
                  autoHeight={true}
                  //DateComponent

                  //그외설정
                  //overlayNoRowsTemplate={"No Data"}
                  rowSelection={"multiple"}
                  suppressRowClickSelection={true}
                  suppressContextMenu={true}
                  checkboxSelection={true}
                  isRowSelectable={() => {
                    return true
                  }}
                />
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
    </>
  )
})

// export default MasterDetailGridModal

const MasterDetailGridModal = ({ modalData: vocaId }) => {
  const gridRef = useRef(null)
  const MasterGridWithSearch = withSearch(MasterDetailGrid)

  return <MasterGridWithSearch ref={gridRef} callDataApi={useServiceList} modalData={vocaId} />
}

export default MasterDetailGridModal
