import React, { useState, forwardRef, useCallback } from "react"
import { useNavigate, useLocation } from "react-router-dom"
//ui component
import {
  CRow,
  CCol,
  CHeader,
  CHeaderNav,
  CButton,
  CInputGroup,
  CFormInput,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle
} from "@coreui/react-pro"
import CIcon from "@coreui/icons-react"
import { cilReload, cilMediaStop } from "@coreui/icons"

//component
import BoardGrid from "./BoardGrid"
import AppBreadcrumb from "src/components/AppBreadcrumb"
import AppHeaderBreadcrumb from "src/components/AppHeaderBreadcrumb"
import usePathStore from "src/common/store/PathStore"

//store
import useGridStore from "src/common/grid/store/GridStore"

export const withHeader = (Component) => {
  return forwardRef((props, ref) => {
    //각각 리스트 마다 필요한 파라미터를 잘 넘겨줘야 한다.
    const { useYn } = useGridStore()
    const { mutate } = props.board_api(useYn.yn, props.board_key)

    const clickDataRegist = () => {
      props.board_insert()
    }

    const clickDeleteData = async () => {
      await props.board_delete(ref)
      await mutate()
    }

    return (
      <>
        <CHeader className={`${window.opener !== null ? "header-content-popup" : "header-content"}`}>
          <CHeaderNav className="d-none d-md-flex me-auto">
            <CIcon icon={cilMediaStop} size="sm" style={{ margin: "5px 4px 0px 0", "--ci-primary-color": "#999" }} />
            <strong>{props.board_name}</strong>
          </CHeaderNav>
          <CHeaderNav className="py-1 me-3">
            <CButton onClick={() => clickDataRegist()} size="sm" color="primary" className="px-4 mx-1">
              등록
            </CButton>
            <CButton onClick={() => clickDeleteData()} size="sm" color="danger" className="px-4">
              삭제
            </CButton>
          </CHeaderNav>
        </CHeader>
        <Component {...props} ref={ref} />
      </>
    )
  })
}

export const withDeleteHeader = (Component) => {
  return forwardRef((props, ref) => {
    //각각 리스트 마다 필요한 파라미터를 잘 넘겨줘야 한다.
    const { useYn } = useGridStore()
    const { mutate } = props.board_api(useYn.yn)

    const clickDeleteData = async () => {
      await props.board_delete(ref)
      await mutate()
    }

    return (
      <>
        <CHeader className={`${window.opener !== null ? "header-content-popup" : "header-content"}`}>
          <CHeaderNav className="d-none d-md-flex me-auto">
            <CIcon icon={cilMediaStop} size="sm" style={{ margin: "5px 4px 0px 0", "--ci-primary-color": "#999" }} />
            <strong>{props.board_name}</strong>
          </CHeaderNav>
          <CHeaderNav className="py-1 me-3">
            <CButton onClick={() => clickDeleteData()} size="sm" color="danger" className="px-4">
              삭제
            </CButton>
          </CHeaderNav>
        </CHeader>
        <Component {...props} ref={ref} />
      </>
    )
  })
}

export const withBreadCrumHeader = (Component) => {
  return forwardRef((props, ref) => {
    //각각 리스트 마다 필요한 파라미터를 잘 넘겨줘야 한다.
    const { useYn } = useGridStore()
    const { mutate } = props.board_api(useYn.yn)

    const clickDataRegist = () => {
      props.board_insert()
    }

    const clickDeleteData = async () => {
      await props.board_delete(ref)
      await mutate()
    }

    return (
      <>
        <CHeader className={`${window.opener !== null ? "header-content-popup" : "header-content"}`}>
          <CHeaderNav className="d-none d-md-flex me-auto">
            <CIcon icon={cilMediaStop} size="sm" style={{ margin: "5px 4px 0px 0", "--ci-primary-color": "#999" }} />
            {/* <strong>{props.board_name}</strong> */}
            {/* <AppBreadcrumb /> */}
            <AppHeaderBreadcrumb />
          </CHeaderNav>
          <CHeaderNav className="py-1 me-3">
            <CButton onClick={() => clickDataRegist()} size="sm" color="primary" className="px-4 mx-1">
              등록
            </CButton>
            <CButton onClick={() => clickDeleteData()} size="sm" color="danger" className="px-4">
              삭제
            </CButton>
          </CHeaderNav>
        </CHeader>
        <Component {...props} ref={ref} />
      </>
    )
  })
}

export const withBreadCrumAndNavigate = (Component) => {
  return forwardRef((props, ref) => {
    //각각 리스트 마다 필요한 파라미터를 잘 넘겨줘야 한다.
    const { useYn } = useGridStore()
    //const { mutate } = props.board_api(useYn.yn)
    const navigate = useNavigate()
    const location = useLocation()

    const clickDataRegist = () => {
      props.board_insert()
    }

    const clickDeleteData = async () => {
      await props.board_delete(ref)
      await props.mutate()
    }

    // const navigateToList = (headerName) => {
    //   const locations = location.state.path.split("/")
    //   const oldPath = locations[0]
    //   console.log(locations)
    //   console.log(oldPath)
    //   //카테고리 뒤에는 서비스 관리
    //   navigate(props.board_navigate, { state: { path: headerName } })
    // }

    return (
      <>
        <CHeader className={`${window.opener !== null ? "header-content-popup" : "header-content"}`}>
          <CHeaderNav className="d-none d-md-flex me-auto">
            <CIcon icon={cilMediaStop} size="sm" style={{ margin: "5px 4px 0px 0", "--ci-primary-color": "#999" }} />
            {/* <strong>{props.board_name}</strong> */}
            {/* <AppBreadcrumb /> */}
            <AppHeaderBreadcrumb />
          </CHeaderNav>
          <CHeaderNav className="py-1 me-3">
            <CButton onClick={() => clickDataRegist()} size="sm" color="primary" className="px-4">
              등록
            </CButton>
            <CButton onClick={() => clickDeleteData()} size="sm" color="danger" className="px-4 mx-1">
              삭제
            </CButton>
            <CButton onClick={() => props.board_navigate()} size="sm" color="dark" className="px-4">
              목록
            </CButton>
          </CHeaderNav>
        </CHeader>
        <Component {...props} ref={ref} />
      </>
    )
  })
}

export const withInsertHeader = (Component) => {
  return forwardRef((props, ref) => {
    return (
      <>
        <CHeader className={`${window.opener !== null ? "header-content-popup" : "header-content"}`}>
          <CHeaderNav className="d-none d-md-flex me-auto">
            <CIcon icon={cilMediaStop} size="sm" style={{ margin: "5px 4px 0px 0", "--ci-primary-color": "#999" }} />
            <strong>{props.board_name}</strong>
          </CHeaderNav>
          <CHeaderNav className="py-1 me-3">
            <CButton onClick={() => props.clickDataRegist()} size="sm" color="primary" className="px-4 mx-1">
              등록
            </CButton>
          </CHeaderNav>
        </CHeader>
        <Component {...props} ref={ref} />
      </>
    )
  })
}

export const withSearch = (Component) => {
  return forwardRef((props, ref) => {
    const { useYn, searchToggle, setGridStateOnReload, onUseYnChange, onKeyDown } = useGridStore()

    const [dataSearch, setDataSearch] = useState("")

    const clickDataReload = useCallback(() => {
      console.log(`${props.board_key} - clickDataReload`)
      setGridStateOnReload(ref)
    }, [])

    const searchChange = useCallback((e) => {
      setDataSearch(e.target.value)
    }, [])

    //검색바 엔터처리
    const searchKeyDown = useCallback((e) => {
      onKeyDown(e, ref)
    }, [])

    return (
      <>
        <CRow>
          <CCol className="px-3 py-2 mb-2 border" style={{ marginLeft: "12.5px", marginRight: "12.5px" }}>
            <CRow>
              <CCol xs={12}>
                <CInputGroup size="sm">
                  <CButton size="sm" className="px-3" onClick={() => clickDataReload()}>
                    <CIcon icon={cilReload} />
                  </CButton>
                  <CDropdown variant="input-group">
                    <CDropdownToggle
                      disabled={false}
                      color="secondary"
                      variant="outline"
                      className="px-3"
                      style={{ fontSize: "0.8em" }}
                    >
                      {useYn.desc}
                    </CDropdownToggle>
                    <CDropdownMenu>
                      {searchToggle.map((item, index) => (
                        <CDropdownItem
                          key={index}
                          onClick={() => onUseYnChange(item.value, item.dsc)}
                          style={{ fontSize: "0.8em" }}
                        >
                          {item.name}
                        </CDropdownItem>
                      ))}
                    </CDropdownMenu>
                  </CDropdown>

                  <CFormInput
                    onChange={(e) => searchChange(e)}
                    onKeyUp={(e) => searchKeyDown(e)}
                    value={dataSearch}
                    placeholder="Search"
                  />
                  <CButton type="button" color="primary" id="button-addon2" className="px-4">
                    검색
                  </CButton>
                </CInputGroup>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <Component {...props} ref={ref} />
      </>
    )
  })
}

export const withVideoSearch = (Component) => {
  return forwardRef((props, ref) => {
    const { useYn, searchToggle, setGridStateOnReload, onUseYnChange, onKeyDown } = useGridStore()

    const [dataSearch, setDataSearch] = useState("")

    const clickDataReload = useCallback(() => {
      console.log(`${props.board_key} - clickDataReload`)
      setGridStateOnReload(ref)
    }, [])

    const searchChange = useCallback((e) => {
      setDataSearch(e.target.value)
    }, [])

    //검색바 엔터처리
    const searchKeyDown = useCallback((e) => {
      onKeyDown(e, ref)
    }, [])

    return (
      <>
        <CRow>
          <CCol className="px-3 py-2 mb-2 border" style={{ marginLeft: "12.5px", marginRight: "12.5px" }}>
            <CRow>
              <CCol xs={12}>
                <CInputGroup size="sm">
                  <CButton size="sm" className="px-3" onClick={() => clickDataReload()}>
                    <CIcon icon={cilReload} />
                  </CButton>
                  <CDropdown variant="input-group">
                    <CDropdownToggle
                      disabled={true}
                      color="secondary"
                      variant="outline"
                      className="px-3"
                      style={{ fontSize: "0.8em" }}
                    >
                      {useYn.desc}
                    </CDropdownToggle>
                    <CDropdownMenu>
                      {searchToggle.map((item, index) => (
                        <CDropdownItem
                          key={index}
                          onClick={() => onUseYnChange(item.value, item.dsc)}
                          style={{ fontSize: "0.8em" }}
                        >
                          {item.name}
                        </CDropdownItem>
                      ))}
                    </CDropdownMenu>
                  </CDropdown>

                  <CFormInput
                    onChange={(e) => searchChange(e)}
                    onKeyUp={(e) => searchKeyDown(e)}
                    value={dataSearch}
                    placeholder="Search"
                  />
                  <CButton type="button" color="primary" id="button-addon2" className="px-4">
                    검색
                  </CButton>
                </CInputGroup>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <Component {...props} ref={ref} />
      </>
    )
  })
}

export const Grid = forwardRef((props, ref) => {
  return <BoardGrid {...props} ref={ref} />
})
