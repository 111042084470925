import { useMemberDelete, useMemberUpdate, useMemberUpdatePassword, useMgrUpdatePassword } from "../api/MemberApi"
import { useImageUploadUrl, useImageDelete } from "src/common/api/CommonApi"
import { useMitt } from "react-mitt"
import SITE_MESSAGE from "src/assets/language/site.message"

import axios from "axios"

const useMemberService = () => {
  const { trigger: requestUpdateMember } = useMemberUpdate()
  const { trigger: requestUpdatePassword } = useMemberUpdatePassword()
  const { trigger: requestMgrUpdatePassword } = useMgrUpdatePassword()
  const { trigger: requestDeleteMember } = useMemberDelete()

  //img api
  const { trigger: reqUploadImageUrl } = useImageUploadUrl()
  const { trigger: reqImageDelete } = useImageDelete()

  const { emitter } = useMitt()

  const deleteMember = async (data) => {
    try {
      if (data.length === 0) {
        window.alert("삭제할 데이터를 선택해주세요")
        return
      }
      const memIdList = data.map((item) => item.mem_id)

      const response = await requestDeleteMember(memIdList)
      console.log("delete Admin response: ", response)

      if (response.status === 200) {
        emitter.emit("alert", { message: `성공적으로 ${response.data.res_data}건을 삭제했습니다.`, type: 200 })
      }
    } catch (error) {
      console.error("insertAdmin error: ", error)
      emitter.emit("alert", { message: SITE_MESSAGE.delete_fail, type: 500 })
    }
  }

  const updateMember = async (data) => {
    try {
      console.log("update data", data)
      const response = await requestUpdateMember(data)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.update_success, type: 200 })
      }
      console.log("udpate admin response: ", response)
    } catch (error) {
      console.error("update admin error: ", error)
      emitter.emit("alert", { message: SITE_MESSAGE.update_fail, type: 500 })
    }
  }

  const updateMemberPassword = async (data) => {
    try {
      const response = await requestUpdatePassword(data)
      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.update_success, type: 200 })
      }
      console.log("udpate admin response: ", response)
    } catch (error) {
      console.error("update admin error: ", error)
      emitter.emit("alert", { message: SITE_MESSAGE.update_fail, type: 500 })
    }
  }

  const updateMgrPassword = async (data) => {
    try {
      const response = await requestMgrUpdatePassword(data)
      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.update_success, type: 200 })
      }
      console.log("udpate admin response: ", response)
    } catch (error) {
      console.error("update admin error: ", error)
      emitter.emit("alert", { message: SITE_MESSAGE.update_fail, type: 500 })
    }
  }

  const deleteImg = async (imgId) => {
    try {
      const response = await reqImageDelete({ id: imgId })
      console.log("img delete response: ", response)

      return response
    } catch (error) {
      emitter.emit("alert", { message: error.message, type: 500 })
    }
  }

  const getUploadResult = async () => {
    try {
      const {
        data: {
          res_data: { result }
        }
      } = await reqUploadImageUrl()

      return result
    } catch (error) {
      emitter.emit("alert", { message: error.message, type: 500 })
    }
  }

  const handleUploadImg = async (imgFile, uploadUrl) => {
    try {
      const formData = new FormData()
      formData.append("file", imgFile)

      const response = await axios.post(uploadUrl, formData)
      console.log("after mem_char_img upload image: ", response)
    } catch (error) {
      console.error("fail upload image")
    }
  }

  return {
    deleteMember,
    updateMember,
    updateMemberPassword,
    updateMgrPassword,
    deleteImg,
    getUploadResult,
    handleUploadImg
  }
}

export default useMemberService
