import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { persist, createJSONStorage } from "zustand/middleware"

const VocaSentStore = (set) => ({
  action: "select",
  setAction: (_state) => set((state) => ({ action: _state })),

  vocaSentInfo: {},
  setVocaSentInfo: (_vocaSentInfo) => set((state) => ({ vocaSentInfo: _vocaSentInfo }))
})

const persistConfig = {
  name: "VocaSentStore",
  storage: createJSONStorage(() => sessionStorage)
}

const useVocaSentStore = create(devtools(persist(VocaSentStore, persistConfig), { name: persistConfig.name }))
export default useVocaSentStore
