import React, { Suspense } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { CContainer, CSpinner } from "@coreui/react-pro"

import AppLoading from "./AppLoading"

// routes config
import routes from "../routes"

const AppContent = () => {
  return (
    <CContainer lg>
      <Suspense fallback={<AppLoading heightSize={window.innerHeight} />}>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route key={idx} path={route.path} exact={route.exact} name={route.name} element={<route.element />}></Route>
              )
            )
          })}
          <Route path="/" element={<Navigate to="/login" replace />} />
        </Routes>
      </Suspense>
    </CContainer>
  )
}

export default React.memo(AppContent)
