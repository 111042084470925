import React from "react"
import { CSpinner } from "@coreui/react-pro"

const AppLoading = ({ heightSize }) => {
  if (heightSize < 500) {
    return (
      <div className="d-flex flex-columns justify-content-center align-items-center" style={{ height: `${heightSize}px` }}>
        <div>
          <div>
            <h4>잠시만 기다려주세요... </h4>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <CSpinner className="ms-1" color={"primary"} style={{ width: "50px", height: "50px" }} />
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="d-flex flex-columns justify-content-center align-items-center" style={{ height: `${heightSize - 200}px` }}>
      <div>
        <div>
          <h1>잠시만 기다려주세요... </h1>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <CSpinner className="ms-1" color={"primary"} style={{ width: "100px", height: "100px" }} />
        </div>
      </div>
    </div>
  )
}

export default AppLoading
