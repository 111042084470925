import React, { useCallback, useMemo, useEffect, useRef } from "react"
import { CRow, CCol, CModalBody } from "@coreui/react-pro"

import { AgGridReact } from "ag-grid-react"
import "ag-grid-enterprise"
import "ag-grid-enterprise/dist/styles/ag-grid.css"
import "ag-grid-enterprise/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise/dist/styles/ag-theme-alpine-dark.css"

//utiles
import { svcGridCommonOptions, defaultColConfig, autoGroupColumnDef } from "src/common/grid/GridConfig"

//store
import useGlobalStore from "src/GlobalStore"
import useGridStore from "src/common/grid/store/GridStore"

//api
import { useMemberHistorys } from "src/views/member/api/MemberApi"
import useMemberStore from "src/views/member/store/MemberStore"
//lib
import moment from "moment"
import "moment/locale/ko"
import { LoginTypeBadge } from "src/views/member/page/MemberPage"
//service

const LoginHistoryModal = ({ modalData }) => {
  const {
    useYn,
    windowHeight,
    setWindowHeight,
    onColumnRowGroupChanged,
    onGridReadyStore,
    onRowDataUpdatedStore,
    onSortChanged,
    onFilterChanged,
    onGridSizeChanged,
    onModelUpdatedStore
  } = useGridStore()

  const pageName = "LoginHistoryModal"
  const gridRef = useRef(null)
  const { siteLanguage, gridLanguage } = useGlobalStore()
  const { memberInfo } = useMemberStore()
  const { data: historys, mutate, isLoading } = useMemberHistorys(memberInfo?.mem_id)

  const columnDefs = useMemo(
    () => [
      {
        field: "",
        width: 30,
        minWidth: 30,
        maxWidth: 30,
        editable: false
        //check box
        // headerCheckboxSelection: () => {
        //   return true
        // },
        // checkboxSelection: (params) => true
      },
      {
        headerName: siteLanguage.gridDefult.no,
        field: "id",
        cellStyle: { textAlign: "right", paddingLeft: "2px", paddingRight: "2px" },
        valueGetter: "node.rowIndex + 1",
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        getQuickFilterText: () => ""
      },
      {
        headerName: "타입",
        field: "verf_typ",
        tooltipField: "verf_typ",
        width: 70,
        minWidth: 70,
        enableRowGroup: true,
        //filter: true,
        cellRenderer: (params) => {
          return params.value && <LoginTypeBadge type={params?.value} />
        }
      },
      {
        headerName: "이메일",
        field: "auth_email",
        tooltipField: "auth_email",
        width: 120,
        minWidth: 120,
        enableRowGroup: true
        //filter: "agTextColumnFilter",
      },
      {
        headerName: "로그인 시간",
        field: "reg_dtm",
        tooltipField: "reg_dtm",
        width: 120,
        minWidth: 120,
        enableRowGroup: true,
        //filter: "agTextColumnFilter",
        cellRenderer: (params) => params.value && moment.utc(params.value).locale("ko").format("YYYY-MM-DD HH:mm")
      }
    ],
    [siteLanguage]
  )

  //s 필수 Grid처리 ===============================================================================
  const onGridReady = useCallback(
    (params) => {
      onGridReadyStore(params, pageName)
    },
    [historys]
  )

  const onFirstDataRendered = useCallback(
    (params) => {
      // onFirstDataRenderedStore(params)
    },
    [historys]
  )

  const onRowDataUpdated = useCallback(
    (params) => {
      onRowDataUpdatedStore(isLoading, params)
    },
    [isLoading]
  )

  const onModelUpdated = useCallback((params) => {
    onModelUpdatedStore(params)
  }, [])

  //e 필수 Grid처리 ===============================================================================

  useEffect(() => {
    window.addEventListener("resize", () => setWindowHeight(window.innerHeight))
    if (window.opener !== null) {
      document.body.classList.add("popup-no-scrollbars")
    }
    return () => {
      window.removeEventListener("resize", () => setWindowHeight(window.innerHeight))
    }
  }, [window.innerHeight])

  if (historys?.length === 0) {
    gridRef.current?.api?.showNoRowsOverlay()
  }

  return (
    <>
      <CModalBody className="py-1">
        <CRow>
          <CCol className="p-0">
            <div className="ag-theme-alpine" style={{ height: `${windowHeight - 220}px` }}>
              <AgGridReact
                ref={gridRef}
                rowData={historys}
                localeText={gridLanguage}
                //Grid Config
                columnDefs={columnDefs}
                //sideBar={useMemo(() => sideBarConfig())}
                defaultColDef={useMemo(() => defaultColConfig())}
                gridOptions={useMemo(() => svcGridCommonOptions())}
                rowGroupPanelShow="never"
                //Grid Group Config
                autoGroupColumnDef={autoGroupColumnDef}
                //groupMaintainOrder={true}
                //groupDisplayType={'singleColumn'}
                suppressDragLeaveHidesColumns={true}
                //suppressMakeColumnVisibleAfterUnGroup={true}
                //suppressRowGroupHidesColumns={true}
                //groupHideOpenParents={true}
                //masterDetail={false}
                groupSelectsChildren={true}
                suppressAggFuncInHeader={true}
                groupDefaultExpanded={-1}
                // onColumnRowGroupChanged={onColumnRowGroupChanged}

                //Grid 이벤트
                onRowDataUpdated={onRowDataUpdated}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                onGridSizeChanged={onGridSizeChanged}
                onSortChanged={onSortChanged}
                onFilterChanged={onFilterChanged}
                onColumnRowGroupChanged={onColumnRowGroupChanged}
                onModelUpdated={onModelUpdated}
                //onPaginationChanged={useCallback((params) => onPaginationChanged(params))}
                //onCellClicked={onCellClicked}
                //Master, Detail설정
                masterDetail={false}
                //DateComponent

                //그외설정
                //overlayNoRowsTemplate={"No Data"}
                rowSelection={"multiple"}
                suppressRowClickSelection={true}
                suppressContextMenu={true}
                checkboxSelection={true}
                isRowSelectable={() => {
                  return true
                }}
              />
            </div>
          </CCol>
        </CRow>
      </CModalBody>
    </>
  )
}

export default LoginHistoryModal
