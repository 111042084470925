import React from "react"
import { CImage } from "@coreui/react-pro"

const defaultImgUrl =
  "https://e7.pngegg.com/pngimages/981/645/png-clipart-default-profile-united-states-computer-icons-desktop-free-high-quality-person-icon-miscellaneous-silhouette-thumbnail.png"

const Profile = ({ imgId, memName }) => {
  const url = imgId ? `https://imagedelivery.net/XJ0dKkCgMOZQs4Ecum2vlw/${imgId}/mobile` : defaultImgUrl

  return (
    <div className="d-flex align-items-center">
      <CImage className="rounded-circle" src={url} width={50} height={50} />
      <div className="ms-2">{memName}</div>
    </div>
  )
}

export default Profile
