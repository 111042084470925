import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { persist, createJSONStorage } from "zustand/middleware"

const VocaStore = (set) => ({
  action: "select",
  setAction: (_state) => set((state) => ({ action: _state })),

  vocaInfo: {},
  setVocaInfo: (_vocaInfo) => set((state) => ({ vocaInfo: _vocaInfo })),

  vocaCatInfos: [],
  setVocaCatInfo: (_mappings) => set((state) => ({ vocaCatInfos: _mappings })),
  resetVocaCatInfo: () => set((state) => ({ vocaCatInfos: [] })),

  vocaHistory: "",
  setVocaHistory: (_history) => set((state) => ({ vocaHistory: _history }))
})

const persistConfig = {
  name: "VocaStore",
  storage: createJSONStorage(() => sessionStorage)
}

const useVocaStore = create(devtools(persist(VocaStore, persistConfig), { name: persistConfig.name }))
export default useVocaStore
