import React from "react"
import CIcon from "@coreui/icons-react"
import { cilBarChart, cilClipboard, cilDrop, cilList, cilLayers, cilAssistiveListeningSystem } from "@coreui/icons"
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react-pro"
import dashboardStore from "src/GlobalStore"

const useNavFAQ = () => {
  const { siteLanguage } = dashboardStore()

  const navFaq = [
    {
      component: CNavItem,
      name: "FAQ",
      to: "/base/faq",
      icon: <CIcon icon={cilAssistiveListeningSystem} customClassName="nav-icon" />
    }
  ]

  return {
    navFaq
  }
}

export default useNavFAQ
