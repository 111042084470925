import React, { useCallback } from "react"
import {
  CRow,
  CCol,
  CContainer,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CButton
} from "@coreui/react-pro"
import { useNavigate } from "react-router-dom"
import { cilUser, cibCastro, cilSitemap, cilAccountLogout } from "@coreui/icons"
import CIcon from "@coreui/icons-react"
import useLoginStore from "src/views/login/store/LoginStore"

const AppHeaderDropdown = () => {
  const navigate = useNavigate()
  const { login, setClearSesstion, setLoginSession } = useLoginStore()
  //const sys_auth_nm = login.user_info.sys_auth_nm

  const testLogin = useCallback((error) => {
    navigate("/test_login")
  }, [])

  const logOut = useCallback(() => {
    const ok = window.confirm("정말 로그아웃 하시겠습니까?")

    if (!ok) return
    setClearSesstion()
    setLoginSession(null)
  }, [])

  return (
    <CDropdown variant="nav-item" alignment="end">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CIcon
          icon={cibCastro}
          className="icon-hover"
          size="lg"
          style={{ color: "#ddd", width: "23px", height: "23px", margin: "0 6px -3px -12px" }}
        />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0 pb-0">
        <CDropdownHeader className="bg-light dark:bg-white dark:bg-opacity-10 fw-semibold py-2">
          <small>사용자 정보</small>
        </CDropdownHeader>
        <CDropdownItem>
          <CIcon icon={cilUser} className="me-2" />
          {/* {login?.mem_acct} */}
          <small>{login?.mem_acct} 님 로그인 중</small>
        </CDropdownItem>
        {/* <CDropdownItem>
          <CIcon icon={cilSitemap} className="me-2" />
          <small>
            
            {login.user_info.br_nm ? `[${login.user_info.br_nm}]` : null} {sys_auth_nm}
       
          </small>
        </CDropdownItem> */}
        <CDropdownDivider className="m-0" />
        <CDropdownItem onClick={() => logOut()}>
          {/* <CContainer>
            
            {login.biz_auth.map((item) => {
              return (
                <CRow key={item.biz_auth}>
                  <CCol className="px-0 pb-0">
                    <small>{item.biz_nm}</small>
                  </CCol>
                </CRow>
              )
            })}
         
            컨테이너
          </CContainer> */}
          {/* <CIcon icon={cilAccountLogout} className="me-2" /> */}
          <CIcon icon={cilAccountLogout} className="me-2" />

          <CButton size="sm" color="warning" style={{ width: "120px" }}>
            <span>로그아웃</span>
          </CButton>
        </CDropdownItem>
        {/* <CDropdownItem className="pb-2">
          {process.env.REACT_APP_SPRING === process.env.REACT_APP_URL_LOCAL ||
          process.env.REACT_APP_SPRING === process.env.REACT_APP_URL_DEV ||
          process.env.REACT_APP_SPRING === process.env.REACT_APP_URL_TST ? (
            <CButton onClick={testLogin} color="light" size="sm" className="me-3">
              Test Login
            </CButton>
          ) : (
            ""
          )}
        </CDropdownItem> */}
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
