import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { CBreadcrumb, CBreadcrumbItem } from "@coreui/react-pro"

//hook
const AppHeaderBreadcrumb = () => {
  const [paths, setPaths] = useState([])
  const location = useLocation()
  useEffect(() => {
    const locations = location?.state?.path.split("/")
    locations &&
      locations.forEach((item) => {
        setPaths((prevPaths) => {
          if (!prevPaths.some((path) => path.name === item)) {
            return [...prevPaths, { name: item, active: true }]
          }
          return prevPaths
        })
      })
  }, [location])

  return (
    <CBreadcrumb className="m-0 ">
      {paths.map((breadcrumb, index) => {
        return (
          <CBreadcrumbItem /*{...(breadcrumb.active ? { active: true } : { active: true })} */ key={index}>
            <strong>{breadcrumb.name}</strong>
          </CBreadcrumbItem>
        )
      })}
    </CBreadcrumb>
  )
}

export default AppHeaderBreadcrumb
