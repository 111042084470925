import axiosSpring from "src/common/AxiosSpring"
import useSWR from "swr"
import useSWRMutation from "swr/mutation"

export const useVocaQuzList = (useYn, catId) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(`/admin/voca/quz/list?use_yn=${useYn}&cat_id=${catId}`, fetcher)
  return { data: data?.res_data || [], error, isLoading, mutate, isValidating }
}

export const useVocaQuzListAll = (useYn) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(`/admin/voca/quz/list/all?use_yn=${useYn}`, fetcher)
  return { data: data?.res_data || [], error, isLoading, mutate, isValidating }
}

//상세
export const useVocaQuzOne = (action, quzId) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    action !== "insert" && quzId ? `/admin/voca/quz/detail?quz_id=${quzId}` : null,
    fetcher
  )
  return { data: data?.res_data || {}, error, isLoading, mutate, isValidating }
}

//등록
export const useVocaQuzInsert = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/quz/insert`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//업데이트
export const useVocaQuzUpdate = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/quz/update`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//삭제
export const useVocaQuzDelete = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/quz/delete`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

export const useVocaQuzOrdUpdate = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/quz/ord/update`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

// 문제항목 api 테이블명: voca_quz_qst
export const useVocaQuzQstList = (useYn, quzId) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    quzId ? `/admin/voca/quz/qst/list?use_yn=${useYn}&quz_id=${quzId}` : null,
    fetcher
  )
  return { data: data?.res_data || [], error, isLoading, mutate, isValidating }
}

//상세
export const useVocaQuzQstOne = (action, qstId) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    action !== "insert" && qstId ? `/admin/voca/quz/qst/detail?qst_id=${qstId}` : null,
    fetcher
  )
  return { data: data?.res_data || {}, error, isLoading, mutate, isValidating }
}

//등록
export const useVocaQuzQstInsert = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/quz/qst/insert`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//등록
export const useVocaQuzQstBulkInsert = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/quz/qst/insert/list`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//업데이트
export const useVocaQuzQstUpdate = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/quz/qst/update`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//삭제
export const useVocaQuzQstDelete = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/quz/qst/delete`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

export const useVocaQstOrdUpdate = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/quz/qst/ord/update`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

export const useVocaQstExchangeUpdate = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/quz/qst/ex/update`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

// 문제보기 api 테이블명: voca_quz_opt
//리스트
export const useVocaQuzOptList = (useYn, quzId) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    quzId ? `/admin/voca/quz/opt/list?use_yn=${useYn}&quz_id=${quzId}` : null,
    fetcher
  )
  return { data: data?.res_data || [], error, isLoading, mutate, isValidating }
}

//상세
export const useVocaQuzOptOne = (action, optId) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    action !== "insert" && optId ? `/admin/voca/quz/opt/detail?opt_id=${optId}` : null,
    fetcher
  )
  return { data: data?.res_data || {}, error, isLoading, mutate, isValidating }
}

//등록
export const useVocaQuzOptInsert = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/quz/opt/insert`, fetcher)
  return { trigger, isMutating, data, error, reset }
}
// @PostMapping("/voca/quz/opt/insert/list")
//등록
export const useVocaQuzOptBulkInsert = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/quz/opt/insert/list`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//업데이트
export const useVocaQuzOptUpdate = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/quz/opt/update`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//삭제
export const useVocaQuzOptDelete = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/quz/opt/delete`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//업데이트
export const useVocaOptOrdUpdate = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/quz/opt/ord/update`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//보기 목록 등록
export const useVocaOptsInsert = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/quz/opt/insert/bulk`, fetcher)
  return { trigger, isMutating, data, error, reset }
}
