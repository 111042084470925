import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { persist, createJSONStorage } from "zustand/middleware"

const VocaQuzStore = (set) => ({
  action: "select",
  setAction: (_state) => set((state) => ({ action: _state })),

  vocaQuz: {},
  setVocaQuz: (_vocaQuz) => set((state) => ({ vocaQuz: _vocaQuz })),
  quzAnsTyp: null,
  setQuzAnsTyp: (_quzAnsTyp) => set((state) => ({ quzAnsTyp: _quzAnsTyp }))
})

const persistConfig = {
  name: "VocaQuzStore",
  storage: createJSONStorage(() => sessionStorage)
}

const useVocaQuzStore = create(devtools(persist(VocaQuzStore, persistConfig), { name: persistConfig.name }))
export default useVocaQuzStore
