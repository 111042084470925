import axiosSpring from "src/common/AxiosSpring"
import useSWR from "swr"
import useSWRMutation from "swr/mutation"

export const useVocaCatMapList = (useYn, catId) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    catId ? `/admin/voca/cat/map/list/${catId}?use_yn=${useYn}` : null,
    fetcher
  )
  return { data: data?.res_data || [], error, isLoading, mutate, isValidating }
}

//등록
export const useVocaCatMapInsert = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/cat/map/insert`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//삭제
export const useVocaCatMapDelete = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/cat/map/delete`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//등록
export const useCatListVocaMapInsert = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/cat/list/voca/map/insert`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//기존 단어의 싱크 맞추기
export const useSyncVocaCatInfo = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/cat/list/sync`, fetcher)
  return { trigger, isMutating, data, error, reset }
}
