import React, { useCallback } from "react"
import { CButton } from "@coreui/react-pro"
import useModalStore from "../store/ModalStore"

const ErrorModal = ({ modalData }) => {
  const { setCloseModal } = useModalStore()

  const loginRetry = useCallback(() => {
    window.location.href = "/#admin_login"
    setCloseModal()
  }, [])

  return (
    <>
      <div style={{ textAlign: "center", padding: "40px 0" }}>
        <h5>{modalData?.code}</h5>
        <h6>{modalData?.message}</h6>
        <h6>{modalData?.response?.data.res_msg}</h6>
        <h6>{modalData?.response?.status === 401 && "세션이 만료되었습니다 다시 로그인 해주세요."}</h6>
        {modalData?.response?.status === 401 ? (
          <>
            <CButton className="my-4" size="lg" onClick={() => loginRetry()}>
              다시 로그인
            </CButton>
          </>
        ) : null}
      </div>
    </>
  )
}

export default ErrorModal
