import {
  useVocaQuzInsert,
  useVocaQuzDelete,
  useVocaQuzUpdate,
  useVocaQuzOrdUpdate,
  useVocaQuzQstInsert,
  useVocaQuzQstBulkInsert,
  useVocaQuzQstUpdate,
  useVocaQuzQstDelete,
  useVocaQstOrdUpdate,
  useVocaQstExchangeUpdate,
  useVocaQuzOptInsert,
  useVocaQuzOptBulkInsert,
  useVocaQuzOptUpdate,
  useVocaQuzOptDelete,
  useVocaOptOrdUpdate,
  useVocaOptsInsert
} from "../api/VocaQuzApi"

import { useMitt } from "react-mitt"
import SITE_MESSAGE from "src/assets/language/site.message"

const useVocaQuzService = () => {
  const { emitter } = useMitt()
  //퀴즈
  const { trigger: requestInsert, isMutating: isInserting } = useVocaQuzInsert()
  const { trigger: requestUpdate, isMutating: isUpdateing } = useVocaQuzUpdate()
  const { trigger: requestOrdUpdate } = useVocaQuzOrdUpdate()
  const { trigger: requestDelete } = useVocaQuzDelete()

  //퀴즈 문항
  const { trigger: requestQstInsert, isMutating: isQstInserting } = useVocaQuzQstInsert()
  const { trigger: requestQstUpdate } = useVocaQuzQstUpdate()
  const { trigger: requestQstOrdUpdate } = useVocaQstOrdUpdate()
  const { trigger: requestQstExeUpdate } = useVocaQstExchangeUpdate()
  const { trigger: requestQstDelete } = useVocaQuzQstDelete()
  const { trigger: requestQstBulkInsert } = useVocaQuzQstBulkInsert()

  //퀴즈 옵션
  const { trigger: requestOptInsert } = useVocaQuzOptInsert()
  const { trigger: requestOptUpdate } = useVocaQuzOptUpdate()
  // const { trigger: requestOptOrdUpdate } = useVocaQstOrdUpdate()
  const { trigger: requestOptDelete } = useVocaQuzOptDelete()
  const { trigger: requestOptBulkInsert } = useVocaQuzOptBulkInsert()
  const { trigger: requestInsertVocaOpts } = useVocaOptsInsert()
  const { trigger: requestOptOrdUpdate } = useVocaOptOrdUpdate()

  const insertVocaQuz = async (data) => {
    try {
      const response = await requestInsert(data)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.insert_success, type: 200 })
      }

      return response?.data?.res_data
    } catch (error) {
      console.error("insertService error:", error)
      emitter.emit("alert", { message: SITE_MESSAGE.insert_fail, type: 500 })
    }
  }

  const insertVocaQuzQst = async (data) => {
    try {
      const response = await requestQstInsert(data)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.insert_success, type: 200 })
      }

      return response?.data?.res_data
    } catch (error) {
      emitter.emit("alert", { message: SITE_MESSAGE.insert_fail, type: 500 })
    }
  }

  const insertVocaQuzQstList = async (data) => {
    //list data 벌크 입력
    try {
      const response = await requestQstBulkInsert(data)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.insert_success, type: 200 })
      }

      return response?.data?.res_data
    } catch (error) {
      console.error("insertService error:", error)
      emitter.emit("alert", { message: SITE_MESSAGE.insert_fail, type: 500 })
    }
  }

  const updateVocaQuz = async (data) => {
    try {
      const response = await requestUpdate(data)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.update_success, type: 200 })
      }
    } catch (error) {
      console.error("updateService error:", error)
      emitter.emit("alert", { message: SITE_MESSAGE.update_fail, type: 500 })
    }
  }

  const updateVocaQuzQst = async (data) => {
    try {
      const response = await requestQstUpdate(data)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.update_success, type: 200 })
      }
    } catch (error) {
      console.error("updateService error:", error)
      emitter.emit("alert", { message: SITE_MESSAGE.update_fail, type: 500 })
    }
  }

  const updateVocaQuzQstOrd = async (data) => {
    try {
      await requestQstOrdUpdate(data)
    } catch (error) {
      console.error("updateVocaQuzQstOrd fail...", error)
    }
  }

  const updateVocaQuzExchange = async (data) => {
    try {
      if (data.length === 0) {
        emitter.emit("alert", { message: "교체할 문답이 없습니다.", type: 300 })
        return
      }
      const response = await requestQstExeUpdate(data)
      if (response.status === 200) {
        emitter.emit("alert", { message: "성공적으로 교환했습니다.", type: 200 })
      }
    } catch (error) {
      console.error("updateVocaQuzQstOrd fail...", error)
      emitter.emit("alert", { message: SITE_MESSAGE.update_fail, type: 500 })
    }
  }

  const updateVocaQuzOrd = async (data) => {
    try {
      await requestOrdUpdate(data)
    } catch (error) {
      console.error("updateService error:", error)
    }
  }

  const deleteVocaQuzQst = async (data) => {
    try {
      if (data.length === 0) {
        window.alert("삭제할 데이터를 선택해주세요")
        return
      }
      const response = await requestQstDelete(data)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.delete_success, type: 200 })
      }
    } catch (error) {
      console.error("useVocaQuzQstDelete fail...", error)
      emitter.emit("alert", { message: SITE_MESSAGE.delete_fail, type: 500 })
    }
  }

  const deleteVocaQuz = async (gridRef) => {
    const selectedQuzs = gridRef.current.api.getSelectedRows().map((item) => item.quz_id)

    try {
      if (selectedQuzs.length === 0) {
        window.alert("삭제할 데이터를 선택해주세요")
        return
      }

      const response = await requestDelete(selectedQuzs)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.delete_success, type: 200 })
      }
    } catch (error) {
      console.error("deleteVocaQuz error:", error)
      emitter.emit("alert", { message: SITE_MESSAGE.delete_fail, type: 500 })
    }
  }

  const insertVocaOpt = async (data) => {
    try {
      const response = await requestOptInsert(data)

      if (response.status === 200) {
        emitter.emit("alert", { message: "성공적으로 등록했습니다.", type: 200 })
      }
    } catch (error) {
      console.error("insertVocaOpt error:", error)
      emitter.emit("alert", { message: SITE_MESSAGE.insert_fail, type: 500 })
    }
  }

  const insertBulkVocaOpt = async (data) => {
    try {
      const response = await requestOptBulkInsert(data)

      if (response.status === 200) {
        emitter.emit("alert", { message: "성공적으로 가져왔습니다.", type: 200 })
      }
      console.log(response)
      return response?.data?.res_data
    } catch (error) {
      console.error("insertBulkVocaOpt error:", error)
      emitter.emit("alert", { message: error.response.data.res_msg, type: 500 })
    }
  }

  const updateVocaOpt = async (data) => {
    try {
      const response = await requestOptUpdate(data)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.update_success, type: 200 })
      }
    } catch (error) {
      console.error("updateVocaOpt error:", error)
      emitter.emit("alert", { message: SITE_MESSAGE.update_fail, type: 500 })
    }
  }

  const deleteVocaOpt = async (data) => {
    if (data.length === 0) {
      window.alert("삭제할 데이터를 선택해주세요")
      return
    }
    try {
      const response = await requestOptDelete(data)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.delete_success, type: 200 })
      }
    } catch (error) {
      console.error("deleteVocaOpt error:", error)
      emitter.emit("alert", { message: SITE_MESSAGE.delete_fail, type: 500 })
    }
  }
  //requestOptOrdUpdate
  const updateVocaOptOrd = async (listData) => {
    try {
      const response = await requestOptOrdUpdate(listData)
    } catch (error) {
      console.error("updateVocaOptOrd fail...", error)
    }
  }

  const insertVocaOpts = async (listData) => {
    try {
      const response = await requestInsertVocaOpts(listData)
      console.log("afrer insert opts response", response)
    } catch (error) {
      console.error("insertVocaOpts faild...", error)
    }
  }

  return {
    insertVocaQuz,
    updateVocaQuz,
    deleteVocaQuz,
    updateVocaQuzOrd,
    insertVocaQuzQst,
    insertVocaQuzQstList,
    updateVocaQuzQst,
    deleteVocaQuzQst,
    updateVocaQuzQstOrd,
    updateVocaQuzExchange,
    insertBulkVocaOpt,
    insertVocaOpt,
    updateVocaOpt,
    deleteVocaOpt,
    updateVocaOptOrd,
    insertVocaOpts
  }
}

export default useVocaQuzService
