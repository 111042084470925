import axiosSpring from "src/common/AxiosSpring"
import useSWR from "swr"
import useSWRMutation from "swr/mutation"

export const useMemberList = (useYn) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, mutate } = useSWR(`/admin/member/list?use_yn=${useYn}`, fetcher)
  return { data: data?.res_data || [], error, isLoading, mutate }
}

export const useMemberHistorys = (mem_id) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, mutate } = useSWR(`/admin/member/history?mem_id=${mem_id}`, fetcher)
  return { data: data?.res_data || [], error, isLoading, mutate }
}

export const useTmpMemberList = () => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, mutate } = useSWR(`/admin/member/tmp/list`, fetcher)
  return { data: data?.res_data || [], error, isLoading, mutate }
}

//상세
export const useMemberOne = (mem_id) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, mutate } = useSWR(mem_id ? `/admin/member/detail?mem_id=${mem_id}` : null, fetcher)
  return { data: data?.res_data || {}, error, isLoading, mutate }
}

//등록
export const useMemberInsert = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/member/insert`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//업데이트
export const useMemberUpdate = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/member/update`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//비밀번호 업데이트
export const useMemberUpdatePassword = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/member/password/update`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//비밀번호 업데이트
export const useMgrUpdatePassword = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/mgr/password/update`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//삭제
export const useMemberDelete = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/member/delete`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//회원이 담은 내 단어장 단어 리스트
export const useMemStdVocaList = (useYn, memId) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, mutate } = useSWR(`/admin/mem/std/voca/map/list?use_yn=${useYn}&mem_id=${memId}`, fetcher)
  return { data: data?.res_data || [], error, isLoading, mutate }
}

//회원이 담은 예문 리스트
export const useMemStdSentList = (useYn, memId) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, mutate } = useSWR(`/admin/mem/std/sent/map/list?use_yn=${useYn}&mem_id=${memId}`, fetcher)
  return { data: data?.res_data || [], error, isLoading, mutate }
}
