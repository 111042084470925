import React from "react"
import CIcon from "@coreui/icons-react"
import { cilBarChart, cilClipboard, cilDrop, cilList, cilLayers, cilLan } from "@coreui/icons"
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react-pro"
import dashboardStore from "src/GlobalStore"

const useNavLecture = () => {
  const { siteLanguage } = dashboardStore()

  const navLecture = [
    {
      component: CNavGroup,
      name: "수강 관리",
      to: "/lecture",
      icon: <CIcon icon={cilLan} customClassName="nav-icon" />,
      items: [
        // {
        //   component: CNavItem,
        //   name: siteLanguage.navCondition.svcList,
        //   to: "/svc/svcList"
        // },
        // {
        //   component: CNavItem,
        //   name: siteLanguage.navCondition.statistics,
        //   to: "/system/statistics"
        // },
        {
          component: CNavItem,
          name: "수강 목록",
          to: "/lecture/manage"
        }
      ]
    }
  ]

  return {
    navLecture
  }
}

export default useNavLecture
