import React from "react"
import CIcon from "@coreui/icons-react"
import { cilBarChart, cilClipboard, cilDrop, cilList, cilLayers, cilBook } from "@coreui/icons"
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react-pro"
import dashboardStore from "src/GlobalStore"

const useNavVoca = () => {
  const { siteLanguage } = dashboardStore()

  const navVoca = [
    {
      component: CNavGroup,
      name: "단어 관리",
      to: "/condition",
      icon: <CIcon icon={cilBook} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: "단어 목록",
          to: "/voca/manage"
        },
        {
          component: CNavItem,
          name: "예문 목록",
          to: "/sent/manage"
        },
        {
          component: CNavItem,
          name: "퀴즈 목록",
          to: "/quz/manage"
        }
      ]
    }
  ]

  return {
    navVoca
  }
}

export default useNavVoca
