import React, { useCallback, useMemo, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import {
  CRow,
  CCol,
  CHeader,
  CHeaderNav,
  CContainer,
  CButton,
  CInputGroup,
  CFormInput,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle
} from "@coreui/react-pro"
import CIcon from "@coreui/icons-react"
import { cilReload, cilMediaStop } from "@coreui/icons"

import { AgGridReact } from "ag-grid-react"
import "ag-grid-enterprise"
import "ag-grid-enterprise/dist/styles/ag-grid.css"
import "ag-grid-enterprise/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise/dist/styles/ag-theme-alpine-dark.css"

//utiles
import { svcGridCommonOptions, sideBarConfig, defaultColConfig, autoGroupColumnDef } from "src/common/grid/GridConfig"

//store
import useGlobalStore from "src/GlobalStore"
import useLoginStore from "src/views/login/store/LoginStore"
import useGridStore from "src/common/grid/store/GridStore"
import useMemberStore from "../store/MemberStore"

//api
import { useMemberList } from "../api/MemberApi"

//lib
import moment from "moment"
import "moment/locale/ko"
//component
import Profile from "./MemberProfile"

//service
import useMemberService from "../hook/useMemberService"

export const withAccoount = (LoginTypeBadge) => {
  return ({ acct, ...rest }) => (
    <div className="d-flex align-items-center">
      <LoginTypeBadge {...rest} />
      <div className="ms-2">{acct}</div>
    </div>
  )
}

export const LoginTypeBadge = ({ type }) => {
  const logintypes = [
    { code: "google", color: "#4185F4", textColor: "text-white" },
    { code: "kakao", color: "#FAE100", textColor: "text-black" },
    { code: "naver", color: "#03C75B", textColor: "text-white" },
    { code: "platform", color: "#2B4B7E", textColor: "text-white" }
  ]

  const loginType = logintypes.find((item) => item.code === type)

  return (
    <div
      className={`rounded-pill p-1 py-1 ${loginType?.textColor} h-50 d-flex justify-content-center align-items-center`}
      style={{ width: "80px", backgroundColor: loginType?.color }}
    >
      {loginType?.code}
    </div>
  )
}

const AdminPage = () => {
  const {
    useYn,
    dataSearch,
    windowHeight,
    searchToggle,
    setWindowHeight,
    setGridStateOnReload,
    setRowDoubleClicked,
    onUseYnChange,
    onColumnRowGroupChanged,
    onSearchChange,
    onKeyDown,
    onGridReadyStore,
    onFirstDataRenderedStore,
    onRowDataUpdatedStore,
    onSortChanged,
    onFilterChanged,
    onGridSizeChanged,
    onModelUpdatedStore
  } = useGridStore()

  const pageName = "memberPage"

  const gridRef = useRef(null)
  const navigate = useNavigate()
  const { setAction, setMemberInfo } = useMemberStore()
  const { siteLanguage, gridLanguage } = useGlobalStore()
  const { login } = useLoginStore()
  const { data: memberList, mutate, isLoading } = useMemberList(useYn.yn)

  const { deleteMember } = useMemberService()
  //store

  const columnDefs = useMemo(
    () => [
      {
        field: "",
        width: 30,
        minWidth: 30,
        maxWidth: 30,
        editable: false,
        //check box
        headerCheckboxSelection: () => {
          return true
        },
        checkboxSelection: (params) => !params.node.group && true
      },
      {
        headerName: siteLanguage.gridDefult.no,
        field: "id",
        cellStyle: { textAlign: "right", paddingLeft: "2px", paddingRight: "2px" },
        valueGetter: "node.rowIndex + 1",
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        getQuickFilterText: () => ""
      },
      {
        headerName: "ID",
        field: "mem_id",
        hide: true,
        width: 100,
        minWidth: 100,
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "접속 타입",
        field: "mem_accs",
        tooltipField: "mem_accs",
        width: 70,
        minWidth: 70,
        enableRowGroup: true,
        filter: true,
        cellRenderer: (params) => {
          return params.value && <LoginTypeBadge type={params?.value} />
        }
      },
      {
        headerName: "계정명",
        field: "mem_acct",
        tooltipField: "mem_acct",
        width: 120,
        minWidth: 120,
        enableRowGroup: true,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "회원명",
        field: "mem_nm",
        tooltipField: "mem_nm",
        width: 120,
        minWidth: 120,
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return params.value && <Profile imgId={params?.data?.mem_char_img} memName={params?.data?.mem_nm} />
        }
      },
      {
        headerName: "연락처",
        field: "mem_mbph_no",
        tooltipField: "mem_mbph_no",
        width: 120,
        minWidth: 120,
        enableRowGroup: true,
        filter: "agTextColumnFilter"
      },

      {
        headerName: "로그인 시간",
        field: "login_dtm",
        tooltipField: "login_dtm",
        width: 120,
        minWidth: 120,
        maxWidth: 150,
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => params.value && moment.utc(params.value).locale("ko").format("YYYY-MM-DD HH:mm")
      },
      {
        headerName: "가입일",
        field: "reg_dtm",
        tooltipField: "reg_dtm",
        width: 120,
        minWidth: 120,
        maxWidth: 150,
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => params.value && moment.utc(params.value).locale("ko").format("YYYY-MM-DD")
      }
    ],
    [siteLanguage]
  )

  //s 필수 Grid처리 ===============================================================================
  const onGridReady = useCallback(
    (params) => {
      onGridReadyStore(params, pageName)
    },
    [memberList]
  )

  const onFirstDataRendered = useCallback(
    (params) => {
      onFirstDataRenderedStore(params)
    },
    [memberList]
  )

  const onRowDataUpdated = useCallback(
    (params) => {
      onRowDataUpdatedStore(isLoading, params)
    },
    [isLoading]
  )

  const onModelUpdated = useCallback((params) => {
    onModelUpdatedStore(params)
  }, [])

  //e 필수 Grid처리 ===============================================================================

  /* S: Action처리 ===============================================================================*/

  const clickDataReload = useCallback(() => {
    console.log(`${pageName} - clickDataReload`)
    setGridStateOnReload(gridRef)
  }, [])

  //상세 더블클릭
  const onRowDoubleClicked = useCallback((params) => {
    if (params.data) {
      console.log(params.data)
      setRowDoubleClicked(params)
      setAction("select")
      setMemberInfo(params.data)
      navigate("/member/manage/detail")
    }
  }, [])

  //검색바 동작
  const searchChange = useCallback((e) => {
    onSearchChange(e)
  }, [])

  //검색바 엔터처리
  const searchKeyDown = useCallback((e) => {
    onKeyDown(e, gridRef)
  }, [])

  const clickDeleteData = useCallback(async (e) => {
    const ok = window.confirm("선택한 유저를 삭제하시겠습니까?")

    if (!ok) return

    await deleteMember(gridRef.current.api.getSelectedRows())

    await mutate()
  }, [])
  /* E: Action처리 ===============================================================================*/

  useEffect(() => {
    window.addEventListener("resize", () => setWindowHeight(window.innerHeight))
    if (window.opener !== null) {
      document.body.classList.add("popup-no-scrollbars")
    }
    return () => {
      window.removeEventListener("resize", () => setWindowHeight(window.innerHeight))
    }
  }, [window.innerHeight])

  if (memberList.length === 0) {
    gridRef.current?.api?.showNoRowsOverlay()
  }

  return (
    <>
      <CHeader className={`${window.opener !== null ? "header-content-popup" : "header-content"}`}>
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CIcon icon={cilMediaStop} size="sm" style={{ margin: "5px 4px 0px 0", "--ci-primary-color": "#999" }} />
          <strong>회원 관리</strong>
        </CHeaderNav>
        <CHeaderNav className="py-1 me-3">
          <>
            <CButton onClick={clickDeleteData} size="sm" color="danger" className="px-4">
              {siteLanguage.btnName.delete}
            </CButton>
          </>
        </CHeaderNav>
      </CHeader>
      <CContainer>
        <CRow>
          <CCol className="px-3 py-2 mb-2 border">
            <CRow>
              <CCol xs={12}>
                <CInputGroup size="sm">
                  <CButton size="sm" className="px-3" onClick={clickDataReload}>
                    <CIcon icon={cilReload} />
                  </CButton>
                  <CDropdown variant="input-group">
                    <CDropdownToggle
                      disabled={false}
                      color="secondary"
                      variant="outline"
                      className="px-3"
                      style={{ fontSize: "0.8em" }}
                    >
                      {useYn.desc}
                    </CDropdownToggle>
                    <CDropdownMenu>
                      {searchToggle.map((item, index) => (
                        <CDropdownItem
                          key={index}
                          onClick={() => onUseYnChange(item.value, item.dsc)}
                          style={{ fontSize: "0.8em" }}
                        >
                          {item.name}
                        </CDropdownItem>
                      ))}
                    </CDropdownMenu>
                  </CDropdown>

                  <CFormInput onChange={searchChange} onKeyUp={searchKeyDown} value={dataSearch} placeholder="Search" />
                  <CButton type="button" color="primary" id="button-addon2" className="px-4">
                    {siteLanguage.btnName.search}
                  </CButton>
                </CInputGroup>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow>
          <CCol className="p-0">
            <div className="ag-theme-alpine" style={{ height: `${windowHeight - 239}px` }}>
              <AgGridReact
                ref={gridRef}
                rowData={memberList}
                localeText={gridLanguage}
                //Grid Config
                columnDefs={columnDefs}
                sideBar={useMemo(() => sideBarConfig())}
                defaultColDef={useMemo(() => defaultColConfig())}
                gridOptions={useMemo(() => svcGridCommonOptions())}
                rowGroupPanelShow="always"
                //Grid Group Config
                autoGroupColumnDef={autoGroupColumnDef}
                //groupMaintainOrder={true}
                //groupDisplayType={'singleColumn'}
                //suppressDragLeaveHidesColumns={true}
                //suppressMakeColumnVisibleAfterUnGroup={true}
                //suppressRowGroupHidesColumns={true}
                //groupHideOpenParents={true}
                //masterDetail={false}
                groupSelectsChildren={true}
                suppressAggFuncInHeader={true}
                groupDefaultExpanded={-1}
                //groupRowRenderer={HandleGroupRender}
                // onColumnRowGroupChanged={onColumnRowGroupChanged}

                //Grid 이벤트
                onRowDataUpdated={onRowDataUpdated}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                onGridSizeChanged={onGridSizeChanged}
                onRowDoubleClicked={onRowDoubleClicked}
                onSortChanged={onSortChanged}
                onFilterChanged={onFilterChanged}
                onColumnRowGroupChanged={onColumnRowGroupChanged}
                onModelUpdated={onModelUpdated}
                //onPaginationChanged={useCallback((params) => onPaginationChanged(params))}
                //onCellClicked={onCellClicked}
                //Master, Detail설정
                masterDetail={false}
                //DateComponent

                //그외설정
                //overlayNoRowsTemplate={"No Data"}
                rowSelection={"multiple"}
                suppressRowClickSelection={true}
                suppressContextMenu={true}
                checkboxSelection={true}
                isRowSelectable={() => {
                  return true
                }}
              />
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default AdminPage
