import React, { useCallback, useMemo, useEffect, useRef } from "react"
import { CRow, CCol, CContainer } from "@coreui/react-pro"

import { AgGridReact } from "ag-grid-react"
import "ag-grid-enterprise"
import "ag-grid-enterprise/dist/styles/ag-grid.css"
import "ag-grid-enterprise/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise/dist/styles/ag-theme-alpine-dark.css"

//utiles
import { svcGridCommonOptions, sideBarConfig, defaultColConfig, autoGroupColumnDef } from "src/common/grid/GridConfig"

//store
import useGlobalStore from "src/GlobalStore"
import useGridStore from "src/common/grid/store/GridStore"
import useMemberStore from "../store/MemberStore"
import useMemStdVocaStore from "../store/MemStdVocaStore"
//api
import { useMemStdVocaList } from "../api/MemberApi"
//lib
import moment from "moment"
import "moment/locale/ko"
//service

const MemStdVocaPage = () => {
  const {
    useYn,
    dataSearch,
    windowHeight,
    searchToggle,
    setWindowHeight,
    setGridStateOnReload,
    setRowDoubleClicked,
    onUseYnChange,
    onColumnRowGroupChanged,
    onSearchChange,
    onKeyDown,
    onGridReadyStore,
    onFirstDataRenderedStore,
    onRowDataUpdatedStore,
    onSortChanged,
    onFilterChanged,
    onGridSizeChanged,
    onModelUpdatedStore
  } = useGridStore()

  const pageName = "MemStdVocaPage"
  const gridRef = useRef(null)
  const { siteLanguage, gridLanguage } = useGlobalStore()
  const { memberInfo } = useMemberStore()
  const { setAction, setMemStdVoca } = useMemStdVocaStore()

  const { data: memStdSentList, mutate, isLoading } = useMemStdVocaList("Y", memberInfo?.mem_id)

  //store
  const auth = "S"

  const columnDefs = useMemo(
    () => [
      {
        field: "",
        width: 30,
        minWidth: 30,
        maxWidth: 30,
        editable: false
        //check box
        // headerCheckboxSelection: () => {
        //   return true
        // },
        // checkboxSelection: (params) => true
      },
      {
        headerName: siteLanguage.gridDefult.no,
        field: "id",
        cellStyle: { textAlign: "right", paddingLeft: "2px", paddingRight: "2px" },
        valueGetter: "node.rowIndex + 1",
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        getQuickFilterText: () => ""
      },
      {
        headerName: "단어",
        field: "voca_tg",
        tooltipField: "voca_tg",
        width: 120,
        minWidth: 120,
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return (
            <strong
              className="align-items-center"
              style={{
                border: "0px solid silver",
                borderRadius: 20,
                padding: "3px 10px",
                // backgroundColor: "white",
                color: "black"
              }}
            >
              <span>{params.value}</span>
            </strong>
          )
        }
      },
      {
        headerName: "뜻",
        field: "voca_mean",
        tooltipField: "voca_mean",
        width: 120,
        minWidth: 120,
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return (
            <strong
              className="align-items-center"
              style={{
                border: "0px solid silver",
                borderRadius: 20,
                padding: "3px 10px",
                // backgroundColor: "white",
                color: "black"
              }}
            >
              <span>{params.value}</span>
            </strong>
          )
        }
      },
      {
        headerName: "북마크 여부",
        cellStyle: { textAlign: "center" },
        field: "mark_yn",
        tooltipField: "mark_yn",
        width: 45,
        minWidth: 45,
        filter: true,
        cellRenderer: (params) => {
          const isY = params.value === "Y"
          return (
            <div
              className={`rounded-pill ${
                isY ? "bg-info" : "bg-waring"
              } text-white h-50 d-flex justify-content-center align-items-center`}
              style={{ width: "80px" }}
            >
              <span>{params.value}</span>
            </div>
          )
        }
      },
      {
        headerName: "매핑 날짜",
        field: "reg_dtm",
        tooltipField: "reg_dtm",
        width: 80,
        minWidth: 80,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return <span>{params.value && moment.utc(params.value).locale("ko").format("YYYY-MM-DD")}</span>
        }
      }
      // {
      //   headerName: "수정일",
      //   field: "upd_dtm",
      //   tooltipField: "upd_dtm",
      //   width: 80,
      //   minWidth: 80,
      //   filter: "agTextColumnFilter",
      //   cellRenderer: (params) => {
      //     return <span>{moment(params.value).format("YYYY-MM-DD")}</span>
      //   }
      // }
    ],
    [siteLanguage]
  )

  //s 필수 Grid처리 ===============================================================================
  const onGridReady = useCallback(
    (params) => {
      onGridReadyStore(params, pageName)
    },
    [memStdSentList]
  )

  const onFirstDataRendered = useCallback(
    (params) => {
      console.log("params check: ", params)
      // onFirstDataRenderedStore(params)
    },
    [memStdSentList]
  )

  const onRowDataUpdated = useCallback(
    (params) => {
      onRowDataUpdatedStore(isLoading, params)
    },
    [isLoading]
  )

  const onModelUpdated = useCallback((params) => {
    onModelUpdatedStore(params)
  }, [])

  //e 필수 Grid처리 ===============================================================================

  /* S: Action처리 ===============================================================================*/

  const clickDataReload = useCallback(() => {
    console.log(`${pageName} - clickDataReload`)
    setGridStateOnReload(gridRef)
  }, [])

  //상세 더블클릭
  const onRowDoubleClicked = useCallback((params) => {
    if (params.data) {
      setRowDoubleClicked(params)
      setAction("select")
      setMemStdVoca(params.data)
      //navigate 상세 화면 일지 혹은 모달로 처리할지.
      window.open(
        `${process.env.REACT_APP_ADMIN}#/voca/manage/detail/${params?.data?.voca_id}`,
        "_blank",
        "width=1200,height=1000"
      )
    }
  }, [])

  //검색바 동작
  const searchChange = useCallback((e) => {
    onSearchChange(e)
  }, [])

  //검색바 엔터처리
  const searchKeyDown = useCallback((e) => {
    onKeyDown(e, gridRef)
  }, [])

  /* E: Action처리 ===============================================================================*/

  useEffect(() => {
    window.addEventListener("resize", () => setWindowHeight(window.innerHeight))
    if (window.opener !== null) {
      document.body.classList.add("popup-no-scrollbars")
    }
    return () => {
      window.removeEventListener("resize", () => setWindowHeight(window.innerHeight))
    }
  }, [window.innerHeight])

  if (memStdSentList?.length === 0) {
    gridRef.current?.api?.showNoRowsOverlay()
  }

  return (
    <>
      <CContainer>
        <CRow>
          <CCol className="p-0">
            <div className="ag-theme-alpine" style={{ height: `${windowHeight - 220}px` }}>
              <AgGridReact
                ref={gridRef}
                rowData={memStdSentList}
                localeText={gridLanguage}
                //Grid Config
                columnDefs={columnDefs}
                sideBar={useMemo(() => sideBarConfig())}
                defaultColDef={useMemo(() => defaultColConfig())}
                gridOptions={useMemo(() => svcGridCommonOptions())}
                rowGroupPanelShow="never"
                //Grid Group Config
                autoGroupColumnDef={autoGroupColumnDef}
                //groupMaintainOrder={true}
                //groupDisplayType={'singleColumn'}
                //suppressDragLeaveHidesColumns={true}
                //suppressMakeColumnVisibleAfterUnGroup={true}
                //suppressRowGroupHidesColumns={true}
                //groupHideOpenParents={true}
                //masterDetail={false}
                groupSelectsChildren={true}
                suppressAggFuncInHeader={true}
                groupDefaultExpanded={-1}
                // onColumnRowGroupChanged={onColumnRowGroupChanged}

                //Grid 이벤트
                onRowDataUpdated={onRowDataUpdated}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                onGridSizeChanged={onGridSizeChanged}
                onRowDoubleClicked={onRowDoubleClicked}
                onSortChanged={onSortChanged}
                onFilterChanged={onFilterChanged}
                onColumnRowGroupChanged={onColumnRowGroupChanged}
                onModelUpdated={onModelUpdated}
                //onPaginationChanged={useCallback((params) => onPaginationChanged(params))}
                //onCellClicked={onCellClicked}
                //Master, Detail설정
                masterDetail={false}
                //DateComponent

                //그외설정
                //overlayNoRowsTemplate={"No Data"}
                rowSelection={"multiple"}
                suppressRowClickSelection={true}
                suppressContextMenu={true}
                checkboxSelection={true}
                isRowSelectable={() => {
                  return true
                }}
              />
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default MemStdVocaPage
