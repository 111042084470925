import { useCallback } from "react"
import useLoginStore from "src/views/login/store/LoginStore"

import moment from "moment"
import "moment/locale/ko"

const useUtils = () => {
  /*
  const { login } = useLoginStore()

  const isSuperAuth = useCallback(() => {
    const sys_auth = login.user_info.sys_auth
    let auth = sys_auth === "S"
    return auth
  }, [])

  const checkSvcRegAuth = useCallback(
    (svc_auth) => {
      let permission = false
      //Info 등록 조건 해당 사용자가 시스템 관리자 이거나 혹은 empType P(정규직인 사람만)
      if (
        svc_auth.svc_reg.empType.includes(login.user_info.emp_type) ||
        svc_auth.svc_reg.sysAuth.includes(login.user_info.sys_auth)
      ) {
        permission = true
      }
      return permission
    },
    [login.user_info]
  )

  const isBizAuth = useCallback((auth) => {
    const bizList = login.biz_auth
    let permission = false
    bizList.map((item) => {
      if (item.biz_auth === auth) permission = true
    })
    return permission
  }, [])

  const isTeamAuth = useCallback((brNo, deptId) => {
    let permisiion = false
    if (login.user_info.br_no === brNo && login.user_info.dept_id === deptId) permisiion = true
    return permisiion
  }, [])

  const commonCodeList = useCallback(() => {
    return login.code_info
  }, [])

  const userInfo = useCallback(() => {
    return login.user_info
  }, [])

  const isRegUser = useCallback((empNo) => {
    return login.user_info.emp_no === empNo
  }, [])

  const revMapping = useCallback(() => {
    const revlist = login.code_info.filter((item) => item.cod_mgr_no === "SCR0001" && item.use_yn === "Y")
    const revObject = revlist.map((item) => {
      return { rev_typ: item.cod_cd, rev_typ_rmk: item.cod_dsc }
    })
    return revObject
  }, [])

  const codeList = useCallback((cod_mgr_no) => {
    if (cod_mgr_no instanceof Array) {
      let tmp = []
      cod_mgr_no.map((cod_mgr) => {
        let codeList = login.code_info.filter((item) => item.cod_mgr_no === cod_mgr && item.use_yn === "Y")
        tmp.push(codeList)
      })
      return tmp
    } else {
      return login.code_info.filter((item) => item.cod_mgr_no === cod_mgr_no && item.use_yn === "Y")
    }
  }, [])

  const initMap = useCallback((code, listData) => {
    let countMap = new Map()
    codeList(code).map((item) => {
      countMap.set(item.cod_cd, 0)
    })

    listData.map((item) => {
      if (countMap.has(item.rev_stt)) {
        countMap.set(item.rev_stt, countMap.get(item.rev_stt) + 1)
      }
    })

    return countMap
  })

  const getMapCount = useCallback((map, status) => {
    let count = 0
    status.map((code) => {
      count += map.get(code)
    })
    return count
  }, [])

  const getBizMap = useCallback(() => {
    let bizMap = new Map()
    codeList("BIZ0001").map((bizCode) => {
      bizMap.set(bizCode.cod_cd.charAt(0), bizCode.cod_etc)
    })

    return bizMap
  }, [])

  const getEapStatus = useCallback((statusCode) => {
    if (statusCode === "ERR") return "결재요청오류"
    if (statusCode === "REQ") return "결재요청"
    if (statusCode === "apprstatus_ing") return "결재진행"
    if (statusCode === "apprstatus_reject") return "결재반송"
    if (statusCode === "apprstatus_finish") return "결재완료"
    else return ""
  }, [])

  return {
    isSuperAuth,
    commonCodeList,
    userInfo,
    revMapping,
    codeList,
    isBizAuth,
    isTeamAuth,
    isRegUser,
    checkSvcRegAuth,
    initMap,
    getMapCount,
    getEapStatus,
    getBizMap
  }*/

  const formatName = (name) => {
    if (!name) return
    const formatFileName = name
      .replace(/ /g, "_")
      .replace(/[^a-zA-Z0-9_]/g, "")
      .substring(0, 20)

    if (formatFileName.endsWith("_")) {
      return formatFileName.slice(0, -1)
    }

    return formatFileName
  }

  const formatFileName = (filename, audioType) => {
    if (!filename) return
    const formatDate = moment.utc().locale("ko").format("YYYYMMDD")
    const staticNum = "_1112_"
    const formatFileName = formatName(filename)
    const fileType = ".mp3"

    return formatDate + staticNum + formatFileName + audioType + fileType
  }

  const getFileRegisterDate = (filename) => {
    if (!filename) return
    const dateString = filename.substring(0, 8)
    console.log("what is date string", dateString)
    return moment.utc(dateString).locale("ko").format("YYYY-MM-DD")
  }

  const formatPhoneNumber = (value) => {
    if (!value) return
    const phoneNumber = value.replace(/[^\d]/g, "")
    const match = phoneNumber.match(/^(\d{0,3})(\d{0,4})(\d{0,4})$/)

    if (!match) return phoneNumber

    let formattedNumber = match[1]
    if (match[2]) {
      formattedNumber += `-${match[2]}`
    }
    if (match[3]) {
      formattedNumber += `-${match[3]}`
    }

    return formattedNumber
  }

  const validatePhoneNumber = (value) => {
    if (!value) return
    const phoneNumber = value.replace(/[^\d]/g, "")

    if (phoneNumber.length < 8) {
      return false
    }

    if (phoneNumber.startsWith("02")) {
      return /^02\d{0,8}$/.test(phoneNumber)
    } else if (phoneNumber.startsWith("1")) {
      return /^(15|16|18)\d{0,6}$/.test(phoneNumber)
    } else if (phoneNumber.startsWith("0")) {
      return /^\d{0,11}$/.test(phoneNumber)
    } else if (phoneNumber.startsWith("01")) {
      return /^01\d{0,9}$/.test(phoneNumber)
    }
    return false
  }

  const bytesToMB = (bytes) => {
    const mb = bytes / (1024 * 1024)
    return mb.toFixed(2)
  }

  return {
    formatName,
    formatFileName,
    getFileRegisterDate,
    formatPhoneNumber,
    validatePhoneNumber,
    bytesToMB
  }
}

export default useUtils
