import React, { useEffect, useState, forwardRef } from "react"

import { CInputGroup, CInputGroupText, CFormSelect, CFormCheck } from "@coreui/react-pro"

import useVocaCatStore from "src/views/vocaCat/store/VocaCatStore"
import useVocaQuzStore from "../store/VocaQuzStore"

//lib

import { useServiceList } from "src/views/svcInfo/api/ServiceApi"
import { useVocaCatList } from "src/views/vocaCat/api/VocaCatApi"
import useServiceStore from "src/views/svcInfo/store/ServiceStore"

export const SelectRadioServiceCat = forwardRef((props, ref) => {
  const [svcId, setSvcId] = useState(0)

  const { vocaCat, setQuzCatId, quzCatId } = useVocaCatStore()
  const { setQuzAnsTyp } = useVocaQuzStore()

  const { serviceInfo } = useServiceStore()

  const [checkboxs, setCheckboxs] = useState({
    voca_tg: false,
    voca_mean: false
  })

  const { data: services, isLoading: serviceLoading } = useServiceList("Y")
  const { data: categorys, isLoading: catrgoryLoading } = useVocaCatList("Y", svcId)

  useEffect(() => {
    setQuzAnsTyp(null)
  }, [])

  useEffect(() => {
    setSvcId(serviceInfo?.svc_id)
    setQuzCatId(vocaCat?.cat_id)
  }, [vocaCat, serviceInfo])

  const handleService = (e) => {
    setSvcId(e.target.value)
    setQuzCatId(0)
  }

  const handleCategory = (e) => {
    setQuzCatId(e.target.value)
  }

  const handleCheckbox = (e) => {
    const { name } = e.target
    setQuzAnsTyp(e.target.value)
    setCheckboxs((pre) => {
      const newCheckboxs = {}
      for (const key in pre) {
        newCheckboxs[key] = key === name ? true : false
      }
      return newCheckboxs
    })
  }

  return (
    <div className="d-flex justify-content-center ms-1">
      <CInputGroup className="me-2" style={{ width: "300px" }}>
        <CInputGroupText as="label" className="p-0 px-2">
          <small>서비스</small>
        </CInputGroupText>
        <CFormSelect className="form-select lh-18" value={svcId} onChange={handleService}>
          {services.map((item) => (
            <option key={item.svc_id} value={item.svc_id}>
              {item.svc_nm}
            </option>
          ))}
        </CFormSelect>
      </CInputGroup>

      <CInputGroup style={{ width: "300px" }}>
        <CInputGroupText as="label" className="p-0 px-2">
          <small>카테고리</small>
        </CInputGroupText>
        <CFormSelect className="form-select lh-18" value={quzCatId} onChange={handleCategory}>
          <option key={0} value={null}>
            카테고리를 선택해주세요
          </option>
          {categorys.map((item) => (
            <option key={item.cat_id} value={item.cat_id}>
              {item.cat_nm}
            </option>
          ))}
        </CFormSelect>
      </CInputGroup>

      <CInputGroup className="mx-3" style={{ width: "50px" }}>
        <CInputGroupText as="label" className="p-0 px-2 ">
          <CFormCheck
            ref={ref}
            onChange={handleCheckbox}
            checked={checkboxs.voca_tg}
            type="checkbox"
            value="voca_tg"
            name="voca_tg"
          />
          <small className="ms-2">
            <strong>단어</strong>
          </small>
        </CInputGroupText>
      </CInputGroup>

      <CInputGroup className="ms-2" style={{ width: "50px" }}>
        <CInputGroupText as="label" className="p-0 px-2">
          <CFormCheck
            onChange={handleCheckbox}
            checked={checkboxs.voca_mean}
            type="checkbox"
            value="voca_mean"
            name="voca_mean"
          />
          <small className="ms-2">
            <strong>뜻</strong>
          </small>
        </CInputGroupText>
      </CInputGroup>
    </div>
  )
})

export const SelectServiceCat = ({ isDisabled = false }) => {
  const [svcId, setSvcId] = useState(0)

  const { vocaCat, setQuzCatId, quzCatId } = useVocaCatStore()
  const { serviceInfo } = useServiceStore()

  const { data: services, isLoading: serviceLoading } = useServiceList("Y")
  const { data: categorys, isLoading: catrgoryLoading } = useVocaCatList("Y", svcId)

  useEffect(() => {
    setSvcId(serviceInfo?.svc_id)
    setQuzCatId(vocaCat?.cat_id)
  }, [vocaCat, serviceInfo])

  const handleService = (e) => {
    setSvcId(e.target.value)
    setQuzCatId(0)
  }

  const handleCategory = (e) => {
    setQuzCatId(e.target.value)
  }

  return (
    <div className="d-flex justify-content-center ms-1">
      <CInputGroup className="me-2" style={{ width: "300px" }}>
        <CInputGroupText as="label" className="p-0 px-2">
          <small>서비스</small>
        </CInputGroupText>
        <CFormSelect disabled={isDisabled} className="form-select lh-18" value={svcId} onChange={handleService}>
          {services.map((item) => (
            <option key={item.svc_id} value={item.svc_id}>
              {item.svc_nm}
            </option>
          ))}
        </CFormSelect>
      </CInputGroup>

      <CInputGroup style={{ width: "300px" }}>
        <CInputGroupText as="label" className="p-0 px-2">
          <small>카테고리</small>
        </CInputGroupText>
        <CFormSelect disabled={isDisabled} className="form-select lh-18" value={quzCatId} onChange={handleCategory}>
          <option key={0} value={null}>
            카테고리를 선택해주세요
          </option>
          {categorys.map((item) => (
            <option key={item.cat_id} value={item.cat_id}>
              {item.cat_nm}
            </option>
          ))}
        </CFormSelect>
      </CInputGroup>
    </div>
  )
}
