import {
  useAdminDelete,
  useAdminInsert,
  useAdminUpdate,
  useAdminPasswordUpdate,
  useAdminisValid,
  useAdminEmailisValid
} from "../api/AdminApi"
import { useMitt } from "react-mitt"
import SITE_MESSAGE from "src/assets/language/site.message"

const useAdminService = () => {
  const { emitter } = useMitt()

  const { trigger: requestAdminInsert } = useAdminInsert()
  const { trigger: requestDeleteAdmin } = useAdminDelete()
  const { trigger: requestUpdateAdmin } = useAdminUpdate()
  const { trigger: requestAdminMemAcctValid } = useAdminisValid()
  const { trigger: requestAdminEamilValid } = useAdminEmailisValid()
  const { trigger: requestUpdateAdminPw } = useAdminPasswordUpdate()

  const insertAdmin = async (data) => {
    try {
      const response = await requestAdminInsert(data)
      console.log("insert Admin response: ", response)

      // 1보다 크면 에러코드 발생임
      if (response.data.res_data === 1) {
        emitter.emit("alert", { message: SITE_MESSAGE.insert_success, type: 200 })
      }

      if (response.data.res_data === "409") {
        emitter.emit("alert", { message: "이미 사용중인 아이디입니다. 다른 아이디를 입력해주세요.", type: 500 })
      }
    } catch (error) {
      console.error("insertAdmin error: ", error)
      emitter.emit("alert", { message: SITE_MESSAGE.insert_fail, type: 500 })
    }
  }
  const deleteAdmin = async (data) => {
    try {
      if (data.length === 0) {
        window.alert("삭제할 데이터를 선택해주세요")
        return
      }
      const memIdList = data.map((item) => item.mem_id)
      const response = await requestDeleteAdmin(memIdList)
      console.log("delete Admin response: ", response)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.delete_success, type: 200 })
      }
    } catch (error) {
      console.error("insertAdmin error: ", error)
      emitter.emit("alert", { message: SITE_MESSAGE.delete_fail, type: 500 })
    }
  }

  const updateAdmin = async (data) => {
    try {
      console.log("update data", data)
      const response = await requestUpdateAdmin(data)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.update_success, type: 200 })
      }
      console.log("udpate admin response: ", response)
    } catch (error) {
      console.error("update admin error: ", error)
      emitter.emit("alert", { message: SITE_MESSAGE.update_fail, type: 500 })
    }
  }

  const updateAdminPw = async (data) => {
    try {
      const response = await requestUpdateAdminPw(data)
      console.log(response)
      emitter.emit("alert", { message: "비밀번호 변경했습니다.", type: 200 })
    } catch (error) {
      console.error("fail update admin pw", error)
      emitter.emit("alert", { message: "비밀번호 변경에 실패했습니다.", type: 500 })
    }
  }

  const isAdminMemAcctValid = async (mem_acct) => {
    try {
      const response = await requestAdminMemAcctValid({ mem_acct })
      console.log("check response", response)
      return response.data.res_data
    } catch (error) {
      emitter.emit("alert", { message: error.message, type: 500 })
    }
  }

  const isAdminMemEmailValid = async (mem_email) => {
    try {
      const response = await requestAdminEamilValid({ mem_email })
      console.log("check response", response)
      return response.data.res_data
    } catch (error) {
      emitter.emit("alert", { message: error.message, type: 500 })
    }
  }

  return {
    insertAdmin,
    deleteAdmin,
    updateAdmin,
    updateAdminPw,
    isAdminMemAcctValid,
    isAdminMemEmailValid
  }
}

export default useAdminService
