import React from "react"
import useUtils from "src/common/utils/useUtils"
import { CFormInput, CTableDataCell } from "@coreui/react-pro"

export const PhoneInput = ({ register, setValue, data, name, isReadOnly, isRequired = false }) => {
  const { formatPhoneNumber } = useUtils()

  const handlePhoneNumber = (e) => {
    const formatted = formatPhoneNumber(e.target.value)
    setValue(name, formatted)
  }

  return (
    <CFormInput
      {...register(name, {
        required: isRequired ? `연락처는 필수 입니다.` : false,
        onChange: handlePhoneNumber
      })}
      defaultValue={data ? data[name] : null}
      placeholder="- 없이 입력해주세요."
      type="tel"
      size="sm"
      className="lh-15"
      disabled={isReadOnly}
      readOnly={isReadOnly}
    />
  )
}

export const OptionList = ({ options }) => {
  return options.map((item, index) => (
    <option key={item.cod_id} value={item.cod_cd}>
      {item.cod_dsc}
    </option>
  ))
}

export const TextInput = ({ name, value, isReadOnly, isDisabled, register, registerOptions, children }) => {
  return (
    <>
      <CTableDataCell className="sf">
        <CFormInput
          {...register(name, registerOptions[name])}
          defaultValue={value}
          size="sm"
          className="lh-15"
          disabled={isDisabled}
          readOnly={isReadOnly}
        />
        {children}
      </CTableDataCell>
    </>
  )
}

export const withError = (Component) => {
  return ({ error, name, registerOptions, ...rest }) => (
    <>
      <Component name={name} registerOptions={registerOptions} {...rest}>
        {error[name] && <span>{error[name]?.message}</span>}
      </Component>
    </>
  )
}

export const withCol = (Component) => {
  return ({ colName, name, ...rest }) => (
    <>
      <CTableDataCell className="sf-label" color="gray">
        {colName}
      </CTableDataCell>
      <Component name={name} {...rest} />
    </>
  )
}
