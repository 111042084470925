import React from "react"

const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"))
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"))

//Base
const Notice = React.lazy(() => import("./views/base/notice/Notice"))
const NoticeDetail = React.lazy(() => import("./views/base/notice/NoticeDetail"))

//User
const AdminManage = React.lazy(() => import("./views/admin/page/AdminPage"))
const AdminDetail = React.lazy(() => import("./views/admin/page/AdminDetail"))

//manageMember
const MemberPage = React.lazy(() => import("./views/member/page/MemberPage"))
const MemberDetail = React.lazy(() => import("./views/member/page/MemberDetail"))

//service
const ServiceWrapper = React.lazy(() => import("./views/svcInfo/page/ServiceWrapper"))
const ServiceDetail = React.lazy(() => import("./views/svcInfo/page/ServiceDetail"))

//voca
const VocaPageWrapper = React.lazy(() => import("./views/vocaInfo/page/VocaPageWrapper"))
const VocaDetail = React.lazy(() => import("./views/vocaInfo/page/VocaDetail"))
const VocaBulkInsert = React.lazy(() => import("./views/vocaInfo/page/VocaBulkInsert"))

//inset
const InstPage = React.lazy(() => import("./views/instInfo/page/InstPage"))
const InstDetail = React.lazy(() => import("./views/instInfo/page/InstDetail"))

//vocaCat
const VocaCatDetail = React.lazy(() => import("./views/vocaCat/page/VocaCatDetail"))

//vocaQuz
const VocaQuzPage = React.lazy(() => import("./views/vocaQuz/page/VocaQuzPage"))
const VocaQuzDetail = React.lazy(() => import("./views/vocaQuz/page/VocaQuzDetail"))
const QuzWrapper = React.lazy(() => import("./views/vocaQuz/page/QuzWrapper"))

//vocaQuzQst
const VocaQuzQstDetail = React.lazy(() => import("./views/vocaQuz/page/VocaQuzQstDetail"))

//FAQ
const FAQ = React.lazy(() => import("./views/faq/page/FAQ"))
const FAQDetail = React.lazy(() => import("./views/faq/page/FAQDetail"))

//Marketing
const MarketingEmailPage = React.lazy(() => import("./views/base/marketing/page/MarketingEmailPage"))
const MarketingEmailDetail = React.lazy(() => import("./views/base/marketing/page/MarketingEmailDetail"))
//Video

const VideoPage = React.lazy(() => import("./views/video/page/VideoPage"))
const VideoDetail = React.lazy(() => import("./views/video/page/VideoDetail"))

//sent
const SentWrapper = React.lazy(() => import("./views/sentInfo/page/SentWrapper"))

//lecture
const ClsPage = React.lazy(() => import("./views/cls/page/ClsWrapper"))
const ClsDetail = React.lazy(() => import("./views/cls/page/ClsDetail"))

//Route 구성
const routes = [
  //DashBoard
  { path: "/dashboard", name: "Dashboard", element: Dashboard },

  //Base
  { path: "/base/notice", name: "Notice", element: Notice },
  { path: "/base/notice/noticeDetail", name: "Notice Details", element: NoticeDetail },

  { path: "/base/marketing", name: "이메일 목록", element: MarketingEmailPage },
  { path: "/base/marketing/marketingDetail", name: "이메일 상세", element: MarketingEmailDetail },

  { path: "/base/faq", name: "FAQ", element: FAQ },
  { path: "/base/faq/faqDetail", name: "FAQDetail", element: FAQDetail },
  { path: "/admin/manage", name: "adminManage", element: AdminManage },
  { path: "/admin/manage/detail", name: "adminDetail", element: AdminDetail },

  //member
  { path: "/member/manage", name: "memberPage", element: MemberPage },
  { path: "/member/manage/detail", name: "memberDetail", element: MemberDetail },

  //service
  { path: "/service/manage", name: "서비스 관리", element: ServiceWrapper },
  { path: "/service/manage/detail", name: "서비스 카테고리", element: ServiceDetail },
  { path: "/service/manage/detail/videos", name: "VideoPage", element: VideoPage },
  { path: "/service/manage/detail/category/:cat_id", name: "카테고리 단어", element: VocaCatDetail },
  { path: "/service/manage/detail/category/quiz", name: "퀴즈", element: VocaQuzPage },
  { path: "/service/manage/detail/category/quiz/detail", name: "퀴즈 상세", element: VocaQuzDetail },

  //voca
  { path: "/voca/manage", name: "vocaPage", element: VocaPageWrapper },
  { path: "/voca/manage/detail/:id", name: "vocaDetail", element: VocaDetail },
  { path: "/voca/bulk/insert", name: "vocaBulkInsert", element: VocaBulkInsert },

  //sent
  { path: "/sent/manage", name: "sentPage", element: SentWrapper },

  { path: "/inst/manage", name: "instPage", element: InstPage },
  { path: "/inst/manage/detail", name: "instDetail", element: InstDetail },

  //quz
  { path: "/voca/quz/qst/detail", name: "퀴즈 내용 상세", element: VocaQuzQstDetail },
  { path: "/quz/manage", name: "퀴즈 목록", element: QuzWrapper },

  { path: "/video/category/manage/detail", name: "VideoDetail", element: VideoDetail },
  { path: "/video/category/manage/detail", name: "VideoDetail", element: VideoDetail },

  //수강관리
  { path: "/lecture/manage", name: "수강 목록", element: ClsPage },
  { path: "/lecture/manage/detail/:id", name: "수강 상세", element: ClsDetail },

  // { path: "/voca/cat/detail", name: "vocaCatDetail", element: vocaCatDetail },
  //404 Not Found
  { path: "*", name: "Notfound", element: Page404 }
]

export default routes
