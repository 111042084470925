import React, { useCallback, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import {
  CForm,
  CButton,
  CTable,
  CTableBody,
  CTableRow,
  CTableDataCell,
  CFormSelect,
  CFormTextarea,
  CFormInput,
  CModalBody,
  CLoadingButton,
  CHeader,
  CHeaderNav
} from "@coreui/react-pro"

import CIcon from "@coreui/icons-react"
import { cilMediaStop } from "@coreui/icons"

//lib
import moment from "moment"
import "moment/locale/ko"
import { useForm } from "react-hook-form"
import { actionType } from "src/common/grid/GridUtils"

//api
import { useVocaSentInfoOne } from "src/views/vocaSentInfo/api/VocaSentInfoApi"

//store
import useGlobalStore from "src/GlobalStore"
import useLoginStore from "src/views/login/store/LoginStore"
import useModalStore from "../store/ModalStore"
import useVocaStore from "src/views/vocaInfo/store/VocaStore"
import useVocaSentStore from "src/views/vocaSentInfo/store/VocaSentStore"

//componets
import AppLoading from "src/components/AppLoading"
import VocaSentAudio from "src/views/vocaSentInfo/page/VocaSentAudio"
import AudioLoadingBtn from "src/views/vocaSentInfo/page/AudioLoadingBtn"

//service
import useVocaSentService from "src/views/vocaSentInfo/hook/useVocaSentService"

const VocaSentDetailModal = ({ modalData, callDataApi: mutate, reOrder }) => {
  const { siteLanguage } = useGlobalStore()
  const { login } = useLoginStore()
  const { setCloseModal } = useModalStore()
  const { vocaInfo } = useVocaStore()

  const { action, setAction, vocaSentInfo, setVocaSentInfo } = useVocaSentStore()
  const BucketType = "sentence-bucket"

  //vocaStore
  const { data, mutate: detailMutate, isLoading } = useVocaSentInfoOne(action, vocaSentInfo?.sent_id)

  //hooks
  const { insertVocaSent, updateVocaSent } = useVocaSentService()

  //status
  const ReadOnly = actionType.SELECT === action ? true : false

  const settingValue = async () => {
    const response = await mutate()
    setValue("sent_ord", response?.res_data.length + 1)
    setValue("sent_lvl", 1)
  }

  useEffect(() => {
    action === "insert" && settingValue()
  }, [action])

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue
  } = useForm({ mode: "onSubmit" })

  const registerOptions = {
    sent_en: { required: "예문(영어) 필수로 입력해주세요." },
    sent_kr: { required: "예문(한글) 필수로 입력해주세요." }
  }

  const onSubmit = useCallback(
    async (submitData, event) => {
      //파일 format 해서 보내줘야 한다... 파일 format 하는 코드 하나로 뭉치자.

      try {
        if (action === actionType.INSERT) {
          console.log("check insert data: ", submitData)
          submitData["voca_id"] = vocaInfo?.voca_id
          submitData["reg_mem_id"] = login.mem_id
          submitData["upd_mem_id"] = login.mem_id

          const sent_id = await insertVocaSent(submitData)
          setVocaSentInfo({ sent_id: sent_id })
          setCloseModal()
        }

        if (action === actionType.UPDATE) {
          submitData["sent_id"] = vocaSentInfo?.sent_id
          submitData["upd_mem_id"] = login.mem_id
          console.log("check update data: ", submitData)
          await updateVocaSent(submitData)
          reOrder && (await reOrder())
        }

        await detailMutate()
        setAction("select")
        await mutate()
      } catch (error) {
        console.error("onSubmit fail...", error)
      }
    },
    [action, vocaInfo, vocaSentInfo]
  )

  const onError = useCallback(async (e) => {
    console.log(e)
  }, [])

  const clickInfoUpdate = useCallback((e) => {
    e.preventDefault()
    reset()
    setAction(actionType.UPDATE)
  }, [])

  const clickInfoReset = useCallback((e) => {
    e.preventDefault()
    reset()
    setAction(actionType.SELECT)
  }, [])

  useEffect(() => {
    if (window.opener !== null) {
      document.body.classList.add("popup-no-scrollbars")
    }
  }, [])

  //e:action event =================================================

  if (isLoading) {
    return <AppLoading heightSize={375} />
  }

  return (
    <>
      <CModalBody className="p-1 shadow-none" style={{ width: "1115px" }}>
        <CForm onSubmit={handleSubmit(onSubmit, onError)}>
          <CTable color="light" bordered small>
            <CTableBody>
              <CTableRow>
                <CTableDataCell className="sf-label" color="gray">
                  예문 (영어)
                </CTableDataCell>
                <CTableDataCell className="sf">
                  <CFormTextarea
                    {...register("sent_en", registerOptions.sent_en)}
                    defaultValue={data?.sent_en}
                    rows={5}
                    size="sm"
                    className="lh-15"
                    disabled={ReadOnly}
                    readOnly={ReadOnly}
                  />
                </CTableDataCell>

                <CTableDataCell className="sf-label" color="gray">
                  예문 (한글)
                </CTableDataCell>
                <CTableDataCell className="sf">
                  <CFormTextarea
                    {...register("sent_kr", registerOptions.sent_kr)}
                    defaultValue={data?.sent_kr}
                    rows={5}
                    placeholder={errors?.ntc_nm && errors.board_nm.message}
                    size="sm"
                    className="lh-15"
                    disabled={ReadOnly}
                    readOnly={ReadOnly}
                  />
                </CTableDataCell>
              </CTableRow>

              <CTableRow>
                <CTableDataCell className="sf-label" color="gray">
                  레벨
                </CTableDataCell>
                <CTableDataCell className="sf">
                  <CFormSelect
                    {...register("sent_lvl", { valueAsNumber: true })}
                    key={uuidv4()}
                    defaultValue={data?.sent_lvl}
                    disabled={ReadOnly}
                    readOnly={ReadOnly}
                    className="form-select lh-18"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </CFormSelect>
                </CTableDataCell>

                <CTableDataCell className="sf-label" color="gray">
                  사용여부
                </CTableDataCell>
                <CTableDataCell className="sf">
                  <CFormSelect
                    {...register("use_yn")}
                    defaultValue={data?.use_yn}
                    key={uuidv4()}
                    disabled={ReadOnly}
                    readOnly={ReadOnly}
                    className="form-select lh-18"
                  >
                    <option value="Y">Y</option>
                    <option value="N">N</option>
                    <option value="D">D</option>
                  </CFormSelect>
                </CTableDataCell>
              </CTableRow>

              <CTableRow>
                <CTableDataCell className="sf-label" color="gray">
                  순번
                </CTableDataCell>
                <CTableDataCell className="sf">
                  <CFormInput
                    {...register("sent_ord", { valueAsNumber: true })}
                    defaultValue={data?.sent_ord}
                    type="number"
                    size="sm"
                    className="lh-15"
                    disabled={ReadOnly}
                    readOnly={ReadOnly}
                  />
                </CTableDataCell>
                <CTableDataCell className="sf-label" color="gray">
                  학습시 노출 여부
                </CTableDataCell>
                <CTableDataCell className="sf">
                  <CFormSelect
                    {...register("voca_dp_yn")}
                    defaultValue={data?.voca_dp_yn}
                    key={uuidv4()}
                    disabled={ReadOnly}
                    readOnly={ReadOnly}
                    className="form-select lh-18"
                  >
                    <option value="Y">Y</option>
                    <option value="N">N</option>
                  </CFormSelect>
                </CTableDataCell>
              </CTableRow>

              <CTableRow>
                <CTableDataCell className="sf-label" color="gray">
                  비고
                </CTableDataCell>
                <CTableDataCell colSpan={3} className="sf-xxl">
                  <CFormTextarea
                    rows={1}
                    {...register("etc")}
                    defaultValue={data?.etc}
                    placeholder={errors?.ntc_nm && errors.board_nm.message}
                    size="sm"
                    className="lh-15"
                    disabled={ReadOnly}
                    readOnly={ReadOnly}
                  />
                </CTableDataCell>
              </CTableRow>

              <CTableRow>
                <CTableDataCell className="sf-label" color="gray">
                  등록일
                </CTableDataCell>
                <CTableDataCell className="sf">
                  <span className="p-2">{data?.reg_dtm && moment.utc(data?.reg_dtm).locale("ko").format("YYYY-MM-DD")}</span>
                </CTableDataCell>

                <CTableDataCell className="sf-label" color="gray">
                  수정일
                </CTableDataCell>
                <CTableDataCell className="sf">
                  <span className="p-2">{data?.upd_dtm && moment.utc(data?.upd_dtm).locale("ko").format("YYYY-MM-DD")}</span>
                </CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>

          {action !== "insert" && (
            <>
              <CHeader className="header-content" style={window.name === "popup" ? { top: "0px" } : { top: "38px" }}>
                <CHeaderNav className="d-none d-md-flex me-auto">
                  <CIcon icon={cilMediaStop} size="sm" style={{ margin: "5px 4px 0px 0", "--ci-primary-color": "#999" }} />
                  <strong>음원 업로드</strong>
                </CHeaderNav>
                <CHeaderNav className="py-1 me-3">
                  <AudioLoadingBtn mutate={detailMutate} data={data} bucketType={BucketType} listMutate={mutate} />
                </CHeaderNav>
              </CHeader>

              <CTable color="light" bordered small>
                <CTableBody>
                  <CTableRow>
                    <VocaSentAudio
                      fileType={"sent_mp3_m"}
                      isReadOnly={ReadOnly}
                      colName={"남자음원"}
                      data={data}
                      mutate={detailMutate}
                      listMutate={mutate}
                    />
                  </CTableRow>

                  <CTableRow>
                    <VocaSentAudio
                      fileType={"sent_mp3_w"}
                      isReadOnly={ReadOnly}
                      colName={"여자음원"}
                      data={data}
                      mutate={detailMutate}
                      listMutate={mutate}
                    />
                  </CTableRow>

                  <CTableRow>
                    <VocaSentAudio
                      fileType={"sent_mp3_k"}
                      isReadOnly={ReadOnly}
                      colName={"한글음원"}
                      data={data}
                      mutate={detailMutate}
                      listMutate={mutate}
                    />
                  </CTableRow>
                </CTableBody>
              </CTable>
            </>
          )}

          <div className="d-flex justify-content-center">
            {
              {
                insert: (
                  <span>
                    <CButton onClick={handleSubmit(onSubmit)} color="primary" className="px-4 mx-1" size="sm">
                      {siteLanguage.btnName.save}
                    </CButton>
                    <CButton onClick={() => setCloseModal()} className="px-4" size="sm" color="dark">
                      닫기
                    </CButton>
                  </span>
                ),
                select: (
                  <span>
                    {
                      <CButton onClick={(e) => clickInfoUpdate(e)} color="primary" className="px-4 mx-1" size="sm">
                        {siteLanguage.btnName.update}
                      </CButton>
                    }
                    <CButton onClick={() => setCloseModal()} className="px-4" size="sm" color="dark">
                      닫기
                    </CButton>
                  </span>
                ),
                update: (
                  <span>
                    <CLoadingButton onClick={handleSubmit(onSubmit)} color="primary" size="sm" className="px-4 mx-1">
                      {siteLanguage.btnName.save}
                    </CLoadingButton>
                    <CButton onClick={(e) => clickInfoReset(e, "select", true)} color="dark" className="px-4" size="sm">
                      {siteLanguage.btnName.cancel}
                    </CButton>
                  </span>
                )
              }[action]
            }
          </div>
        </CForm>
      </CModalBody>
    </>
  )
}

export default VocaSentDetailModal
