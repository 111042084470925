import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { persist, createJSONStorage } from "zustand/middleware"

const VocaCatStore = (set) => ({
  action: "select",
  setAction: (_state) => set((state) => ({ action: _state })),

  vocaCat: {},
  setVocaCat: (_vocaCat) => set((state) => ({ vocaCat: _vocaCat })),

  quzCatId: 0,
  setQuzCatId: (_quzCatId) => set((state) => ({ quzCatId: _quzCatId }))
})

const persistConfig = {
  name: "VocaCatStore",
  storage: createJSONStorage(() => sessionStorage)
}

const useVocaCatStore = create(devtools(persist(VocaCatStore, persistConfig), { name: persistConfig.name }))
export default useVocaCatStore
