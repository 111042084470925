import React, { useEffect, useState } from "react"
import { CButton, CTableDataCell, CSpinner } from "@coreui/react-pro"

//api

//service
import useVocaSentService from "../hook/useVocaSentService"
//hooks
import useS3Client from "src/common/hook/s3/useS3Client"

//store
import useVocaSentStore from "../store/VocaSentStore"

//utiles
import useUtils from "src/common/utils/useUtils"

const VocaSentAudio = ({ fileType, data, colName, mutate, listMutate }) => {
  //작업의 요점 1가지 state를 관리하는것으로 줄이고 나머지 필요한 함수는 hook 패턴으로 정리헤서 추상화

  const { vocaSentInfo } = useVocaSentStore()
  const { deleteVocaSentFileInfo } = useVocaSentService()
  const { getFileRegisterDate } = useUtils()

  const { getR2PreSignedUrl, deleteR2File } = useS3Client()
  const BucketType = "sentence-bucket"
  const [mp3, setMp3] = useState({
    src: null,
    loading: false,
    name: "",
    colName: colName,
    reg_dtm: ""
  })

  const getAudioSrcThenSetSrc = async (data) => {
    //loading...
    setMp3((pre) => ({ ...pre, loading: true }))
    const response = await getR2PreSignedUrl(BucketType, data[fileType])
    setMp3((pre) => ({
      ...pre,
      src: response,
      loading: false,
      name: fileType,
      colName: colName,
      reg_dtm: getFileRegisterDate(data[fileType])
    }))
  }

  const setEmpty = () => {
    setMp3((pre) => ({ ...pre, src: null }))
  }

  useEffect(() => {
    console.log("file check", data[fileType])
    data[fileType] ? getAudioSrcThenSetSrc(data) : setEmpty()
  }, [data])

  // const deleteCloudFlareFile = async () => {
  //   const fileName = data[fileType]
  //   const ok = window.confirm(`${fileName}\n 음원을 삭제하시겠습니까?`)

  //   if (ok) {
  //     try {
  //       if (fileName) {
  //         await deleteR2File(BucketType, fileName)

  //         await deleteVocaSentFileInfo({
  //           sent_id: vocaSentInfo?.sent_id,
  //           [fileType]: data[fileType]
  //         })
  //         window.alert(`${fileName}\n 음원을 삭제했습니다.`)

  //         setMp3((pre) => ({ ...pre, src: null }))
  //         await mutate()
  //         await listMutate()
  //       }
  //     } catch (error) {
  //       console.error("delete r2 file fail...", error)
  //     }
  //   }
  // }

  return (
    <>
      {/* <CTableDataCell className="sf-label" color="gray">
        {colName} 파일명
      </CTableDataCell>
      <CTableDataCell className="sf-xxl">
        {mp3.loading ? <CSpinner color="primary" /> : <div className="p-2">{data[fileType]}</div>}
      </CTableDataCell> */}

      <CTableDataCell className="sf-label" color="gray">
        {colName} 오디오
      </CTableDataCell>
      <CTableDataCell className="sf" colSpan={2}>
        {mp3.loading ? (
          <CSpinner color="primary" />
        ) : (
          mp3.src && (
            <div className="d-flex align-items-center justify-content-start align-items-center">
              <div className="d-flex">
                <audio key={mp3.src} controls>
                  <source src={mp3.src} type="audio/mpeg" />
                </audio>
              </div>
              <div className="d-flex flex-column ms-3">
                <strong>파일명</strong>
                <div>{data[fileType]}</div>
              </div>
            </div>
          )
        )}
      </CTableDataCell>
      <CTableDataCell className="sf-label" color="gray">
        등록일
      </CTableDataCell>
      <CTableDataCell className="sf" style={{ width: "150px" }}>
        <div className="p-2">{mp3.reg_dtm}</div>
      </CTableDataCell>
    </>
  )
}

export default VocaSentAudio
