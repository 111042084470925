import React, { useCallback, useMemo, useEffect, forwardRef } from "react"
import { useNavigate } from "react-router-dom"

//ui component
import { CRow, CCol, CContainer } from "@coreui/react-pro"

import { AgGridReact } from "ag-grid-react"
import "ag-grid-enterprise"
import "ag-grid-enterprise/dist/styles/ag-grid.css"
import "ag-grid-enterprise/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise/dist/styles/ag-theme-alpine-dark.css"

//utiles
import { sideBarConfig, defaultColConfig, autoGroupColumnDef, svcGridCommonOptions } from "src/common/grid/GridConfig"

import moment from "moment"
import "moment/locale/ko"

import { filterParams } from "src/common/grid/GridUtils"
import useBoardStore from "../store/BoardStore"

//store
import useGlobalStore from "src/GlobalStore"
import useGridStore from "src/common/grid/store/GridStore"

const BoardGrid = forwardRef(({ board_key, board_api, board_set }, ref) => {
  const pageName = "BoardGrid"

  // const gridRef = useRef(null)
  const navigate = useNavigate()
  const { setAction } = useBoardStore()

  const { siteLanguage, gridLanguage } = useGlobalStore()

  const auth = "S"

  const {
    useYn,
    windowHeight,
    setWindowHeight,
    setRowDoubleClicked,
    onColumnRowGroupChanged,
    onGridReadyStore,
    onFirstDataRenderedStore,
    onRowDataUpdatedStore,
    onSortChanged,
    onFilterChanged,
    onGridSizeChanged,
    onModelUpdatedStore
  } = useGridStore()

  const { data: listData, isLoading: listLoading, mutate: listMutate } = board_api(useYn.yn, board_key)

  const columnDefs = useMemo(
    () => [
      {
        field: "",
        width: 30,
        minWidth: 30,
        maxWidth: 30,
        editable: false,
        hide: false,
        headerCheckboxSelection: () => true,
        //checkboxSelection: (params) => !params.node.group
        checkboxSelection: (params) => true
      },
      {
        headerName: siteLanguage.gridDefult.no,
        field: "id",
        cellStyle: { textAlign: "right", paddingLeft: "2px", paddingRight: "2px" },
        valueGetter: "node.rowIndex + 1",
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        getQuickFilterText: () => ""
      },
      {
        headerName: "ID",
        field: "board_id",
        hide: true,
        width: 100,
        minWidth: 100,
        filter: "agNumberColumnFilter"
      },
      {
        headerName: siteLanguage.base.notice.notiGrid.ntc_nm,
        field: "board_nm",
        tooltipField: "board_nm",
        width: 350,
        minWidth: 350,
        enableRowGroup: true,
        filter: "agTextColumnFilter"
      },
      // {
      //   headerName: siteLanguage.base.notice.notiGrid.ntc_dsc,
      //   field: "board_dsc",
      //   tooltipField: "board_dsc",
      //   width: 260,
      //   minWidth: 260,
      //   cellRenderer: (params) => {
      //     return <div className="mt-2">{params.value}</div>
      //   },
      //   filter: "agTextColumnFilter"
      // },
      {
        headerName: siteLanguage.gridDefult.updUser,
        headerTooltip: siteLanguage.gridDefult.updUser_t,
        field: "upd_mem_nm",
        tooltipField: "upd_mem_nm",
        width: 150,
        minWidth: 150,
        maxWidth: 180,
        enableRowGroup: true,
        filter: "agTextColumnFilter"
      },
      {
        headerName: siteLanguage.gridDefult.updDt,
        headerTooltip: siteLanguage.gridDefult.updDt_t,
        field: "upd_dtm",
        tooltipField: "upd_dtm",
        width: 150,
        minWidth: 150,
        maxWidth: 180,
        cellRenderer: (params) => {
          return <div>{params.value && <span>{moment.utc(params.value).locale("ko").format("YYYY-MM-DD")}</span>}</div>
        },
        filter: "agTextColumnFilter"
      }
      // {
      //   headerName: siteLanguage.gridDefult.useYn,
      //   field: "use_yn",
      //   headerClass: "ag-center-header",
      //   width: 60,
      //   minWidth: 60,
      //   maxWidth: 80,
      //   filter: true,
      //   hide: auth ? false : true,
      //   cellRenderer: (params) => {
      //     return <div className="mt-2">{params.value}</div>
      //   },
      //   getQuickFilterText: () => ""
      // }
    ],
    [siteLanguage, auth]
  )

  //s 필수 Grid처리 ===============================================================================
  const onGridReady = useCallback(
    (params) => {
      onGridReadyStore(params, pageName)
    },
    [listData]
  )

  const onFirstDataRendered = useCallback(
    (params) => {
      onFirstDataRenderedStore(params)
    },
    [listData]
  )

  const onRowDataUpdated = useCallback(
    (params) => {
      onRowDataUpdatedStore(listLoading, params)
    },
    [listLoading]
  )

  const onModelUpdated = useCallback((params) => {
    onModelUpdatedStore(params)
  }, [])

  //e 필수 Grid처리 ===============================================================================

  /* S: Action처리 ===============================================================================*/

  //상세 더블클릭
  const onRowDoubleClicked = useCallback((params) => {
    if (params.data) {
      setRowDoubleClicked(params)
      navigate(`/base/${board_key}/${board_key}Detail`)
      board_set(params.data)
      setAction("select")
    }
  }, [])

  /* E: Action처리 ===============================================================================*/

  useEffect(() => {
    window.addEventListener("resize", () => setWindowHeight(window.innerHeight))
    if (window.opener !== null) {
      document.body.classList.add("popup-no-scrollbars")
    }
    return () => {
      window.removeEventListener("resize", () => setWindowHeight(window.innerHeight))
    }
  }, [window.innerHeight])

  if (listData?.length === 0) {
    ref.current?.api?.showNoRowsOverlay()
  }

  return (
    <>
      <CContainer>
        <CRow>
          <CCol className="p-0">
            <div className="ag-theme-alpine" style={{ height: `${windowHeight - 239}px` }}>
              <AgGridReact
                ref={ref}
                rowData={listData}
                localeText={gridLanguage}
                //Grid Config
                columnDefs={columnDefs}
                sideBar={useMemo(() => sideBarConfig())}
                defaultColDef={useMemo(() => defaultColConfig())}
                gridOptions={useMemo(() => svcGridCommonOptions())}
                rowGroupPanelShow="always"
                //Grid Group Config
                autoGroupColumnDef={autoGroupColumnDef}
                //groupMaintainOrder={true}
                //groupDisplayType={'singleColumn'}
                //suppressDragLeaveHidesColumns={true}
                //suppressMakeColumnVisibleAfterUnGroup={true}
                //suppressRowGroupHidesColumns={true}
                //groupHideOpenParents={true}
                //masterDetail={false}
                groupSelectsChildren={true}
                suppressAggFuncInHeader={true}
                groupDefaultExpanded={-1}
                // onColumnRowGroupChanged={onColumnRowGroupChanged}

                //Grid 이벤트
                onRowDataUpdated={onRowDataUpdated}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                onGridSizeChanged={onGridSizeChanged}
                onRowDoubleClicked={onRowDoubleClicked}
                onSortChanged={onSortChanged}
                onFilterChanged={onFilterChanged}
                onColumnRowGroupChanged={onColumnRowGroupChanged}
                onModelUpdated={onModelUpdated}
                //onPaginationChanged={useCallback((params) => onPaginationChanged(params))}
                //onCellClicked={onCellClicked}
                //Master, Detail설정
                masterDetail={false}
                //DateComponent

                //그외설정
                //overlayNoRowsTemplate={"No Data"}
                rowSelection={"multiple"}
                suppressRowClickSelection={true}
                suppressContextMenu={true}
                checkboxSelection={true}
                isRowSelectable={() => {
                  return true
                }}
              />
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
})

export default BoardGrid
