import { useCallback } from "react"
import { useClsDelete, useClsUpdate, useClsDtlUpdate } from "../api/ClsApi"
import { useMitt } from "react-mitt"
import SITE_MESSAGE from "src/assets/language/site.message"
const useClsService = () => {
  const { emitter } = useMitt()
  const { trigger: deleteCls } = useClsDelete()
  const { trigger: reqUpdateCls } = useClsUpdate()
  const { trigger: reqUpdateClsDtl } = useClsDtlUpdate()

  const deleteClsList = useCallback(async (ref) => {
    try {
      const ok = window.confirm("선택한 수강을 삭제하시겠습니까?")
      if (!ok) return

      const list = ref.current.api.getSelectedRows()

      if (list.length === 0) {
        emitter.emit("alert", { message: "삭제할 수강을 선택해주세요.", type: 300 })
        return
      }
      const targetIds = list.map((item) => item.cls_id)

      const response = await deleteCls(targetIds)
      console.log("after delete cls ", response)
      emitter.emit("alert", { message: SITE_MESSAGE.delete_success, type: 200 })
    } catch (error) {
      console.error("delete cls list fail", error)
      emitter.emit("alert", { message: SITE_MESSAGE.delete_fail, type: 500 })
    }
  }, [])

  const updateCls = useCallback(async (data) => {
    try {
      const response = await reqUpdateCls(data)
      console.log("after updateClsDtl cls ", response)
      emitter.emit("alert", { message: SITE_MESSAGE.update_success, type: 200 })
    } catch (error) {
      console.error("delete cls list fail", error)
      emitter.emit("alert", { message: SITE_MESSAGE.update_fail, type: 500 })
    }
  }, [])

  const updateClsDtl = useCallback(async (data) => {
    try {
      const response = await reqUpdateClsDtl(data)
      console.log("after updateClsDtl cls ", response)
      emitter.emit("alert", { message: SITE_MESSAGE.update_success, type: 200 })
    } catch (error) {
      console.error("delete cls list fail", error)
      emitter.emit("alert", { message: SITE_MESSAGE.update_fail, type: 500 })
    }
  }, [])

  return {
    deleteClsList,
    updateCls,
    updateClsDtl
  }
}

export default useClsService
