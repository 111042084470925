import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { persist, createJSONStorage } from "zustand/middleware"

const VocaQuzOptStore = (set) => ({
  action: "select",
  setAction: (_state) => set((state) => ({ action: _state })),

  vocaQuzOpt: {},
  setVocaQuzOpt: (_state) => set((state) => ({ vocaQuzOpt: _state }))
})

const persistConfig = {
  name: "useVocaQuzQstStore",
  storage: createJSONStorage(() => sessionStorage)
}

const useVocaQuzOptStore = create(devtools(persist(VocaQuzOptStore, persistConfig), { name: persistConfig.name }))
export default useVocaQuzOptStore
