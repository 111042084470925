import axiosSpring from "src/common/AxiosSpring"
import useSWR from "swr"
import useSWRMutation from "swr/mutation"

//등록
export const useImageUploadUrl = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/image/direct/upload`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

export const useImageDetail = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/image/detail`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

export const useImageGetDetail = (image_id) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    image_id ? `/admin/image/detail?image_id=${image_id}` : null,
    fetcher
  )
  return { data: data?.res_data || [], error, isLoading, mutate, isValidating }
}

//카테고리 삭제시 카테고리 이미지 리소스 정리
export const useImageDelete = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/image/delete`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//Stream
export const useVideoList = (keyword) => {
  const fetcher = (arg) => axiosSpring.get(arg).then((res) => res.data)
  const { data, error, isLoading, mutate } = useSWR(`/admin/videos?keyword=${keyword}`, fetcher)
  return { data, isLoading, error, mutate }
}

export const useVideoDetail = (id) => {
  const fetcher = (arg) => axiosSpring.get(arg).then((res) => res.data)
  const { data, error, isLoading, mutate } = useSWR(id ? `/admin/video/detail?id=${id}` : null, fetcher)
  return { data, isLoading, error, mutate }
}

export const useVideoDetailV2 = (id) => {
  const fetcher = (arg) => axiosSpring.get(arg).then((res) => res.data)
  const { data, error, isLoading, mutate } = useSWR(id ? `/admin/video/v2/detail?id=${id}` : null, fetcher)
  return { data, isLoading, error }
}

export const useVideoEdit = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg).then((res) => res.data)
  const { trigger, isMutating } = useSWRMutation(`/admin/video/edit`, fetcher)
  return { trigger, isMutating }
}

export const useVideoDelete = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg).then((res) => res.data)
  const { trigger, isMutating } = useSWRMutation(`/admin/video/delete`, fetcher)
  return { trigger, isMutating }
}

export const useVideoRequest = (id) => {
  const fetcher = (arg) => axiosSpring.get(arg).then((res) => res.data)
  const { data, error, isLoading, mutate } = useSWR(id ? `/admin/video/token?id=${id}` : null, fetcher)
  return { data, isLoading, error, mutate }
}

//등록
export const useAudioConvert = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/audio/upload`, fetcher)
  return { trigger, isMutating, data, error, reset }
}
