import axiosSpring from "src/common/AxiosSpring"
import useSWR from "swr"
import useSWRMutation from "swr/mutation"

export const useAdminList = (useYn) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, mutate } = useSWR(`/admin/list?use_yn=${useYn}`, fetcher)
  return { data: data?.res_data || [], error, isLoading, mutate }
}

//상세
export const useAdminOne = (mem_acct) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, mutate } = useSWR(mem_acct ? `/admin/detail?mem_acct=${mem_acct}` : null, fetcher)
  return { data: data?.res_data || {}, error, isLoading, mutate }
}

export const useAdminisValid = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/memacct/isvalid`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

export const useAdminEmailisValid = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/email/isvalid`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//등록
export const useAdminInsert = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/insert`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//업데이트
export const useAdminUpdate = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/update`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

export const useAdminPasswordUpdate = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/password/update`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//삭제
export const useAdminDelete = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/delete`, fetcher)
  return { trigger, isMutating, data, error, reset }
}
