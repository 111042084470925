import axiosSpring from "src/common/AxiosSpring"
import useSWR from "swr"
import useSWRMutation from "swr/mutation"

//업데이트
export const useGetPreSignedUrl = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/getPreSignedUrl`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

export const useUpdateVocaFieInfo = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/update/file`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

export const useDeleteVocaFieInfo = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/delete/file`, fetcher)
  return { trigger, isMutating, data, error, reset }
}
