import React, { useCallback } from "react"
import {
  CForm,
  CButton,
  CCard,
  CCardBody,
  CTable,
  CTableBody,
  CTableRow,
  CTableDataCell,
  CFormInput,
  CModalBody
} from "@coreui/react-pro"

//store
import useModalStore from "../store/ModalStore"
import useMemberStore from "src/views/member/store/MemberStore"
import useLoginStore from "src/views/login/store/LoginStore"

import { useForm } from "react-hook-form"

const PasswordChangeModal = ({ modalData, dataRegister, callDataApi: mutate }) => {
  //modalData membere, mgr 각각 들어오는 String 값으로 회원 인지 책임자 비밀번호인지 결정됨
  const { setCloseModal } = useModalStore()
  const { memberInfo } = useMemberStore()
  const { login } = useLoginStore()

  // const Com = Component

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue
  } = useForm({ mode: "onSubmit" })

  const onSubmit = useCallback(
    async (data) => {
      console.log(data)

      data["mem_id"] = memberInfo?.mem_id
      data["upd_mem_id"] = login.mem_id
      console.log("update 하는 데이터 => ", data)
      await dataRegister(data)
      await mutate()
      setCloseModal()
    },
    [memberInfo, modalData, login]
  )

  const onError = useCallback(async (e) => {
    console.log(e)
  }, [])

  return (
    <>
      <CModalBody className="p-1">
        <CCard className="shadow-none">
          <CCardBody className="p-0">
            <CForm onSubmit={handleSubmit(onSubmit, onError)}>
              <CTable color="light" bordered small>
                <CTableBody>
                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray">
                      변경할 비밀번호
                    </CTableDataCell>
                    <CTableDataCell className="sf-xl">
                      <CFormInput {...register(modalData)} size="sm" className="lh-15" />
                    </CTableDataCell>
                  </CTableRow>
                </CTableBody>
              </CTable>
              <div className="d-flex justify-content-center">
                <span>
                  <CButton onClick={handleSubmit(onSubmit)} color="primary" className="px-4 mx-1" size="sm">
                    변경
                  </CButton>
                  <CButton onClick={() => setCloseModal()} className="px-4" size="sm" color="dark">
                    닫기
                  </CButton>
                </span>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CModalBody>
    </>
  )
}

export default PasswordChangeModal
