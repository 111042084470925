import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { persist, createJSONStorage } from "zustand/middleware"

const ServiceStore = (set) => ({
  action: "select",
  setAction: (_state) => set((state) => ({ action: _state })),

  serviceInfo: {},
  setServiceInfo: (_serviceInfo) => set((state) => ({ serviceInfo: _serviceInfo }))
})

const persistConfig = {
  name: "serviceStore",
  storage: createJSONStorage(() => sessionStorage)
}

const useServiceStore = create(devtools(persist(ServiceStore, persistConfig), { name: persistConfig.name }))
export default useServiceStore
