import axiosSpring from "src/common/AxiosSpring"
import useSWR from "swr"
import useSWRMutation from "swr/mutation"

export const useServiceList = (useYn) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(`/admin/svc/list?use_yn=${useYn}`, fetcher)
  return { data: data?.res_data || [], error, isLoading, isValidating, mutate }
}

//상세
export const useServiceOne = (action, svc_id) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, mutate } = useSWR(
    action !== "insert" && svc_id ? `/admin/svc/detail?svc_id=${svc_id}` : null,
    fetcher
  )
  return { data: data?.res_data || {}, error, isLoading, mutate }
}

//등록
export const useServiceCatList = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/svc/cat/list`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//등록
export const useServiceInsert = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/svc/insert`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//업데이트
export const useServiceUpdate = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/svc/update`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//삭제
export const useServiceDelete = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/svc/delete`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//정렬
export const useServiceOrder = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/svc/order`, fetcher)
  return { trigger, isMutating, data, error, reset }
}
