import { useServiceInsert, useServiceUpdate, useServiceDelete, useServiceOrder } from "../api/ServiceApi"
import { useMitt } from "react-mitt"
import SITE_MESSAGE from "src/assets/language/site.message"

const useService = () => {
  const { trigger: requestInsertService } = useServiceInsert()
  const { trigger: requestUpdateService } = useServiceUpdate()
  const { trigger: requestDeleteService } = useServiceDelete()
  const { trigger: requestOrderService } = useServiceOrder()

  const { emitter } = useMitt()

  const insertService = async (data) => {
    try {
      const response = await requestInsertService(data)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.insert_success, type: 200 })
      }

      return response.data.res_data
    } catch (error) {
      console.error("insertService error:", error)
      emitter.emit("alert", { message: SITE_MESSAGE.insert_fail, type: 500 })
    }
  }

  const updateService = async (data) => {
    try {
      const response = await requestUpdateService(data)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.update_success, type: 200 })
      }
    } catch (error) {
      console.error("updateService error:", error)
      emitter.emit("alert", { message: SITE_MESSAGE.update_fail, type: 500 })
    }
  }

  const deleteService = async (gridRef) => {
    try {
      const toDeleteServices = gridRef.current.api.getSelectedRows().map((item) => item.svc_id)

      if (toDeleteServices.length === 0) {
        window.alert("삭제할 데이터를 선택해주세요")
        return
      }

      const response = await requestDeleteService(toDeleteServices)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.delete_success, type: 200 })
      }
    } catch (error) {
      console.error("deleteService error:", error)
      emitter.emit("alert", { message: SITE_MESSAGE.delete_fail, type: 500 })
    }
  }

  const reOrderThenMutate = async (gridRef, mutate) => {
    const list = gridRef.current.api.getModel().rowsToDisplay.map((item) => item.data)
    const order = list.map((item, index) => ({ svc_id: item.svc_id, svc_ord: index + 1 }))

    if (order.length === 0) {
      emitter.emit("alert", { message: "정렬할 데이터가 없습니다.", type: 500 })
      return
    }
    try {
      const response = await requestOrderService(order)

      console.log("check response : ", response)

      await mutate()
    } catch (error) {
      emitter.emit("alert", { message: "정렬에 실패했습니다, 관리자에게 문의해주세요.", type: 500 })
    }
  }
  return {
    insertService,
    updateService,
    deleteService,
    reOrderThenMutate
  }
}

export default useService
