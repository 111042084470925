import React, { useCallback, useMemo, useEffect, useRef } from "react"

import {
  CRow,
  CCol,
  CContainer,
  CButton,
  CInputGroup,
  CFormInput,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle
} from "@coreui/react-pro"
import CIcon from "@coreui/icons-react"
import { cilReload } from "@coreui/icons"

import { AgGridReact } from "ag-grid-react"
import "ag-grid-enterprise"
import "ag-grid-enterprise/dist/styles/ag-grid.css"
import "ag-grid-enterprise/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise/dist/styles/ag-theme-alpine-dark.css"

//utiles
import { svcGridCommonOptions, sideBarConfig, defaultColConfig, autoGroupColumnDef } from "src/common/grid/GridConfig"

//store
import useGlobalStore from "src/GlobalStore"
import useLoginStore from "src/views/login/store/LoginStore"
import useGridStore from "src/common/grid/store/GridStore"
import useVocaCatStore from "src/views/vocaCat/store/VocaCatStore"
//api
import { useVocaCatMapInsert } from "src/views/vocaCatMap/api/VocaCatMapApi"
//lib
import moment from "moment"
import "moment/locale/ko"

const VocaGrid = ({ clickToSet, callDataApi }) => {
  const {
    useYn,
    dataSearch,
    windowHeight,
    searchToggle,
    setWindowHeight,
    setGridStateOnReload,
    setRowDoubleClicked,
    onUseYnChange,
    onColumnRowGroupChanged,
    onSearchChange,
    onKeyDown,
    onGridReadyStore,
    onFirstDataRenderedStore,
    onRowDataUpdatedStore,
    onSortChanged,
    onFilterChanged,
    onGridSizeChanged,
    onModelUpdatedStore
  } = useGridStore()

  const pageName = "VocaGrid"

  const gridRef = useRef(null)

  const { siteLanguage, gridLanguage } = useGlobalStore()
  const { login, bucketList } = useLoginStore()
  const { vocaCat } = useVocaCatStore()

  //callDataApi 함수는 PORPS 전달받은 함수
  const { data: vocaList, isLoading, isValidating } = callDataApi(useYn.yn, vocaCat?.cat_id)
  const { trigger: requestCatVocaMapInsert } = useVocaCatMapInsert()

  //store
  const auth = "S"

  const columnDefs = useMemo(
    () => [
      {
        field: "",
        width: 30,
        minWidth: 30,
        maxWidth: 30,
        editable: false,
        //check box
        // clickToSet === undefined && true,
        headerCheckboxSelection: () => false,
        checkboxSelection: (params) => false
      },
      {
        headerName: "ID",
        field: "voca_id",
        hide: true,
        width: 100,
        minWidth: 100,
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "단어",
        field: "voca_tg",
        tooltipField: "voca_tg",
        width: 120,
        minWidth: 120,
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return (
            <div className="mt-2">
              <strong
                className="align-items-center"
                style={{
                  border: "0px solid silver",
                  borderRadius: 20,
                  padding: "3px 10px",
                  // backgroundColor: "white",
                  color: "black"
                }}
              >
                <span>{params.value}</span>
              </strong>
            </div>
          )
        }
      },
      {
        headerName: "단어 의미",
        field: "voca_mean",
        tooltipField: "voca_mean",
        width: 120,
        minWidth: 120,
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return (
            <div className="mt-2">
              <strong
                className="align-items-center"
                style={{
                  border: "0px solid silver",
                  borderRadius: 20,
                  padding: "3px 10px",
                  // backgroundColor: "white",
                  color: "black"
                }}
              >
                <span>{params.value}</span>
              </strong>
            </div>
          )
        }
      },
      {
        headerName: "단어 타입",
        field: "voca_typ",
        tooltipField: "voca_typ",
        width: 100,
        minWidth: 100,
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          const vocaTyp = bucketList.find((item) => item.value === params.value)
          return <div className="mt-2">{vocaTyp?.label}</div>
        }
      },
      // {
      //   headerName: "품사",
      //   field: "voca_pos",
      //   tooltipField: "voca_pos",
      //   width: 100,
      //   minWidth: 100,
      //   enableRowGroup: true,
      //   filter: "agTextColumnFilter",
      //   cellRenderer: (params) => {
      //     return <div className="mt-2">{params?.label}</div>
      //   }
      // },
      {
        headerName: "단어 기본 레벨",
        cellStyle: { textAlign: "center" },
        field: "voca_lvl",
        tooltipField: "voca_lvl",
        width: 100,
        minWidth: 100,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return (
            <div className="mt-2">
              <span>{params.value}</span>
            </div>
          )
        }
      },
      {
        headerName: "등록일",
        field: "reg_dtm",
        tooltipField: "reg_dtm",
        width: 100,
        minWidth: 100,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return (
            <div className="mt-2">
              <span>{params.value && moment.utc(params.value).locale("ko").format("YYYY-MM-DD")}</span>
            </div>
          )
        }
      },
      {
        headerName: "수정일",
        field: "upd_dtm",
        tooltipField: "upd_dtm",
        width: 100,
        minWidth: 100,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return (
            <div className="mt-2">
              <span>{params.value && moment.utc(params.value).locale("ko").format("YYYY-MM-DD")}</span>
            </div>
          )
        }
      }
    ],
    [siteLanguage]
  )

  //s 필수 Grid처리 ===============================================================================
  const onGridReady = useCallback(
    (params) => {
      onGridReadyStore(params, pageName)
    },
    [vocaList]
  )

  const onFirstDataRendered = useCallback(
    (params) => {
      //   onFirstDataRenderedStore(params)
    },
    [vocaList]
  )

  const onRowDataUpdated = useCallback(
    (params) => {
      onRowDataUpdatedStore(isLoading, params)
    },
    [isLoading]
  )

  const onModelUpdated = useCallback((params) => {
    onModelUpdatedStore(params)
  }, [])

  //e 필수 Grid처리 ===============================================================================

  /* S: Action처리 ===============================================================================*/

  const clickDataReload = useCallback(() => {
    console.log(`${pageName} - clickDataReload`)
    setGridStateOnReload(gridRef)
  }, [])

  //상세 더블클릭
  const onRowDoubleClicked = useCallback(
    (params) => {
      if (params.data && clickToSet) {
        clickToSet(params.data)
      }
    },
    [clickToSet]
  )

  //검색바 동작
  const searchChange = useCallback((e) => {
    onSearchChange(e)
  }, [])

  //검색바 엔터처리
  const searchKeyDown = useCallback((e) => {
    onKeyDown(e, gridRef)
  }, [])

  /* E: Action처리 ===============================================================================*/

  useEffect(() => {
    window.addEventListener("resize", () => setWindowHeight(window.innerHeight))
    if (window.opener !== null) {
      document.body.classList.add("popup-no-scrollbars")
    }
    return () => {
      window.removeEventListener("resize", () => setWindowHeight(window.innerHeight))
    }
  }, [window.innerHeight])

  if (vocaList?.length === 0) {
    gridRef.current?.api?.showNoRowsOverlay()
  }

  return (
    <>
      <CContainer>
        <CRow>
          <CCol className="px-3 py-2 mb-2 border">
            <CRow>
              <CCol xs={12}>
                <CInputGroup size="sm">
                  <CButton size="sm" className="px-3" onClick={clickDataReload}>
                    <CIcon icon={cilReload} />
                  </CButton>
                  <CDropdown variant="input-group">
                    <CDropdownToggle
                      disabled={!auth}
                      color="secondary"
                      variant="outline"
                      className="px-3"
                      style={{ fontSize: "0.8em" }}
                    >
                      {useYn.desc}
                    </CDropdownToggle>
                    <CDropdownMenu>
                      {searchToggle.map((item, index) => (
                        <CDropdownItem
                          key={index}
                          onClick={() => onUseYnChange(item.value, item.dsc)}
                          style={{ fontSize: "0.8em" }}
                        >
                          {item.name}
                        </CDropdownItem>
                      ))}
                    </CDropdownMenu>
                  </CDropdown>

                  <CFormInput onChange={searchChange} onKeyUp={searchKeyDown} value={dataSearch} placeholder="Search" />
                  <CButton type="button" color="primary" id="button-addon2" className="px-4">
                    {siteLanguage.btnName.search}
                  </CButton>
                </CInputGroup>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow>
          <CCol className="p-0">
            <div className="ag-theme-alpine">
              <AgGridReact
                ref={gridRef}
                rowData={vocaList}
                localeText={gridLanguage}
                //Grid Config
                columnDefs={columnDefs}
                sideBar={useMemo(() => sideBarConfig())}
                defaultColDef={useMemo(() => defaultColConfig())}
                gridOptions={useMemo(() => svcGridCommonOptions())}
                rowGroupPanelShow="always"
                //Grid Group Config
                autoGroupColumnDef={autoGroupColumnDef}
                //groupMaintainOrder={true}
                //groupDisplayType={'singleColumn'}
                //suppressDragLeaveHidesColumns={true}
                //suppressMakeColumnVisibleAfterUnGroup={true}
                //suppressRowGroupHidesColumns={true}
                //groupHideOpenParents={true}
                //masterDetail={false}
                groupSelectsChildren={true}
                suppressAggFuncInHeader={true}
                groupDefaultExpanded={-1}
                // onColumnRowGroupChanged={onColumnRowGroupChanged}

                //Grid 이벤트
                onRowDataUpdated={onRowDataUpdated}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                onGridSizeChanged={onGridSizeChanged}
                onRowDoubleClicked={onRowDoubleClicked}
                onSortChanged={onSortChanged}
                onFilterChanged={onFilterChanged}
                onColumnRowGroupChanged={onColumnRowGroupChanged}
                onModelUpdated={onModelUpdated}
                //onPaginationChanged={useCallback((params) => onPaginationChanged(params))}
                //onCellClicked={onCellClicked}
                //Master, Detail설정
                masterDetail={false}
                //DateComponent
                domLayout="autoHeight"
                //그외설정
                //overlayNoRowsTemplate={"No Data"}
                rowSelection={"multiple"}
                suppressRowClickSelection={true}
                suppressContextMenu={true}
                checkboxSelection={true}
                isRowSelectable={() => {
                  return true
                }}
              />
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default VocaGrid
