import React from "react"
import CIcon from "@coreui/icons-react"
import { cilBarChart, cilClipboard, cilDrop, cilList, cilLayers } from "@coreui/icons"
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react-pro"
import dashboardStore from "src/GlobalStore"

const useNavCondition = () => {
  const { siteLanguage } = dashboardStore()

  const navCondition = [
    {
      component: CNavGroup,
      name: "사용자관리",
      to: "/condition",
      icon: <CIcon icon={cilBarChart} customClassName="nav-icon" />,
      items: [
        // {
        //   component: CNavItem,
        //   name: siteLanguage.navCondition.svcList,
        //   to: "/svc/svcList"
        // },
        // {
        //   component: CNavItem,
        //   name: siteLanguage.navCondition.statistics,
        //   to: "/system/statistics"
        // },
        {
          component: CNavItem,
          name: "관리자",
          to: "/admin/manage"
        },
        {
          component: CNavItem,
          name: "회원 관리",
          to: "/member/manage"
        }
      ]
    }
  ]

  return {
    navCondition
  }
}

export default useNavCondition
