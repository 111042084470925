import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { persist, createJSONStorage } from "zustand/middleware"

const MemberStore = (set) => ({
  action: "select",
  setAction: (_state) => set((state) => ({ action: _state })),

  memberInfo: {},
  setMemberInfo: (_memberInfo) => set((state) => ({ memberInfo: _memberInfo }))
})

const persistConfig = {
  name: "memberStore",
  storage: createJSONStorage(() => sessionStorage)
}

const useMemberStore = create(devtools(persist(MemberStore, persistConfig), { name: persistConfig.name }))
export default useMemberStore
