import React, { useCallback, useEffect, useRef, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import {
  CForm,
  CButton,
  CCard,
  CCardBody,
  CTable,
  CTableBody,
  CTableRow,
  CTableDataCell,
  CFormSelect,
  CFormInput,
  CModalBody
} from "@coreui/react-pro"

import CIcon from "@coreui/icons-react"
import { cilMediaStop } from "@coreui/icons"

//lib
import moment from "moment"
import "moment/locale/ko"
import { useMitt } from "react-mitt"
import { useForm } from "react-hook-form"
import { actionType } from "src/common/grid/GridUtils"

//store
import useGlobalStore from "src/GlobalStore"
import useLoginStore from "src/views/login/store/LoginStore"
import useVocaQuzOptStore from "src/views/vocaQuz/store/VocaQuzOptStore"
import useVocaQuzStore from "src/views/vocaQuz/store/VocaQuzStore"
import useModalStore from "../store/ModalStore"

//service
import { useVocaQuzOptOne } from "src/views/vocaQuz/api/VocaQuzApi"
import useVocaQuzService from "src/views/vocaQuz/hook/useVocaQuzService"

import AppLoading from "src/components/AppLoading"

const VocaOptDetailModal = ({ modalData, callDataApi }) => {
  //utils
  const { siteLanguage } = useGlobalStore()
  const { emitter } = useMitt()
  //store
  const { action, setAction, vocaQuzOpt, setVocaQuzOpt } = useVocaQuzOptStore()
  const { vocaQuz } = useVocaQuzStore()
  const { setCloseModal } = useModalStore()
  const { login } = useLoginStore()
  //data
  const { data, isLoading, mutate } = useVocaQuzOptOne(action, vocaQuzOpt?.opt_id)

  //service
  const { insertVocaOpt, updateVocaOpt } = useVocaQuzService()

  const ReadOnly = actionType.SELECT === action ? true : false
  const [quzGrp, setQuzGrp] = useState(1)

  const settingInsertOrd = async (group) => {
    const response = await callDataApi()
    if (action !== "insert") return

    if (group === 1) {
      setValue("opt_ord", response?.res_data.filter((item) => item.quz_grp === 1).length + 1)
      console.log(response?.res_data.filter((item) => item.quz_grp === 1).length + 1)
    } else {
      setValue("opt_ord", response.res_data.filter((item) => item.quz_grp === 2).length + 1)
      console.log(response?.res_data.filter((item) => item.quz_grp === 2).length + 1)
    }
  }

  useEffect(() => {
    settingInsertOrd(quzGrp)
  }, [quzGrp])

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue
  } = useForm({ mode: "onSubmit" })

  const registerOptions = {
    svc_nm: { required: "서비스명은 필수값입니다." },
    svc_prc: { required: "서비스 단가값은 필수값입니다" },
    svc_exp: { required: "서비스 유효기간을 입력해주세요." }
  }

  const onSubmit = useCallback(
    async (submitData, event) => {
      console.log(submitData)

      if (action === actionType.UPDATE) {
        submitData["opt_id"] = vocaQuzOpt?.opt_id
        submitData["upd_mem_id"] = login.mem_id
        await updateVocaOpt(submitData)
      }
      //modal data로 찍으 버리면. 문제는 후 개정 . 후데이터를 못가져오는 문제가 발생하네
      const { res_data: currentData } = await callDataApi()
      if (action === actionType.INSERT) {
        if (currentData.filter((item) => item.quz_grp === Number(submitData.quz_grp)).length >= 6) {
          emitter.emit("alert", { message: "문제보기 옵션은 최대 6개까지 등록 가능합니다.", type: 500 })
          return
        }
        submitData["reg_mem_id"] = login.mem_id
        submitData["upd_mem_id"] = login.mem_id
        submitData["quz_id"] = vocaQuz?.quz_id
        const optId = await insertVocaOpt(submitData)
        setVocaQuzOpt({ opt_id: optId })

        await callDataApi()
      }

      mutate(submitData)
      setAction("select")
      setCloseModal()
    },
    [vocaQuzOpt, action, login, modalData]
  )

  const onError = useCallback(async (e) => {
    console.log(e)
  }, [])

  const clickInfoUpdate = useCallback((e) => {
    e.preventDefault()
    reset()
    setAction(actionType.UPDATE)
  }, [])

  const clickInfoReset = useCallback((e) => {
    e.preventDefault()
    reset()
    setAction(actionType.SELECT)
  }, [])

  const onChangeGrp = (quzGrop) => {
    setQuzGrp(Number(quzGrop))
    Number(quzGrop) === 1
      ? setValue("opt_ord", modalData.filter((item) => item.quz_grp === 1).length + 1)
      : setValue("opt_ord", modalData.filter((item) => item.quz_grp === 2).length + 1)
  }

  useEffect(() => {
    if (window.opener !== null) {
      document.body.classList.add("popup-no-scrollbars")
    }
  }, [])

  //e:action event =================================================

  if (isLoading) {
    return <AppLoading heightSize={255} />
  }

  return (
    <>
      <CModalBody className="p-1">
        <CCard className="shadow-none mb-4">
          <CCardBody className="p-0">
            <CForm onSubmit={handleSubmit(onSubmit, onError)}>
              <CTable color="light" bordered small>
                <CTableBody>
                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray" style={{ width: "130px" }}>
                      퀴즈 그룹 *{/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormSelect
                        {...register("quz_grp", {
                          onChange: (e) => onChangeGrp(e.target.value)
                        })}
                        key={uuidv4()}
                        type="number"
                        defaultValue={data?.quz_grp}
                        className="form-select lh-18"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      >
                        <option value={1}>1번 그룹</option>
                        <option value={2}>2번 그룹</option>
                      </CFormSelect>
                      {errors.svc_nm && <span>{errors.svc_nm.message}</span>}
                    </CTableDataCell>

                    <CTableDataCell className="sf-label" color="gray" style={{ width: "130px" }}>
                      옵션 순번{/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormInput
                        {...register("opt_ord", { valueAsNumber: true })}
                        defaultValue={data?.opt_ord}
                        type="number"
                        size="sm"
                        className="lh-15"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      />
                      {/* {errors.opt_ord && <span>{errors.opt_ord.message}</span>} */}
                    </CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray" style={{ width: "130px" }}>
                      옵션 답 {/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormInput
                        {...register("opt_ans")}
                        defaultValue={data?.opt_ans}
                        size="sm"
                        className="lh-15"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      />
                    </CTableDataCell>

                    <CTableDataCell className="sf-label" color="gray" style={{ width: "130px" }}>
                      비고 {/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormInput
                        {...register("etc")}
                        defaultValue={data?.etc}
                        size="sm"
                        className="lh-15"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      />
                    </CTableDataCell>
                  </CTableRow>

                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray" style={{ width: "130px" }}>
                      사용여부 *{/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell colSpan={3} className="sf">
                      <CFormSelect
                        {...register("use_yn")}
                        key={uuidv4()}
                        defaultValue={data?.use_yn}
                        className="form-select lh-18"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      >
                        <option value="Y">Y</option>
                        <option value="N">N</option>
                        <option value="D">D</option>
                      </CFormSelect>
                    </CTableDataCell>
                  </CTableRow>

                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray" style={{ width: "130px" }}>
                      등록일 {/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <span className="p-2">{data?.reg_dtm && moment.utc(data?.reg_dtm).locale("ko").format("YYYY-MM-DD")}</span>
                    </CTableDataCell>

                    <CTableDataCell className="sf-label ms-1" color="gray" style={{ width: "130px" }}>
                      수정일 {/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <span className="p-2">{data?.upd_dtm && moment.utc(data?.upd_dtm).locale("ko").format("YYYY-MM-DD")}</span>
                    </CTableDataCell>
                  </CTableRow>
                </CTableBody>
              </CTable>
              <div className="d-flex justify-content-center">
                {
                  {
                    insert: (
                      <span>
                        <CButton onClick={handleSubmit(onSubmit)} color="primary" className="px-4 mx-1" size="sm">
                          {siteLanguage.btnName.save}
                        </CButton>
                        <CButton onClick={() => setCloseModal()} className="px-4" size="sm" color="dark">
                          닫기
                        </CButton>
                      </span>
                    ),
                    select: (
                      <span>
                        <CButton onClick={(e) => clickInfoUpdate(e)} color="primary" className="px-4 mx-1" size="sm">
                          {siteLanguage.btnName.update}
                        </CButton>
                        <CButton onClick={() => setCloseModal()} className="px-4" size="sm" color="dark">
                          닫기
                        </CButton>
                      </span>
                    ),
                    update: (
                      <span>
                        <CButton onClick={handleSubmit(onSubmit)} color="primary" size="sm" className="px-4 mx-1">
                          {siteLanguage.btnName.save}
                        </CButton>
                        <CButton onClick={(e) => clickInfoReset(e, "select", true)} color="dark" className="px-4" size="sm">
                          {siteLanguage.btnName.cancel}
                        </CButton>
                      </span>
                    )
                  }[action]
                }
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CModalBody>
    </>
  )
}

export default VocaOptDetailModal
