import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { persist, createJSONStorage } from "zustand/middleware"

const BoardStore = (set) => ({
  action: "select", //select, update, insert] (readOnly: true), ETC (readOnly: false)
  defaultOptionValue: [
    { value: "Y", label: "Y" },
    { value: "N", label: "N" },
    { value: "D", label: "D" }
  ],
  setAction: (_action) => set((state) => ({ action: _action })),

  boardInfo: {},
  setBoardInfo: (_boardInfo) => set((state) => ({ boardInfo: _boardInfo })),

  //promotions 관리되는 데이터
  promotions: [{ key: "marketing", service_name: "emailMarketing" }]
})

const persistConfig = {
  name: "BoardStore",
  storage: createJSONStorage(() => sessionStorage)
}

const useBoardStore = create(devtools(persist(BoardStore, persistConfig), { name: persistConfig.name }))
export default useBoardStore
