import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { persist, createJSONStorage } from "zustand/middleware"

const MemStdVocaStore = (set) => ({
  action: "select",
  setAction: (_state) => set((state) => ({ action: _state })),

  memStdVoca: {},
  setMemStdVoca: (_memStdVoca) => set((state) => ({ memStdVoca: _memStdVoca }))
})

const persistConfig = {
  name: "memberStore",
  storage: createJSONStorage(() => sessionStorage)
}

const useMemStdVocaStore = create(devtools(persist(MemStdVocaStore, persistConfig), { name: persistConfig.name }))
export default useMemStdVocaStore
