import React from "react"
import { v4 as uuidv4 } from "uuid"
//core ui
import { CForm, CFormInput, CInputGroup, CInputGroupText, CButton, CFormSelect, CModalBody } from "@coreui/react-pro"

//form lib
import { useForm } from "react-hook-form"

//store
import useModalStore from "../store/ModalStore"

//service
import useAdminService from "src/views/admin/hook/useAdminService"

//login
import useLoginStore from "src/views/login/store/LoginStore"

//componests
import { PhoneInput } from "src/components/AppComponents"

const InsertAdminModal = ({ callDataApi: mutate }) => {
  const { login } = useLoginStore()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    setError,
    clearErrors
  } = useForm({ mode: "onSubmit" })

  const { insertAdmin, isAdminMemAcctValid, isAdminMemEmailValid } = useAdminService()
  const { setCloseModal } = useModalStore()

  const onSubmit = async (data) => {
    console.log(data)
    data["reg_mem_id"] = login.mem_id
    data["upd_mem_id"] = login.mem_id

    await insertAdmin(data, reset)
    await mutate()
    setCloseModal()
  }

  return (
    <CModalBody>
      <CForm onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-column">
          <CInputGroup className="mb-3">
            <CInputGroupText style={{ width: "20%" }} id="basic-addon1">
              아이디
            </CInputGroupText>
            <CFormInput
              {...register("mem_acct", {
                required: "아이디는 필수로 입력해주세요",
                validate: async (value) => {
                  const isValid = await isAdminMemAcctValid(value)
                  return isValid || "이미 등록된 계정입니다."
                }
              })}
              placeholder="아이디"
              size="sm"
              className="lh-15"
            />
          </CInputGroup>
          {errors.mem_acct && <small className="d-flex mb-2 ms-2">{errors.mem_acct.message}</small>}
        </div>

        <CInputGroup className="mb-3">
          <CInputGroupText style={{ width: "20%" }} id="basic-addon1">
            비밀번호
          </CInputGroupText>
          <CFormInput
            {...register("mem_pw", {
              required: "비밀번호 값을 입력해주세요"
            })}
            placeholder="비밀번호"
            type="password"
            size="sm"
            className="lh-15"
          />
        </CInputGroup>

        <div className="d-flex flex-column">
          <CInputGroup className="mb-3">
            <CInputGroupText style={{ width: "20%" }} id="basic-addon1">
              이메일
            </CInputGroupText>
            <CFormInput
              {...register("mem_email", {
                validate: async (value) => {
                  const isValid = await isAdminMemEmailValid(value)
                  return isValid || "이미 등록된 이메일 입니다."
                }
              })}
              placeholder="ex) 12345@gmail.com"
              type="email"
              size="sm"
              className="lh-15"
            />
          </CInputGroup>
          {errors.mem_email && <small className="d-flex mb-2 ms-2">{errors.mem_email.message}</small>}
        </div>

        <CInputGroup className="mb-3">
          <CInputGroupText style={{ width: "20%" }} id="basic-addon1">
            연락처
          </CInputGroupText>

          <PhoneInput register={register} setValue={setValue} data={null} name={"mem_mbph_no"} isReadOnly={false} />
        </CInputGroup>

        <CInputGroup className="mb-3">
          <CInputGroupText style={{ width: "20%" }} id="basic-addon1">
            권한
          </CInputGroupText>
          <CFormSelect {...register("mem_auth")} key={uuidv4()} className="form-select lh-18" readOnly={true}>
            <option value="S">S : 관리자</option>
          </CFormSelect>
        </CInputGroup>

        <div className="d-flex justify-content-center">
          <CButton type="submit" className="px-4 me-2" size="sm">
            등록
          </CButton>
          <CButton onClick={() => setCloseModal()} className="px-4" size="sm">
            닫기
          </CButton>
        </div>
      </CForm>
    </CModalBody>
  )
}

export default InsertAdminModal
