import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { persist, createJSONStorage } from "zustand/middleware"

const PathStore = (set) => ({
  paths: [],
  addPath: (path) =>
    set((state) => {
      const updatedPaths = state.paths.map((p) => ({ ...p, active: false }))
      return { paths: [...updatedPaths, { ...path, active: true }] }
    }),

  removePath: () =>
    set((state) => {
      if (state.paths.length === 0) return { paths: [] }
      const updatedPaths = [...state.paths] // 새로운 배열을 생성하여 원본 배열을 복사
      updatedPaths.pop() // 배열의 마지막 요소 제거
      if (updatedPaths.length > 0) {
        updatedPaths[updatedPaths.length - 1].active = true
      }
      return { paths: updatedPaths }
    }),
  resetPath: () => set({ paths: [] })
})

const persistConfig = {
  name: "usePathStore",
  storage: createJSONStorage(() => sessionStorage)
}

const usePathStore = create(devtools(persist(PathStore, persistConfig), { name: persistConfig.name }))
export default usePathStore
