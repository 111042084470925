import React, { useCallback, useMemo, useEffect, useRef } from "react"
import {
  CRow,
  CCol,
  CHeader,
  CHeaderNav,
  CContainer,
  CButton,
  CInputGroup,
  CFormInput,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CModalBody
} from "@coreui/react-pro"
import CIcon from "@coreui/icons-react"
import { cilReload, cilMediaStop } from "@coreui/icons"

import { AgGridReact } from "ag-grid-react"
import "ag-grid-enterprise"
import "ag-grid-enterprise/dist/styles/ag-grid.css"
import "ag-grid-enterprise/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise/dist/styles/ag-theme-alpine-dark.css"

//utiles
import { svcGridCommonOptions, sideBarConfig, defaultColConfig, autoGroupColumnDef } from "src/common/grid/GridConfig"
import { useMitt } from "react-mitt"

//store
import useGlobalStore from "src/GlobalStore"
import useGridStore from "src/common/grid/store/GridStore"
import useVocaCatStore from "src/views/vocaCat/store/VocaCatStore"

//api
import { useVocaSentInfoListByCatId } from "src/views/vocaSentInfo/api/VocaSentInfoApi"

//component
import { SelectServiceCat } from "src/views/vocaQuz/page/QuzSelectHeader"
//modal
import useModalStore from "src/views/modal/store/ModalStore"

const VocaQuzSentGridModal = ({ clickToSet }) => {
  const {
    useYn,
    dataSearch,
    windowHeight,
    searchToggle,
    setWindowHeight,
    setGridStateOnReload,
    setRowDoubleClicked,
    onUseYnChange,
    onColumnRowGroupChanged,
    onSearchChange,
    onKeyDown,
    onGridReadyStore,
    onFirstDataRenderedStore,
    onRowDataUpdatedStore,
    onSortChanged,
    onFilterChanged,
    onGridSizeChanged,
    onModelUpdatedStore
  } = useGridStore()

  const pageName = "VocaSentPage"
  const { emitter } = useMitt()

  const gridRef = useRef(null)
  const { siteLanguage, gridLanguage } = useGlobalStore()
  const { vocaCat, quzCatId } = useVocaCatStore()
  const { data: vocaSentListByCatId, mutate, isLoading, isValidating } = useVocaSentInfoListByCatId(useYn.yn, quzCatId)
  const { setCloseModal } = useModalStore()

  //store
  const auth = "S"

  const columnDefs = useMemo(
    () => [
      {
        field: "",
        width: 30,
        minWidth: 30,
        maxWidth: 30,
        editable: false,
        autoHeight: true,
        // headerCheckboxSelection: () => {
        //   return true
        // },
        checkboxSelection: (params) => true
      },
      {
        headerName: "ID",
        field: "sent_id",
        hide: true,
        width: 100,
        minWidth: 100,
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "영어예문",
        field: "sent_en",
        tooltipField: "sent_en",
        width: 200,
        minWidth: 120,
        enableRowGroup: true,
        cellRenderer: (params) => {
          return (
            <strong
              className="align-items-center"
              style={{
                border: "0px solid silver",
                borderRadius: 20,
                padding: "3px 10px",
                // backgroundColor: "white",
                color: "black"
              }}
            >
              <span>{params.value}</span>
            </strong>
          )
        }
      },
      {
        headerName: "한글예문",
        field: "sent_kr",
        tooltipField: "sent_kr",
        width: 200,
        minWidth: 120,
        enableRowGroup: true,
        cellRenderer: (params) => {
          return (
            <strong
              className="align-items-center"
              style={{
                border: "0px solid silver",
                borderRadius: 20,
                padding: "3px 10px",
                // backgroundColor: "white",
                color: "black"
              }}
            >
              <span>{params.value}</span>
            </strong>
          )
        }
      },
      {
        headerName: "단어 기본 레벨",
        field: "sent_lvl",
        tooltipField: "sent_lvl",
        width: 100,
        minWidth: 100,
        cellRenderer: (params) => {
          return (
            <div
              className={`rounded-pill bg-warning text-black h-50 d-flex justify-content-center align-items-center`}
              style={{ width: "80px" }}
            >
              <strong>{params.value}</strong>
            </div>
          )
        }
      }
    ],
    [siteLanguage]
  )

  //s 필수 Grid처리 ===============================================================================
  const onGridReady = useCallback(
    (params) => {
      onGridReadyStore(params, pageName)
    },
    [vocaSentListByCatId]
  )

  const onFirstDataRendered = useCallback(
    (params) => {
      // onFirstDataRenderedStore(params)
    },
    [vocaSentListByCatId]
  )

  const onRowDataUpdated = useCallback(
    (params) => {
      onRowDataUpdatedStore(isLoading, params)
    },
    [isLoading]
  )

  const onModelUpdated = useCallback((params) => {
    onModelUpdatedStore(params)
  }, [])

  //e 필수 Grid처리 ===============================================================================

  /* S: Action처리 ===============================================================================*/

  const clickDataReload = useCallback(() => {
    console.log(`${pageName} - clickDataReload`)
    setGridStateOnReload(gridRef)
  }, [])

  //상세 더블클릭
  const onRowDoubleClicked = useCallback((params) => {
    if (params.data) {
      emitter.emit("alert", { message: "성공적으로 예문선택하였습니다.", type: 200 })
      clickToSet(params.data)
      setCloseModal()
    }
  }, [])

  //검색바 동작
  const searchChange = useCallback((e) => {
    onSearchChange(e)
  }, [])

  //검색바 엔터처리
  const searchKeyDown = useCallback((e) => {
    onKeyDown(e, gridRef)
  }, [])

  const clickDataRegist = useCallback((e) => {
    const list = gridRef.current.api.getSelectedRows()
    if (list.length > 0) {
      clickToSet(list[0])
      emitter.emit("alert", { message: "성공적으로 예문선택하였습니다.", type: 200 })
      setCloseModal()
    }
  }, [])
  /* E: Action처리 ===============================================================================*/

  useEffect(() => {
    window.addEventListener("resize", () => setWindowHeight(window.innerHeight))
    if (window.opener !== null) {
      document.body.classList.add("popup-no-scrollbars")
    }
    return () => {
      window.removeEventListener("resize", () => setWindowHeight(window.innerHeight))
    }
  }, [window.innerHeight])

  if (vocaSentListByCatId?.length === 0) {
    gridRef.current?.api?.showNoRowsOverlay()
  }

  return (
    <>
      <CModalBody>
        <CContainer>
          <CRow>
            <CCol className="px-3 py-2 mb-2 border">
              <CRow>
                <CCol xs={10}>
                  <div className="d-flex justfiy-content-around">
                    <SelectServiceCat isDisabled={false} />
                  </div>
                </CCol>
                <CCol className="d-flex justify-content-end" md={2}>
                  <CButton onClick={() => clickDataRegist()} className="px-4" size="sm" color="dark">
                    예문 선택
                  </CButton>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow>
            <CCol className="px-3 py-2 mb-2 border">
              <CRow>
                <CCol xs={12}>
                  <CInputGroup size="sm">
                    <CButton size="sm" className="px-3" onClick={clickDataReload}>
                      <CIcon icon={cilReload} />
                    </CButton>
                    <CDropdown variant="input-group">
                      <CDropdownToggle
                        disabled={!auth}
                        color="secondary"
                        variant="outline"
                        className="px-3"
                        style={{ fontSize: "0.8em" }}
                      >
                        {useYn.desc}
                      </CDropdownToggle>
                      <CDropdownMenu>
                        {searchToggle.map((item, index) => (
                          <CDropdownItem
                            key={index}
                            onClick={() => onUseYnChange(item.value, item.dsc)}
                            style={{ fontSize: "0.8em" }}
                          >
                            {item.name}
                          </CDropdownItem>
                        ))}
                      </CDropdownMenu>
                    </CDropdown>

                    <CFormInput onChange={searchChange} onKeyUp={searchKeyDown} value={dataSearch} placeholder="Search" />
                    <CButton type="button" color="primary" id="button-addon2" className="px-4">
                      {siteLanguage.btnName.search}
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow>
            {/*  */}
            <CCol className="p-0">
              <div className="ag-theme-alpine" style={{ height: `${windowHeight - 300}px` }}>
                <AgGridReact
                  ref={gridRef}
                  rowData={vocaSentListByCatId}
                  localeText={gridLanguage}
                  //Grid Config
                  columnDefs={columnDefs}
                  sideBar={useMemo(() => sideBarConfig())}
                  defaultColDef={useMemo(() => defaultColConfig())}
                  gridOptions={useMemo(() => svcGridCommonOptions())}
                  rowGroupPanelShow="never"
                  //Grid Group Config
                  autoGroupColumnDef={autoGroupColumnDef}
                  //groupMaintainOrder={true}
                  //groupDisplayType={'singleColumn'}
                  //suppressDragLeaveHidesColumns={true}
                  //suppressMakeColumnVisibleAfterUnGroup={true}
                  //suppressRowGroupHidesColumns={true}
                  //groupHideOpenParents={true}
                  //masterDetail={false}
                  groupSelectsChildren={false}
                  suppressAggFuncInHeader={true}
                  groupDefaultExpanded={-1}
                  // onColumnRowGroupChanged={onColumnRowGroupChanged}

                  //Grid 이벤트
                  onRowDataUpdated={onRowDataUpdated}
                  onGridReady={onGridReady}
                  onFirstDataRendered={onFirstDataRendered}
                  onGridSizeChanged={onGridSizeChanged}
                  onRowDoubleClicked={onRowDoubleClicked}
                  onSortChanged={onSortChanged}
                  onFilterChanged={onFilterChanged}
                  onColumnRowGroupChanged={onColumnRowGroupChanged}
                  onModelUpdated={onModelUpdated}
                  //onPaginationChanged={useCallback((params) => onPaginationChanged(params))}
                  //onCellClicked={onCellClicked}
                  //Master, Detail설정
                  masterDetail={false}
                  //DateComponent
                  //그외설정
                  //overlayNoRowsTemplate={"No Data"}

                  // domLayout="autoHeight"
                  rowSelection={"single"}
                  suppressRowClickSelection={true}
                  suppressContextMenu={true}
                  checkboxSelection={true}
                  isRowSelectable={() => {
                    return true
                  }}
                />
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
    </>
  )
}

export default VocaQuzSentGridModal
