import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { persist, createJSONStorage } from "zustand/middleware"

const LoginStore = (set) => ({
  login: null,
  //setClearSesstion: () => window.sessionStorage.clear(),
  //버킷 필드 만들기

  //   idiom-bucket

  // pattern-bucket

  // word-bucket

  // sentence-bucket

  bucketList: [
    { value: "W", label: "단어", bucket: "word-bucket" },
    { value: "I", label: "숙어", bucket: "idiom-bucket" },
    { value: "P", label: "패턴", bucket: "pattern-bucket" }
  ],

  setClearSesstion: () =>
    set((state) => {
      sessionStorage.removeItem("LoginStore")
      sessionStorage.removeItem("GlobalStore")
      sessionStorage.removeItem("SvcStore")
      sessionStorage.removeItem("OtpStore")
      return state
    }),
  setLoginSession: (_login) => set((state) => ({ login: _login }))
})

const persistConfig = {
  name: "LoginStore",
  storage: createJSONStorage(() => sessionStorage)
}

const useLoginStore = create(devtools(persist(LoginStore, persistConfig), { name: persistConfig.name }))
export default useLoginStore
