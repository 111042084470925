import axiosSpring from "src/common/AxiosSpring"
import useSWR from "swr"
import useSWRMutation from "swr/mutation"

export const useVocaCatList = (useYn, svcId) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    svcId ? `/admin/voca/cat/list?use_yn=${useYn}&svc_id=${svcId}` : null,
    fetcher
  )
  return { data: data?.res_data || [], error, isLoading, isValidating, mutate }
}

//상세
export const useVocaCatOne = (action, catId) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    action !== "insert" && catId ? `/admin/voca/cat/detail?cat_id=${catId}` : null,
    fetcher
  )
  return { data: data?.res_data || {}, error, isLoading, mutate, isValidating }
}

//등록
export const useVocaCatInsert = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/cat/insert`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//업데이트
export const useVocaCatUpdate = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/cat/update`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//삭제
export const useVocaCatDelete = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/cat/delete`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//삭제
export const useVocaCatImgDelete = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/cat/delete/img`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//업데이트
export const useVocaCatOrder = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/cat/order`, fetcher)
  return { trigger, isMutating, data, error, reset }
}
