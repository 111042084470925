import React from "react"
import CIcon from "@coreui/icons-react"
import { cilBarChart, cilClipboard, cilDrop, cilList, cilLayers, cilVideo, cilAudioDescription } from "@coreui/icons"
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react-pro"
import dashboardStore from "src/GlobalStore"

const usePromotion = () => {
  const { siteLanguage } = dashboardStore()

  const navPromotion = [
    {
      component: CNavGroup,
      name: "마케팅 관리",
      to: "/marketing",
      icon: <CIcon icon={cilAudioDescription} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: "이메일 목록",
          to: "/base/marketing"
        }
      ]
    }
  ]

  return {
    navPromotion
  }
}

export default usePromotion
