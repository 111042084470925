import React, { useEffect, useState } from "react"
import { CRow, CCard, CCardBody } from "@coreui/react-pro"
import { useNavigate } from "react-router-dom"
import { AppAside, AppContent, AppSidebar, AppFooter, AppHeader } from "../components/index"
import useLoginStore from "src/views/login/store/LoginStore"

import AppToast from "src/components/AppToast"

const DefaultLayout = () => {
  const navigate = useNavigate()
  const { login } = useLoginStore()
  const [menus, setMenus] = useState([])

  useEffect(() => {
    console.log("login 정보 : ", login)
    if (login === null) {
      navigate("/admin_login")
      return
    }
    //login ? setMenus(login.menu_info.map((item) => item.menu_url)) : navigate("/admin_login")

    const menu_info = [
      { menu_url: "/404", use_yn: "Y", menu_desc: "not found ", menu_id: 1 },
      { menu_url: "/500", use_yn: "Y", menu_desc: "server error", menu_id: 2 },
      { menu_url: "/dashboard", use_yn: "Y", menu_desc: "대시보드", menu_id: 3 },
      { menu_url: "/base/notice", use_yn: "Y", menu_desc: "공지사항", menu_id: 13 },
      { menu_url: "/base/faq", use_yn: "Y", menu_desc: "FAQ", menu_id: 14 },
      { menu_url: "/svc/svcList", use_yn: "Y", menu_desc: "보안성심의 현황/진행 현황", menu_id: 19 },
      { menu_url: "/system/statistics", use_yn: "Y", menu_desc: "보안성심의 현황/통계 현황", menu_id: 35 },
      { menu_url: "/condition", use_yn: "Y", menu_desc: "사용자관리", menu_id: 36 },
      { menu_url: "/admin/manage", use_yn: "Y", menu_desc: "어드민 관리", menu_id: 37 },
      { menu_url: "/member/manage", use_yn: "Y", menu_desc: "유저관리", menu_id: 38 },
      { menu_url: "/inst/manage", use_yn: "Y", menu_desc: "기관 관리", menu_id: 39 },
      { menu_url: "/pay/hist", use_yn: "Y", menu_desc: "기관 관리", menu_id: 40 },
      { menu_url: "/service", use_yn: "Y", menu_desc: "서비스 관리", menu_id: 41 },
      { menu_url: "/service/manage", use_yn: "Y", menu_desc: "서비스 관리", menu_id: 42 },
      { menu_url: "/voca/manage", use_yn: "Y", menu_desc: "단어 목록", menu_id: 43 },
      { menu_url: "/sent/manage", use_yn: "Y", menu_desc: "예문 목록", menu_id: 43 },
      { menu_url: "/quz/manage", use_yn: "Y", menu_desc: "퀴즈 목록", menu_id: 43 },
      { menu_url: "/lecture", use_yn: "Y", menu_desc: "수강 관리", menu_id: 44 },
      { menu_url: "/lecture/manage", use_yn: "Y", menu_desc: "수강 목록", menu_id: 44 },
      { menu_url: "/marketing", use_yn: "Y", menu_desc: "마케팅 관리", menu_id: 45 },
      { menu_url: "/base/marketing", use_yn: "Y", menu_desc: "이메일 목록", menu_id: 45 }
    ]

    setMenus(menu_info.map((item) => item.menu_url))

    // navigate("/dashboard")
  }, [login])

  return (
    <div style={{ minWidth: "1200px" }}>
      {/*sys_auth === "S" ? <AppSidebar menus={menus} /> : null*/}
      {window.name === "popup" ? "" : <AppSidebar menus={menus} />}
      <div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
        {window.name === "popup" ? "" : <AppHeader />}
        <div className="body flex-grow-1 px-2">
          <CCard>
            <CCardBody style={{ padding: "10px", border: "1px solid #ccc" /*, backgroundColor: "rgb(249 253 255)"*/ }}>
              <CRow xs={{ cols: 0 }} sm={{ cols: 0 }} md={{ cols: 0 }}>
                <AppToast />
                <AppContent />
              </CRow>
            </CCardBody>
          </CCard>
        </div>
        <AppFooter />
      </div>
      <AppAside />
    </div>
  )
}

export default DefaultLayout
