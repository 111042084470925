import React, { useState, useRef, useEffect } from "react"
import { useMitt } from "react-mitt"
import { CToast, CToaster, CAlert } from "@coreui/react-pro"
import CIcon from "@coreui/icons-react"
import { cilCheckCircle, cilWarning, cilBellExclamation } from "@coreui/icons"

const AppToast = () => {
  const [toast, addToast] = useState(0)
  const toaster = useRef()
  const { emitter } = useMitt()

  useEffect(() => {
    emitter.on("alert", (event) => sendToast(event))

    return () => {
      emitter.off("alert", (event) => sendToast(event))
    }
  }, [])

  const sendToast = async (event) => {
    console.log(event.message)

    if (event.type === 200) {
      const sucessToast = (
        <CToast className="d-inline">
          <CAlert color="success" className="d-flex align-items-center m-0">
            <CIcon icon={cilCheckCircle} className="flex-shrink-0 me-2" width={24} height={24} />
            <div className="flex-grow-1">{event.message}</div>
          </CAlert>
        </CToast>
      )
      addToast(sucessToast)
    }

    if (event.type === 300) {
      const sucessToast = (
        <CToast className="d-inline">
          <CAlert color="warning" className="d-flex align-items-center m-0">
            <CIcon icon={cilBellExclamation} className="flex-shrink-0 me-2" width={24} height={24} />
            <div className="flex-grow-1">{event.message}</div>
          </CAlert>
        </CToast>
      )
      addToast(sucessToast)
    }

    if (event.type === 500) {
      const failToast = (
        <CToast className="d-inline">
          <CAlert color="danger" className="d-flex align-items-center m-0">
            <CIcon icon={cilWarning} className="flex-shrink-0 me-2" width={24} height={24} />
            <div className="flex-grow-1">{event.message}</div>
          </CAlert>
        </CToast>
      )

      addToast(failToast)
    }
  }

  return (
    <>
      <CToaster className="p-3" placement="top-center" push={toast} ref={toaster} />
    </>
  )
}

export default AppToast
