import React from "react"
import { CModalBody } from "@coreui/react-pro"

import MemStdVocaPage from "src/views/member/page/MemStdVocaPage"

const BookedVocaModal = ({ modalData }) => {
  return (
    <>
      <CModalBody className="p-1">
        <MemStdVocaPage />
      </CModalBody>
    </>
  )
}

export default BookedVocaModal
