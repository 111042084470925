import React, { useCallback, useState, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import {
  CForm,
  CButton,
  CCard,
  CCardBody,
  CTable,
  CTableBody,
  CTableRow,
  CTableDataCell,
  CFormSelect,
  CFormTextarea,
  CFormInput,
  CLoadingButton,
  CImage,
  CModalBody
} from "@coreui/react-pro"
//json
import sysCodeInfo from "src/assets/system/system.info.json"

//lib
import moment from "moment"
import "moment/locale/ko"
import { useForm } from "react-hook-form"
import { actionType } from "src/common/grid/GridUtils"
import axios from "axios"

//api
import { useImageUploadUrl } from "src/common/api/CommonApi"

//store
import useGlobalStore from "src/GlobalStore"
import useVocaCatStore from "src/views/vocaCat/store/VocaCatStore"
import useServiceStore from "src/views/svcInfo/store/ServiceStore"
import useModalStore from "../store/ModalStore"
import useLoginStore from "src/views/login/store/LoginStore"
//service
import { useVocaCatOne } from "src/views/vocaCat/api/VocaCatApi"
import useVocaCatService from "src/views/vocaCat/hook/useVocaCatService"
import useCloudService from "src/common/hook/cloudflare/useCloudService"
//componets
import AppLoading from "src/components/AppLoading"
import AppBadge from "src/components/AppBadge"

const defaultImageUrl = "https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg"

const RoundedImg = ({ url = defaultImageUrl, id, mutate, name }) => {
  const imgUrl = url.includes(null) ? defaultImageUrl : url
  const { vocaCat } = useVocaCatStore()
  const { login } = useLoginStore()

  const { deleteCloudImg } = useCloudService()
  const { deleteCategoryImg } = useVocaCatService()

  const deleteImg = async () => {
    const ok = window.confirm("해당 이미지를 삭제하시겠습니까?")

    if (!ok) return

    await deleteCloudImg([{ id }])
    await deleteCategoryImg({
      img_column: name,
      cat_id: vocaCat.cat_id,
      upd_mem_id: login.mem_id
    })
    await mutate()
  }

  return (
    <div className="position-relative">
      <CImage className="rounded" src={imgUrl} width={100} height={100} />
      {id && <AppBadge type="delete" trigger={deleteImg} />}
    </div>
  )
}

const withBtn = (Component) => {
  return ({ register, isReadyOnly, name, onImgChange, label, ...rest }) => {
    return (
      <div className="d-flex flex-column">
        <Component {...rest} name={name} />
        <label className="d-flex justify-content-center" htmlFor={name}>
          <div
            className={`custom-file-upload text-center ${isReadyOnly && "bg-secondary"}`}
            style={{ fontSize: "0.9em", width: "100px" }}
          >
            {label}
          </div>
        </label>
        <input
          {...register(name, {
            onChange: (e) => onImgChange(e)
          })}
          disabled={isReadyOnly}
          type="file"
          accept="image/jpeg, image/gif, image/png, image/webp"
          style={{ display: "none" }}
          id={name}
          name={name}
        />
      </div>
    )
  }
}
const RoundedImgWithBtn = withBtn(RoundedImg)

const VocaCatDetailModal = ({ callDataApi, modalData }) => {
  const { siteLanguage } = useGlobalStore()
  const { serviceInfo } = useServiceStore()
  const { vocaCat, setVocaCat } = useVocaCatStore()
  const { setCloseModal, modalAction, setModalAction } = useModalStore()

  const ReadOnly = actionType.SELECT === modalAction ? true : false

  const { data, isLoading, mutate } = useVocaCatOne(modalAction, vocaCat?.cat_id)

  const { login } = useLoginStore()

  const [thumImg, setThumImg] = useState(null)
  const [sucsImg, setSucsImg] = useState(null)
  const [failImg, setFailImg] = useState(null)

  const createUrl = (imgId) => `https://imagedelivery.net/XJ0dKkCgMOZQs4Ecum2vlw/${imgId}/mobile`

  const images = [
    {
      name: "cat_tumb_img",
      label: "썸네일 이미지",
      setImg: setThumImg,
      serverUrl: createUrl(data?.cat_tumb_img),
      id: data.cat_tumb_img,
      imgUrl: thumImg
    },
    {
      name: "cat_sucs_img",
      label: "성공 이미지",
      setImg: setSucsImg,
      serverUrl: createUrl(data?.cat_sucs_img),
      id: data.cat_sucs_img,
      imgUrl: sucsImg
    },
    {
      name: "cat_fail_img",
      label: "실패 이미지",
      setImg: setFailImg,
      serverUrl: createUrl(data?.cat_fail_img),
      id: data?.cat_fail_img,
      imgUrl: failImg
    }
  ]

  const { insertVocaCat, updateVocaCat, deleteCategoryImg } = useVocaCatService()
  const { trigger: reqUploadImageUrl } = useImageUploadUrl()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue
  } = useForm({ mode: "onSubmit" })

  const registerOptions = {
    cat_nm: { required: "카테고리명은 필수값입니다." },
    cat_ord: { required: "카테고리 순번은 필수값입니다." }
  }

  const settingCatOrd = async () => {
    const { res_data } = await callDataApi()
    modalAction === "insert" && setValue("cat_ord", res_data?.length + 1)
  }

  useEffect(() => {
    settingCatOrd()
  }, [modalAction])

  const uploadImgeFile = async (submitData) => {
    for (const image of images) {
      const formData = new FormData()
      if (image.imgUrl) {
        const {
          data: {
            res_data: { result }
          }
        } = await reqUploadImageUrl()
        const uploadUrl = result.uploadURL
        if (!submitData[image.name][0]) {
          window.alert("파일이 객체가 들어오지 않았습니다. 다시 시도해주세요")
          return
        }
        formData.append("file", submitData[image.name][0])

        //upload image
        const response = await axios.post(uploadUrl, formData)
        console.log(`after ${image.name} upload image: `, response)
        const imageId = result.id
        submitData[image.name] = imageId
      } else {
        submitData[image?.name] = data[image.name]
      }
    }
  }

  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  const onSubmit = useCallback(
    async (submitData, event) => {
      console.log("입력한 데이터 확인", submitData)

      setIsSubmitLoading(true)

      await uploadImgeFile(submitData)

      console.log("check submitData: ", submitData)

      if (modalAction === actionType.UPDATE) {
        submitData["svc_id"] = serviceInfo?.svc_id
        submitData["cat_id"] = vocaCat?.cat_id
        submitData["upd_mem_id"] = login.mem_id
        await updateVocaCat(submitData)
      }

      if (modalAction === actionType.INSERT) {
        submitData["svc_id"] = serviceInfo?.svc_id
        submitData["reg_mem_id"] = login.mem_id
        submitData["upd_mem_id"] = login.mem_id
        const insertId = await insertVocaCat(submitData)
        setVocaCat({ cat_id: insertId })
      }
      //data를 가져오는 방식이 이게 맞는지 파라미터 없이 mutate 하면 즉시 가져오고 데이터 넣어주면 캐싱하고 가져오고...
      if (callDataApi) await callDataApi()

      await mutate()
      setIsSubmitLoading(false)
      setModalAction("select")
      setCloseModal()
    },
    [vocaCat, modalAction, data, images]
  )

  const onError = useCallback(async (e) => {
    console.log(e)
  }, [])

  const onImgChange = (event) => {
    const file = event.currentTarget.files[0]
    if (!file) {
      return
    }

    const reader = new FileReader()

    images.forEach((item, index) => {
      if (event.target.name === item.name) {
        console.log("no setting?")
        reader.onload = () => {
          item.setImg(reader.result)
        }

        reader.readAsDataURL(file)
      }
    })
  }

  const clickInfoUpdate = useCallback(
    (e) => {
      reset()
      setModalAction(actionType.UPDATE)
    },
    [data]
  )

  const clickInfoReset = useCallback(
    (e) => {
      e.preventDefault()
      reset()
      images.map((item) => item.setImg(null))
      setModalAction(actionType.SELECT)
    },
    [data]
  )

  useEffect(() => {
    if (window.opener !== null) {
      document.body.classList.add("popup-no-scrollbars")
    }
  }, [])

  //e:action event =================================================

  if (isLoading) {
    return <AppLoading heightSize={388} />
  }

  return (
    <>
      <CModalBody className="p-1" style={{ width: "1115px" }}>
        <CCard className="shadow-none">
          <CCardBody className="p-0">
            <CForm onSubmit={handleSubmit(onSubmit, onError)}>
              <CTable color="light" bordered small>
                <CTableBody>
                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray" style={{ width: "130px" }}>
                      카테고리명 *{/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf-xxl">
                      <CFormInput
                        {...register("cat_nm", registerOptions.cat_nm)}
                        defaultValue={data?.cat_nm}
                        size="sm"
                        className="lh-15"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      />
                      {errors.cat_nm && <span>{errors.cat_nm.message}</span>}
                    </CTableDataCell>

                    <CTableDataCell className="sf-label" color="gray" style={{ width: "130px" }}>
                      카테고리 순번{/* <br />카테고리 순서 컬럼 ord 로 변경될것임 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormInput
                        {...register("cat_ord", registerOptions.cat_ord, { valueAsNumber: true })}
                        defaultValue={data?.cat_ord}
                        type="number"
                        size="sm"
                        className="lh-15"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      />
                      {errors.cat_ord && <span>{errors.cat_ord.message}</span>}
                    </CTableDataCell>
                  </CTableRow>

                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray" style={{ width: "130px" }}>
                      카테고리 타입 {/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormSelect
                        {...register("cat_typ")}
                        key={uuidv4()}
                        defaultValue={data?.cat_typ}
                        className="form-select lh-18"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      >
                        {sysCodeInfo
                          .filter((code) => code.cod_mgr_no === "CAT0001")
                          .map((item) => (
                            <option key={item.cod_id} value={item.cod_cd}>
                              {item.cod_dsc}
                            </option>
                          ))}
                      </CFormSelect>
                    </CTableDataCell>

                    <CTableDataCell className="sf-label" color="gray" style={{ width: "130px" }}>
                      보상 {/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormInput
                        {...register("cat_rew")}
                        defaultValue={data?.cat_rew}
                        size="sm"
                        className="lh-15"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      />
                    </CTableDataCell>
                  </CTableRow>

                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray" style={{ width: "130px" }}>
                      카테고리 설명 {/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormTextarea
                        {...register("cat_dsc")}
                        defaultValue={data?.cat_dsc}
                        rows={1}
                        size="sm"
                        className="lh-15 ps-2"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      />
                    </CTableDataCell>

                    <CTableDataCell className="sf-label" color="gray" style={{ width: "130px" }}>
                      사용여부
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormSelect
                        {...register("use_yn")}
                        key={uuidv4()}
                        defaultValue={data?.use_yn}
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                        className="form-select lh-18"
                      >
                        <option value="Y">Y</option>
                        <option value="N">N</option>
                        <option value="D">D</option>
                      </CFormSelect>
                    </CTableDataCell>
                  </CTableRow>

                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray" style={{ width: "130px" }}>
                      내용
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormTextarea
                        {...register("etc")}
                        defaultValue={data?.etc}
                        rows={6}
                        size="sm"
                        className="lh-15 ps-2"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      />
                    </CTableDataCell>
                    <CTableDataCell className="sf-label" color="gray" style={{ width: "130px" }}>
                      이미지
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <div className="d-flex justify-content-around">
                        {images.map((item, index) => (
                          <RoundedImgWithBtn
                            key={index}
                            mutate={mutate}
                            register={register}
                            id={item.id}
                            isReadyOnly={ReadOnly}
                            name={item.name}
                            label={item.label}
                            url={item.imgUrl ? item.imgUrl : item.serverUrl}
                            onImgChange={onImgChange}
                          />
                        ))}
                      </div>
                    </CTableDataCell>
                  </CTableRow>

                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray">
                      등록일
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <span className="p-2">{data?.reg_dtm && moment.utc(data?.reg_dtm).locale("ko").format("YYYY-MM-DD")}</span>
                    </CTableDataCell>

                    <CTableDataCell className="sf-label" color="gray">
                      수정일
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <span className="p-2">{data?.upd_dtm && moment.utc(data?.upd_dtm).locale("ko").format("YYYY-MM-DD")}</span>
                    </CTableDataCell>
                  </CTableRow>
                </CTableBody>
              </CTable>
              <div className="d-flex justify-content-center">
                {
                  {
                    insert: (
                      <span>
                        <CLoadingButton
                          loading={isSubmitLoading}
                          onClick={handleSubmit(onSubmit)}
                          color="primary"
                          className="px-4 mx-1"
                          size="sm"
                        >
                          {siteLanguage.btnName.save}
                        </CLoadingButton>
                        <CButton onClick={() => setCloseModal()} className="px-4" size="sm" color="dark">
                          닫기
                        </CButton>
                      </span>
                    ),
                    select: (
                      <span>
                        {
                          <CLoadingButton
                            loading={isSubmitLoading}
                            onClick={() => clickInfoUpdate()}
                            color="primary"
                            className="px-4 mx-1"
                            size="sm"
                          >
                            {siteLanguage.btnName.update}
                          </CLoadingButton>
                        }
                        <CButton onClick={() => setCloseModal()} className="px-4" size="sm" color="dark">
                          닫기
                        </CButton>
                      </span>
                    ),
                    update: (
                      <span>
                        <CLoadingButton
                          loading={isSubmitLoading}
                          onClick={handleSubmit(onSubmit)}
                          color="primary"
                          size="sm"
                          className="px-4 mx-1"
                        >
                          {siteLanguage.btnName.save}
                        </CLoadingButton>
                        <CButton onClick={(e) => clickInfoReset(e, "select", true)} color="dark" className="px-4" size="sm">
                          {siteLanguage.btnName.cancel}
                        </CButton>
                      </span>
                    )
                  }[modalAction]
                }
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CModalBody>
    </>
  )
}

export default VocaCatDetailModal
