import React, { useCallback, useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import {
  CForm,
  CButton,
  CCard,
  CCardBody,
  CTable,
  CTableBody,
  CTableRow,
  CTableDataCell,
  CModalBody,
  CFormTextarea
} from "@coreui/react-pro"

//lib
import moment from "moment"
import "moment/locale/ko"
import { useForm } from "react-hook-form"
import { actionType } from "src/common/grid/GridUtils"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { ko } from "date-fns/esm/locale"

//store
import useGlobalStore from "src/GlobalStore"
import useLoginStore from "src/views/login/store/LoginStore"
import useClsStore from "src/views/cls/store/ClsStore"
import useModalStore from "../store/ModalStore"

//service
import { useClsDtlDetail } from "src/views/cls/api/ClsApi"
import useClsService from "src/views/cls/hooks/useClsService"
import AppLoading from "src/components/AppLoading"

const ClsDtlModal = ({ modalData, callDataApi: listMutate }) => {
  //utils
  const { siteLanguage } = useGlobalStore()
  //store
  const { action, setAction, clsDtl, setClsDtl } = useClsStore()
  const { setCloseModal } = useModalStore()
  const { login } = useLoginStore()

  //action
  const { updateClsDtl } = useClsService()
  //data
  const { data, isLoading, mutate } = useClsDtlDetail(action, clsDtl?.cls_dtl_id)

  const [str_dt, setStr] = useState(new Date())
  const [end_dt, setEnd] = useState(new Date())

  //service

  const ReadOnly = actionType.SELECT === action ? true : false

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue
  } = useForm({ mode: "onSubmit" })

  useEffect(() => {
    data?.cls_dtl_exp_str_dt && setStr(moment.utc(data?.cls_dtl_exp_str_dt).locale("ko").format("YYYY-MM-DD"))
    data?.cls_dtl_exp_end_dt && setEnd(moment.utc(data?.cls_dtl_exp_end_dt).locale("ko").format("YYYY-MM-DD"))
  }, [data])

  const onSubmit = useCallback(
    async (submitData, event) => {
      console.log(submitData)
      console.log(moment.utc(str_dt).locale("ko").format("YYYY-MM-DD"), moment.utc(end_dt).locale("ko").format("YYYY-MM-DD"))

      submitData["cls_dtl_exp_str_dt"] = moment.utc(str_dt).locale("ko").format("YYYY-MM-DD")
      submitData["cls_dtl_exp_end_dt"] = moment.utc(end_dt).locale("ko").format("YYYY-MM-DD")
      submitData["upd_mem_id"] = login.mem_id
      submitData["cls_dtl_id"] = data?.cls_dtl_id

      if (action === actionType.UPDATE) {
        await updateClsDtl(submitData)
      }

      await mutate()

      await listMutate()
      setAction("select")
      setCloseModal()
    },
    [action, login, str_dt, end_dt]
  )

  const changeStrDate = useCallback((date) => {
    setStr(date)
  }, [])

  const changeEndDate = useCallback((date) => {
    setEnd(date)
  }, [])

  const onError = useCallback(async (e) => {
    console.log(e)
  }, [])

  const clickInfoUpdate = useCallback((e) => {
    e.preventDefault()
    reset()
    setAction(actionType.UPDATE)
  }, [])

  const clickInfoReset = useCallback((e) => {
    e.preventDefault()
    reset()
    setAction(actionType.SELECT)
  }, [])

  useEffect(() => {
    if (window.opener !== null) {
      document.body.classList.add("popup-no-scrollbars")
    }
  }, [])

  //e:action event =================================================

  if (isLoading) {
    return <AppLoading heightSize={656} />
  }

  return (
    <>
      <CModalBody className="p-1" style={{ width: "1115px" }}>
        <CCard className="shadow-none mb-4">
          <CCardBody className="p-0">
            <CForm onSubmit={handleSubmit(onSubmit, onError)}>
              <CTable color="light" bordered small>
                <CTableBody>
                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray">
                      서비스명
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <span className="p-2">{data?.svc_nm}</span>
                    </CTableDataCell>
                    <CTableDataCell className="sf-label" color="gray">
                      유효기간
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <span className="p-2">{data?.cls_dtl_exp}개월</span>
                    </CTableDataCell>
                  </CTableRow>

                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray">
                      결제가격
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <span className="p-2">{data?.cls_dtl_pay_prc}</span>
                    </CTableDataCell>
                    <CTableDataCell className="sf-label" color="gray">
                      결제상태
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <span className="p-2">{data?.cls_dtl_pay_stt}</span>
                    </CTableDataCell>
                  </CTableRow>

                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray">
                      시작일
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <DatePicker
                        className="monthCalendarInTable"
                        showIcon
                        dateFormat="yyyy-MM-dd"
                        selected={new Date(str_dt)}
                        onChange={(str_dt) => changeStrDate(str_dt)}
                        locale={ko}
                        placeholderText={"수강시작일 선택"}
                        disabled={ReadOnly}
                      />
                    </CTableDataCell>
                    <CTableDataCell className="sf-label" color="gray">
                      종류일
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <DatePicker
                        className="monthCalendarInTable"
                        showIcon
                        dateFormat="yyyy-MM-dd"
                        selected={new Date(end_dt)}
                        onChange={(end_dt) => changeEndDate(end_dt)}
                        locale={ko}
                        placeholderText={"수강종류일 선택"}
                        disabled={ReadOnly}
                      />
                    </CTableDataCell>
                  </CTableRow>

                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray">
                      메모
                    </CTableDataCell>
                    <CTableDataCell colSpan={3} className="sf">
                      <CFormTextarea
                        {...register("cls_dtl_note")}
                        defaultValue={data?.cls_dtl_note}
                        rows={8}
                        size="sm"
                        className="lh-15"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      />
                    </CTableDataCell>
                  </CTableRow>

                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray">
                      등록일
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <span className="p-2">{data?.reg_dtm && moment.utc(data?.reg_dtm).locale("ko").format("YYYY-MM-DD")}</span>
                    </CTableDataCell>

                    <CTableDataCell className="sf-label ms-1" color="gray">
                      수정일
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <span className="p-2">{data?.upd_dtm && moment.utc(data?.upd_dtm).locale("ko").format("YYYY-MM-DD")}</span>
                    </CTableDataCell>
                  </CTableRow>
                </CTableBody>
              </CTable>
              <div className="d-flex justify-content-center">
                {
                  {
                    insert: (
                      <span>
                        <CButton onClick={handleSubmit(onSubmit)} color="primary" className="px-4 mx-1" size="sm">
                          {siteLanguage.btnName.save}
                        </CButton>
                        <CButton onClick={() => setCloseModal()} className="px-4" size="sm" color="dark">
                          닫기
                        </CButton>
                      </span>
                    ),
                    select: (
                      <span>
                        <CButton onClick={(e) => clickInfoUpdate(e)} color="primary" className="px-4 mx-1" size="sm">
                          {siteLanguage.btnName.update}
                        </CButton>
                        <CButton onClick={() => setCloseModal()} className="px-4" size="sm" color="dark">
                          닫기
                        </CButton>
                      </span>
                    ),
                    update: (
                      <span>
                        <CButton onClick={handleSubmit(onSubmit)} color="primary" size="sm" className="px-4 mx-1">
                          {siteLanguage.btnName.save}
                        </CButton>
                        <CButton onClick={(e) => clickInfoReset(e, "select", true)} color="dark" className="px-4" size="sm">
                          {siteLanguage.btnName.cancel}
                        </CButton>
                      </span>
                    )
                  }[action]
                }
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CModalBody>
    </>
  )
}

export default ClsDtlModal
