import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { persist, createJSONStorage } from "zustand/middleware"

const ClsStore = (set) => ({
  action: "select",
  setAction: (_state) => set((state) => ({ action: _state })),

  clsInfo: {},
  setClsInfo: (_clsInfo) => set((state) => ({ clsInfo: _clsInfo })),

  clsDtl: {},
  setClsDtl: (_clsDtl) => set((state) => ({ clsDtl: _clsDtl }))
})

const persistConfig = {
  name: "useClsStore",
  storage: createJSONStorage(() => sessionStorage)
}

const useClsStore = create(devtools(persist(ClsStore, persistConfig), { name: persistConfig.name }))
export default useClsStore
