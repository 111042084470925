import React from "react"
import CIcon from "@coreui/icons-react"
import { cilBarChart, cilClipboard, cilDrop, cilList, cilLayers, cilListRich } from "@coreui/icons"
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react-pro"
import dashboardStore from "src/GlobalStore"

const useNavService = () => {
  const { siteLanguage } = dashboardStore()

  const navService = [
    {
      component: CNavItem,
      name: "서비스 관리",
      to: "/service/manage",
      icon: <CIcon icon={cilListRich} customClassName="nav-icon" />,
      state: { path: "서비스 관리" }
    }
  ]

  return {
    navService
  }
}

export default useNavService
