import React, { useState, useEffect, useCallback } from "react"
import dashboardStore from "src/GlobalStore"

import { CSidebar, CSidebarBrand, CSidebarNav } from "@coreui/react-pro"

import { AppSidebarNav } from "./AppSidebarNav"

import logo_icon from "src/assets/images/teamsoft_logo.png"
import logo from "src/assets/images/teamsoft_fulllogo_wh.png"

import SimpleBar from "simplebar-react"
import "simplebar/dist/simplebar.min.css"

import useNavBoard from "src/components/navigation/useNavBoard"
import useNavCondition from "src/components/navigation/useNavCondition"
import useNavService from "./navigation/useNavService"
import useNavVoca from "./navigation/useNavVoca"
import useNavInst from "./navigation/useNavInst"
import useNavLecture from "./navigation/useNavLecture"
import useNavFAQ from "./navigation/useNavFAQ"
import usePromotion from "./navigation/usePromotion"

const AppSidebar = ({ menus }) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const { sidebarShow, setSidebarShow, sidebarUnfoldable, setSidebarUnfoldable } = dashboardStore()
  const { navBoard } = useNavBoard()
  const { navCondition } = useNavCondition()
  const { navService } = useNavService()
  const { navVoca } = useNavVoca()
  const { navInst } = useNavInst()
  const { navLecture } = useNavLecture()
  const { navFaq } = useNavFAQ()
  const { navPromotion } = usePromotion()

  const handleResize = useCallback(() => {
    setWindowHeight(window.innerHeight)
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <CSidebar
      position="fixed"
      unfoldable={sidebarUnfoldable}
      visible={sidebarShow}
      //style={{ backgroundColor: "#2e3540" }}
      style={{ backgroundColor: "rgb(15 29 46)" }}
      /* 
      overlaid={false}
      placement='start'
      narrow={false}
      onHide={() => {
        setSidebarShow(true)
      }}*/
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <img src={logo} alt="teamsoft" className="sidebar-brand-full" style={{ width: "110px" }} />
        <img src={logo_icon} alt="teamsoft" className="sidebar-brand-narrow" style={{ width: "20px" }} />
        {/* <CIcon className="sidebar-brand-full" icon={logoHanaWide} height={35} />
        <CIcon className="sidebar-brand-narrow" icon={logoHanaIcon} height={35} /> */}
      </CSidebarBrand>

      <SimpleBar style={{ height: `${windowHeight - 97}px` }}>
        <CSidebarNav>
          <AppSidebarNav items={navBoard} menus={menus} />
        </CSidebarNav>
        <CSidebarNav>
          <AppSidebarNav items={navFaq} menus={menus} />
        </CSidebarNav>
        <CSidebarNav>
          <AppSidebarNav items={navCondition} menus={menus} />
        </CSidebarNav>
        <CSidebarNav>
          <AppSidebarNav items={navService} menus={menus} />
        </CSidebarNav>
        <CSidebarNav>
          <AppSidebarNav items={navVoca} menus={menus} />
        </CSidebarNav>
        <CSidebarNav>
          <AppSidebarNav items={navInst} menus={menus} />
        </CSidebarNav>
        <CSidebarNav>
          <AppSidebarNav items={navLecture} menus={menus} />
        </CSidebarNav>
        <CSidebarNav>
          <AppSidebarNav items={navPromotion} menus={menus} />
        </CSidebarNav>
      </SimpleBar>

      {/*
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => setSidebarUnfoldable()}
      />
      */}
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
