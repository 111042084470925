import {
  useVocaSentInfoInsert,
  useVocaSentInfoListbyIds,
  useVocaSentInfoUpdate,
  useVocaSentInfoDelete,
  useVocaSentFileInfoDelete,
  useVocaSentInfoOrder
} from "../api/VocaSentInfoApi"
import { useMitt } from "react-mitt"
import SITE_MESSAGE from "src/assets/language/site.message"

const useVocaSentService = () => {
  const { emitter } = useMitt()
  const { trigger: requestInsert, isMutating: isInserting } = useVocaSentInfoInsert()
  const { trigger: requestUpdate, isMutating: isUpdateing } = useVocaSentInfoUpdate()
  const { trigger: requestOrder } = useVocaSentInfoOrder()
  const { trigger: requestDelete, isMutating: isDeleteing } = useVocaSentInfoDelete()
  const { trigger: requestFileDelete } = useVocaSentFileInfoDelete()

  const insertVocaSent = async (data) => {
    try {
      const response = await requestInsert(data)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.insert_success, type: 200 })
      }
      //등록한 id값 나옴
      return response?.data?.res_data
    } catch (error) {
      console.error("insertService error:", error)
      emitter.emit("alert", { message: SITE_MESSAGE.insert_fail, type: 500 })
    }
  }

  const updateVocaSent = async (data) => {
    try {
      const response = await requestUpdate(data)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.update_success, type: 200 })
      }
    } catch (error) {
      console.error("updateService error:", error)
      emitter.emit("alert", { message: SITE_MESSAGE.update_fail, type: 500 })
    }
  }

  const deleteVocaSent = async (gridRef) => {
    try {
      const selectedIds = gridRef.current.api.getSelectedRows()

      if (selectedIds.length === 0) {
        window.alert("삭제할 데이터를 선택해주세요")
        return
      }

      const idList = selectedIds.map((item) => item.sent_id)
      console.log("check id list: ", idList)
      const response = await requestDelete(idList)

      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.delete_success, type: 200 })
      }
    } catch (error) {
      console.error("deleteService error:", error)
      emitter.emit("alert", { message: SITE_MESSAGE.delete_fail, type: 500 })
    }
  }

  const deleteVocaSentFileInfo = async (data) => {
    try {
      const response = await requestFileDelete(data)
      if (response.status === 200) {
        emitter.emit("alert", { message: SITE_MESSAGE.delete_success, type: 200 })
      }
      return response
    } catch (error) {
      console.error("deleteVocaSentFileInfo fail...", error)
      emitter.emit("alert", { message: SITE_MESSAGE.delete_fail, type: 500 })
    }
  }

  const reOrderSentInfo = async (ref) => {
    try {
      const list = ref.current.api.getModel().rowsToDisplay.map((item) => item.data)
      const order = list.map((item, index) => ({ sent_id: item.sent_id, sent_ord: index + 1 }))
      await requestOrder(order)
    } catch (error) {
      console.error("reOrderSentInfo fail...", error)
      emitter.emit("alert", { message: "정렬에 실패했습니다, 관리자에게 문의해주세요.", type: 500 })
    }
  }

  return {
    insertVocaSent,
    updateVocaSent,
    deleteVocaSent,
    deleteVocaSentFileInfo,
    reOrderSentInfo,
    isInserting,
    isUpdateing,
    isDeleteing
  }
}

export default useVocaSentService
