import axiosSpring from "src/common/AxiosSpring"
import useSWR from "swr"
import useSWRMutation from "swr/mutation"

export const useVocaSentInfoList = (useYn, vocaId) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    vocaId ? `/admin/voca/sent/list?use_yn=${useYn}&voca_id=${vocaId}` : null,
    fetcher
  )
  return { data: data?.res_data || [], error, isLoading, mutate, isValidating }
}

export const useSentInfoList = (useYn) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(`/admin/voca/sent/all/list?use_yn=${useYn}`, fetcher)
  return { data: data?.res_data || [], error, isLoading, mutate, isValidating }
}

export const useVocaSentInfoListByCatId = (useYn, catId) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    catId ? `/admin/voca/cat/sent/list?use_yn=${useYn}&cat_id=${catId}` : null,
    fetcher
  )
  return { data: data?.res_data || [], error, isLoading, mutate, isValidating }
}

//상세
export const useVocaSentInfoOne = (action, sentId) => {
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    action !== "insert" && sentId ? `/admin/voca/sent/detail?sent_id=${sentId}` : null,
    fetcher
  )
  return { data: data?.res_data || {}, error, isLoading, mutate, isValidating }
}

//등록
export const useVocaSentInfoInsert = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/sent/insert`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//업데이트
export const useVocaSentInfoUpdate = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/sent/update`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

export const useVocaSentInfoOrder = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/sent/order/list`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//삭제
export const useVocaSentInfoDelete = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/sent/delete`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

//삭제
export const useVocaSentFileInfoDelete = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/admin/voca/sent/delete/file`, fetcher)
  return { trigger, isMutating, data, error, reset }
}
