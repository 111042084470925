import React, { useCallback, Suspense, lazy } from "react"
import { CModal, CModalFooter, CModalHeader } from "@coreui/react-pro"
import CIcon from "@coreui/icons-react"
import { cilMediaStop } from "@coreui/icons"
import useModalStore from "./store/ModalStore"
import { actionType } from "src/common/grid/GridUtils"

import ServiceDetailModal from "./page/ServiceDetailModal"
import ErrorModal from "./page/ErrorModal"
import InsertAdminModal from "./page/InsertAdminModal"
import VocaSentDetailModal from "./page/VocaSentDetailModal"
import VocaGridModal from "./page/VocaGridModal"
import VocaCatDetailModal from "./page/VocaCatDetailModal"
import VocaOptDetailModal from "./page/VocaOptDetailModal"
import VocaQstDetailModal from "./page/VocaQstDetailModal"
import VocaQuzSentGridModal from "./page/VocaQuzSentGridModal"
import PasswordChangeModal from "./page/PasswordChangeModal"
import BookedVocaModal from "./page/BookedVocaModal"
import BookedSentModal from "./page/BokkedSentModal"
import MasterDetailGridModal from "./page/MasterDetailGridModal"
import LoginHistoryModal from "./page/LoginHistoryModal"
import ClsDtlModal from "./page/ClsDtlModal"

const Modal = () => {
  const {
    modalName,
    modalTitle,
    showModal,
    modalData,
    setShowModal,
    modalSize,
    modalTopPosition,
    dataRegister,
    clickToSet,
    callDataApi,
    modalStyle,
    navigate,
    reOrder,
    modalType,
    headerComponent
  } = useModalStore()
  const clickHideModal = useCallback((event) => {
    if (event.target.className === "btn btn-close") {
      setShowModal({
        modalName: "none",
        showModal: false,
        modalData: null,
        modalSize: "sm",
        modalTopPosition: "0px",
        modalAction: actionType.SELECT
      })
    }
  }, [])

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <CModal
          scrollable
          visible={showModal}
          // backdrop={true}
          backdrop="static"
          portal={false}
          // size={modalSize}
          //modal size xl 일때 width, maring 정해주기
          className={`flex modal-${modalSize}`}
          style={{ top: modalTopPosition }}
          onClick={(e) => clickHideModal(e)}
        >
          <CModalHeader className="py-2" style={{ background: "#3b3b3b12" }}>
            {modalTitle ? (
              <>
                <CIcon icon={cilMediaStop} size="sm" style={{ margin: "0px 4px 0px 0", "--ci-primary-color": "#333" }} />
                <strong>{modalTitle}</strong>
              </>
            ) : null}
          </CModalHeader>
          {
            {
              ErrorModal: <ErrorModal modalData={modalData} />,
              InsertAdminModal: <InsertAdminModal modalData={modalData} callDataApi={callDataApi} />,
              VocaSentDetailModal: <VocaSentDetailModal modalData={modalData} callDataApi={callDataApi} reOrder={reOrder} />,
              VocaGridModal: (
                <VocaGridModal
                  modalData={modalData}
                  dataRegister={dataRegister}
                  clickToSet={clickToSet}
                  callDataApi={callDataApi}
                  modalStyle={modalStyle}
                  navigate={navigate}
                  modalType={modalType}
                  headerComponent={headerComponent}
                />
              ),
              ServiceDetailModal: <ServiceDetailModal modalData={modalData} callDataApi={callDataApi} />,
              VocaCatDetailModal: <VocaCatDetailModal modalData={modalData} callDataApi={callDataApi} />,
              VocaOptDetailModal: <VocaOptDetailModal modalData={modalData} callDataApi={callDataApi} />,
              VocaQstDetailModal: <VocaQstDetailModal modalData={modalData} callDataApi={callDataApi} />,
              VocaQuzSentGridModal: <VocaQuzSentGridModal modalData={modalData} clickToSet={clickToSet} />,
              PasswordChangeModal: (
                <PasswordChangeModal modalData={modalData} dataRegister={dataRegister} callDataApi={callDataApi} />
              ),
              BookedVocaModal: <BookedVocaModal />,
              BookedSentModal: <BookedSentModal />,
              LoginHistoryModal: <LoginHistoryModal modalData={modalData} />,
              ClsDtlModal: <ClsDtlModal modalData={modalData} callDataApi={callDataApi} />,
              MasterDetailGridModal: (
                <MasterDetailGridModal modalData={modalData} callDataApi={callDataApi} clickToSet={clickToSet} />
              )
            }[modalName]
          }
          <CModalFooter className="p-0" style={{ height: "4px", border: "0" }}></CModalFooter>
        </CModal>
      </Suspense>
    </>
  )
}

export default Modal
