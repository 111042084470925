import { create } from "zustand"
import { devtools } from "zustand/middleware"

const ModalStore = (set, get) => ({
  modalName: "",
  modalTitle: "",
  showModal: false,
  modalData: null,
  modalSize: "lg",
  modalTopPosition: "20px",
  modalAction: "select",
  modalType: "",
  dataRegister: null,
  clickToSet: null,
  callDataApi: null,
  modalStyle: "",
  navigate: null,
  reOrder: null,
  headerComponent: null,

  setModalAction: (_action) => set((state) => ({ modalAction: _action })),

  setShowModal: (modalObject) =>
    set((state) => ({
      modalName: modalObject.modalName,
      modalTitle: modalObject.modalTitle,
      showModal: modalObject.showModal,
      modalData: modalObject.modalData,
      modalSize: modalObject.modalSize,
      modalTopPosition: modalObject.modalTopPosition,
      modalAction: modalObject.modalAction,
      modalType: modalObject.modalType,
      dataRegister: modalObject.dataRegister,
      clickToSet: modalObject.clickToSet,
      callDataApi: modalObject.callDataApi,
      modalStyle: modalObject.modalStyle,
      navigate: modalObject.navigate,
      reOrder: modalObject.reOrder,
      headerComponent: modalObject.headerComponent
    })),

  setCloseModal: () =>
    set((state) => ({
      modalName: "none",
      modalTitle: "",
      showModal: false,
      modalData: null,
      modalSize: "sm",
      modalTopPosition: "0px",
      modalAction: "select",
      modalType: "none",
      dataRegister: null,
      clickToSet: null,
      callDataApi: null,
      modalStyle: "",
      navigate: null,
      reOrder: null,
      headerComponent: null
    }))
})

const useModalStore = create(devtools(ModalStore, { name: "ModalStore" }))
export default useModalStore
