import React, { useState } from "react"
import CIcon from "@coreui/icons-react"
import { cilX } from "@coreui/icons"

const AppBadge = ({ type = "circle", trigger }) => {
  const [show, setShow] = useState(false)

  if (type === "circle") {
    return (
      <div
        className="position-absolute bg-danger rounded-circle"
        style={{ width: "12px", height: "12px", top: "-6px", right: "0px" }}
      ></div>
    )
  }

  return (
    <div
      className="position-absolute bg-danger rounded-circle"
      onClick={async () => trigger()}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      style={{ width: "15px", height: "15px", top: "-6px", right: "-4px", cursor: "pointer" }}
    >
      {show && (
        <CIcon
          className="position-absolute text-black"
          style={{ left: "1px", top: "1px", padding: "1.5px" }}
          icon={cilX}
          size="sm"
        />
      )}
    </div>
  )
}

export default AppBadge
