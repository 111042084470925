import React, { useCallback, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import {
  CForm,
  CButton,
  CCard,
  CCardBody,
  CTable,
  CTableBody,
  CTableRow,
  CTableDataCell,
  CFormSelect,
  CFormTextarea,
  CFormInput,
  CLoadingButton,
  CInputGroup,
  CInputGroupText,
  CModalBody,
  CHeader,
  CHeaderNav
} from "@coreui/react-pro"
import CIcon from "@coreui/icons-react"
import { cilMediaStop, cilMagnifyingGlass } from "@coreui/icons"

//lib
import moment from "moment"
import "moment/locale/ko"
import { useForm } from "react-hook-form"
import { actionType } from "src/common/grid/GridUtils"

//store
import useGlobalStore from "src/GlobalStore"
import useLoginStore from "src/views/login/store/LoginStore"
import useVocaQuzQstStore from "src/views/vocaQuz/store/VocaQuzQstStore"
import useModalStore from "src/views/modal/store/ModalStore"
import useVocaQuzStore from "src/views/vocaQuz/store/VocaQuzStore"
import useVocaCatStore from "src/views/vocaCat/store/VocaCatStore"

//service
import { useVocaQuzQstOne } from "src/views/vocaQuz/api/VocaQuzApi"
import { useVocaCatMapList } from "src/views/vocaCatMap/api/VocaCatMapApi"
import useVocaQuzService from "src/views/vocaQuz/hook/useVocaQuzService"

//componets
import AppLoading from "src/components/AppLoading"
import VocaGrid from "src/views/vocaQuz/page/VocaGrid"

const VocaQstDetailModal = ({ modalData, callDataApi }) => {
  const { siteLanguage } = useGlobalStore()
  const { login } = useLoginStore()
  const { action, setAction, vocaQuzQst, setVocaQuzQst } = useVocaQuzQstStore()
  const { vocaCat } = useVocaCatStore()
  const { vocaQuz } = useVocaQuzStore()
  const { setShowModal, setCloseModal } = useModalStore()

  const ReadOnly = actionType.SELECT === action ? true : false

  const { data, isLoading, mutate } = useVocaQuzQstOne(action, vocaQuzQst.qst_id)
  const readyOnlyOnW = vocaQuz.quz_typ === "W"

  const { insertVocaQuzQst, updateVocaQuzQst } = useVocaQuzService()

  useEffect(() => {
    setValue("qst_sco", 10)
    if (vocaQuz.quz_typ === "S" && action === "insert") setValue("qst_ord", 1)
    action === "insert" && setValue("qst_ord", modalData.length + 1)
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    getValue
  } = useForm({ mode: "onSubmit" })

  const registerOptions = {
    voca_tg: { required: "단어는 필수값입니다." },
    voca_typ: { required: "단어유형은 필수값입니다." }
  }

  const onSubmit = useCallback(
    async (submitData, event) => {
      console.log("입력한 데이터 확인", submitData)

      if (action === actionType.UPDATE) {
        submitData["qst_id"] = vocaQuzQst?.qst_id
        submitData["quz_id"] = vocaQuzQst.quz_id
        submitData["upd_mem_id"] = login.mem_id
        await updateVocaQuzQst(submitData)
      }

      if (action === actionType.INSERT) {
        submitData["reg_mem_id"] = login.mem_id
        submitData["upd_mem_id"] = login.mem_id
        submitData["quz_id"] = vocaQuz?.quz_id
        const insertId = await insertVocaQuzQst(submitData)
        setVocaQuzQst({ qst_id: insertId, quz_id: vocaQuz?.quz_id })
      }

      if (callDataApi) {
        await callDataApi()
      }

      reset(submitData)
      mutate(submitData)
      setAction("select")
      //page 리스트 화면 갱신하기.
      setCloseModal()
    },
    [vocaQuzQst, vocaQuz, action, data]
  )

  const onError = useCallback(async (e) => {
    console.log(e)
  }, [])

  const clickInfoUpdate = useCallback((e) => {
    e.preventDefault()
    reset()
    setAction(actionType.UPDATE)
  }, [])

  const clickInfoReset = useCallback((e) => {
    e.preventDefault()
    reset()
    setAction(actionType.SELECT)
  }, [])

  const clickToSet = (data) => {
    console.log(data)
    if (action !== "select") {
      setValue("voca_id", data.voca_id)
      setValue("qst_req", data.voca_mean)
      setValue("qst_ans", data.voca_tg)
    }
    //보여주는 데이터도 set 해주기
  }

  useEffect(() => {
    if (window.opener !== null) {
      document.body.classList.add("popup-no-scrollbars")
    }
  }, [])

  //e:action event =================================================

  if (isLoading) {
    return <AppLoading heightSize={299} />
  }

  //   className={`${window.opener !== null ? "header-content-popup" : "header-content"} ${auth ? "" : "p-2"}`}

  return (
    <>
      <CModalBody className="p-1">
        <CHeader className="header-content-popup justify-content-end">
          <CHeaderNav className="d-none d-md-flex me-auto">
            <CIcon icon={cilMediaStop} size="sm" style={{ margin: "5px 4px 0px 0", "--ci-primary-color": "#999" }} />
            <strong>아래 표를 더블클릭하여 문항을 작성해주세요.</strong>
          </CHeaderNav>
          <CHeaderNav className="py-1 me-3 ">
            {
              {
                insert: (
                  <span>
                    <CButton onClick={handleSubmit(onSubmit)} color="primary" className="px-4 mx-1" size="sm">
                      {siteLanguage.btnName.save}
                    </CButton>
                    <CButton onClick={() => setCloseModal()} className="px-4" size="sm" color="dark">
                      닫기
                    </CButton>
                  </span>
                ),
                select: (
                  <span>
                    {
                      <CButton onClick={(e) => clickInfoUpdate(e)} color="primary" className="px-4 mx-1" size="sm">
                        {siteLanguage.btnName.update}
                      </CButton>
                    }
                    <CButton onClick={() => setCloseModal()} className="px-4" size="sm" color="dark">
                      닫기
                    </CButton>
                  </span>
                ),
                update: (
                  <span>
                    <CLoadingButton
                      // loading={isUpdateing}
                      onClick={handleSubmit(onSubmit)}
                      color="primary"
                      size="sm"
                      className="px-4 mx-1"
                    >
                      {siteLanguage.btnName.save}
                    </CLoadingButton>
                    <CButton onClick={(e) => clickInfoReset(e, "select", true)} color="dark" className="px-4" size="sm">
                      {siteLanguage.btnName.cancel}
                    </CButton>
                  </span>
                )
              }[action]
            }
          </CHeaderNav>
        </CHeader>
        <CCard className="shadow-none">
          <CCardBody className="p-0">
            <CForm onSubmit={handleSubmit(onSubmit, onError)}>
              <CTable color="light" bordered small>
                <CTableBody>
                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray">
                      퀴즈 그룹 *
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormSelect
                        {...register("quz_grp")}
                        key={uuidv4()}
                        defaultValue={data?.quz_grp}
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                        className="form-select lh-18"
                      >
                        <option value="1">1번 그룹</option>
                        <option value="2">2번 그룹</option>
                      </CFormSelect>
                    </CTableDataCell>

                    <CTableDataCell className="sf-label" color="gray">
                      순번{/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormInput
                        {...register("qst_ord")}
                        defaultValue={data?.qst_ord}
                        size="sm"
                        className="lh-15"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      />
                    </CTableDataCell>
                  </CTableRow>

                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray">
                      문제
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormInput
                        {...register("qst_req")}
                        defaultValue={data?.qst_req}
                        size="sm"
                        className="lh-15"
                        disabled={ReadOnly}
                        readOnly={readyOnlyOnW}
                      />
                    </CTableDataCell>

                    <CTableDataCell className="sf-label" color="gray">
                      정답
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormInput
                        {...register("qst_ans")}
                        defaultValue={data?.qst_ans}
                        placeholder={errors?.ntc_nm && errors.board_nm.message}
                        size="sm"
                        className="lh-15"
                        disabled={ReadOnly}
                        readOnly={readyOnlyOnW}
                      />
                    </CTableDataCell>
                  </CTableRow>

                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray">
                      점수
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormInput
                        {...register("qst_sco")}
                        defaultValue={data?.qst_sco}
                        size="sm"
                        className="lh-15"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      />
                    </CTableDataCell>

                    <CTableDataCell className="sf-label" color="gray">
                      사용여부 {/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormSelect
                        {...register("use_yn")}
                        key={uuidv4()}
                        defaultValue={data?.use_yn}
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                        className="form-select lh-18"
                      >
                        <option value="Y">Y</option>
                        <option value="N">N</option>
                        <option value="D">D</option>
                      </CFormSelect>
                    </CTableDataCell>
                  </CTableRow>

                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray">
                      비고 {/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <CFormTextarea
                        rows={1}
                        {...register("etc")}
                        defaultValue={data?.etc}
                        placeholder={errors?.ntc_nm && errors.board_nm.message}
                        size="sm"
                        className="lh-15"
                        disabled={ReadOnly}
                        readOnly={ReadOnly}
                      />
                    </CTableDataCell>

                    <CTableDataCell className="sf-label" color="gray">
                      연관 단어
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <span className="p-2">{data?.voca_tg}</span>
                      <CFormInput
                        {...register("voca_id")}
                        defaultValue={data?.voca_id}
                        size="sm"
                        hidden={true}
                        className="lh-15"
                        // disabled={ReadOnly}
                        readOnly={true}
                      />
                    </CTableDataCell>
                  </CTableRow>

                  <CTableRow>
                    <CTableDataCell className="sf-label" color="gray">
                      등록일 {/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <span className="p-2">{data?.reg_dtm && moment.utc(data?.reg_dtm).locale("ko").format("YYYY-MM-DD")}</span>
                    </CTableDataCell>

                    <CTableDataCell className="sf-label" color="gray">
                      수정일 {/* <br />제목 */}
                    </CTableDataCell>
                    <CTableDataCell className="sf">
                      <span className="p-2">{data?.upd_dtm && moment.utc(data?.upd_dtm).locale("ko").format("YYYY-MM-DD")}</span>
                    </CTableDataCell>
                  </CTableRow>
                </CTableBody>
              </CTable>
            </CForm>
          </CCardBody>
        </CCard>
        <div className="mt-2">
          <VocaGrid callDataApi={useVocaCatMapList} clickToSet={clickToSet} modalStyle={"p-0"} />
        </div>
      </CModalBody>
    </>
  )
}

export default VocaQstDetailModal
