import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import dashboardStore from "src/GlobalStore"
import { CContainer, CHeader, CHeaderNav, CHeaderToggler, CNavLink, CNavItem, CNav } from "@coreui/react-pro"
import CIcon from "@coreui/icons-react"
import { cilMenu, cilHome, cilCalendar } from "@coreui/icons"
import { AppHeaderDropdown, AppHeaderDropdownNotif } from "./header/index"

const AppHeader = () => {
  const location = useLocation()
  const { siteLanguage, theme, asideShow, setAsideShow, setNav, setSidebarUnfoldable } = dashboardStore()
  const [activeKey] = useState(2)

  theme === "dark" ? document.body.classList.add("dark-theme") : document.body.classList.remove("dark-theme")

  useEffect(() => {
    setNav(location.pathname)
  }, [location.pathname])

  return (
    <CHeader position="sticky" className="mb-2">
      <CContainer fluid>
        {/*
        <CHeaderToggler
          className="ps-1"
          onClick={() => setSidebarShow()}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        */}

        <CHeaderToggler className="ps-1" onClick={() => setSidebarUnfoldable()}>
          <CIcon icon={cilMenu} size="lg" className="text-light icon-hover" />
        </CHeaderToggler>

        <CHeaderNav className="d-none d-md-flex me-auto">
          <CNav>
            <CNavItem>
              <CNavLink href="/#/dashboard" active={activeKey === 1} className="headerNavLink">
                <CIcon icon={cilHome} size="sm" style={{ "--ci-primary-color": "#eee", marginBottom: "2px" }} />
                <h3
                  style={{
                    display: "inline-block",
                    margin: "5px 0 0 2px",
                    fontWeight: "bold",
                    fontSize: "1em",
                    color: "#ddd"
                  }}
                >
                  {siteLanguage.header.dashboard}
                </h3>
              </CNavLink>
            </CNavItem>
            {/*
            <CNavItem>
              <CNavLink href="/#/dashboard" active={activeKey === 1} className="headerNavLink">{siteLanguage.header.dashboard}</CNavLink>
            </CNavItem>            
          */}
          </CNav>
        </CHeaderNav>
        <CHeaderNav className="ms-auto me-3">
          {/*
          <CNavItem className="me-2">
            <CContainer fluid style={{ marginTop: "4px" }}>
              <AppBreadcrumb />
            </CContainer>
          </CNavItem>
          */}

          {/*
          <CButtonGroup aria-label="Theme switch" size="sm" className="me-3">
            <CFormCheck
              type="radio"
              button={{ color: "primary" }}
              name="theme-switch"
              id="btn-light-theme"
              autoComplete="off"
              label={<CIcon icon={cilSun} />}
              checked={theme === "default"}
              onChange={() => setTheme("default", "")}
            />
            <CFormCheck
              type="radio"
              button={{ color: "primary" }}
              name="theme-switch"
              id="btn-dark-theme"
              autoComplete="off"
              label={<CIcon icon={cilMoon} />}
              checked={theme === "dark"}
              onChange={() => setTheme("dark", "-dark")}
            />
          </CButtonGroup>
          <CButtonGroup aria-label="Theme switch" size="sm">
            <CFormCheck
              type="radio"
              button={lang === "en" ? { color: "dark" } : { color: "light" }}
              name="theme-switch"
              id="btn-Eng"
              autoComplete="off"
              label="Eng"
              checked={lang === "en"}
              onChange={() => {
                setSiteEnLanguage()
              }}
              //onChange={() => setTheme('default', '')}
            />
            <CFormCheck
              type="radio"
              button={lang === "kr" ? { color: "dark" } : { color: "light" }}
              name="theme-switch"
              id="btn-Kor"
              autoComplete="off"
              label="Kor"
              checked={lang === "kr"}
              onChange={() => {
                setSiteKrLanguage()
              }}
              //onChange={() => setTheme('dark', '-dark')}
            />
          </CButtonGroup>
          */}
        </CHeaderNav>
        <CHeaderNav className="me-2">
          <AppHeaderDropdown />
        </CHeaderNav>
        {/*
        <CHeaderNav className="me-2">
          <AppHeaderDropdownNotif />
        </CHeaderNav>
        <CHeaderToggler className="px-md-0 me-md-3" onClick={() => setAsideShow(!asideShow)}>
          <CIcon
            icon={cilCalendar}
            size="lg"
            className="icon-hover"
            style={{ color: "#ddd", width: "26px", height: "26px", margin: "0px -4px -2px 2px" }}
          />
        </CHeaderToggler>
        */}
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
