import React from "react"
import { CModalBody } from "@coreui/react-pro"

import MemStdSentPage from "src/views/member/page/MemStdSentPage"

const BookedSentModal = ({ modalData }) => {
  return (
    <>
      <CModalBody className="p-1">
        <MemStdSentPage />
      </CModalBody>
    </>
  )
}

export default BookedSentModal
