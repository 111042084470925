import React from "react"
import CIcon from "@coreui/icons-react"
import { cilNotes, cilColorBorder, cilDrop, cilLayers, cilLan, cilMap } from "@coreui/icons"
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react-pro"
import dashboardStore from "src/GlobalStore"

const useNavBoard = () => {
  const { siteLanguage } = dashboardStore()

  const navBoard = [
    {
      component: CNavItem,
      name: siteLanguage.navBoard.navTitle,
      to: "/base/notice",
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />
      // ,
      // items: [
      //   {
      //     component: CNavItem,
      //     name: siteLanguage.navBoard.boardNotice,
      //     to: "/base/notice"
      //   },
      //   {
      //     component: CNavItem,
      //     name: siteLanguage.navBoard.boardCommu,
      //     to: "/base/comm"
      //   }
      // ]
    }
  ]

  return {
    navBoard
  }
}

export default useNavBoard
