import "react-app-polyfill/stable"
import "core-js"
import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { LicenseManager } from "ag-grid-enterprise"

LicenseManager.setLicenseKey(
  "CompanyName=TeamSoft,LicensedApplication=GRM-APP,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-036217,SupportServicesEnd=2_January_2024_[v2]_MTcwNDE1MzYwMDAwMA==5c997cbd65e81b22093b7aa1ad3c8980"
)

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(<App />)
