import React from "react"
import { CFooter } from "@coreui/react-pro"
import logo from "src/assets/images/teamsoft_fulllogo.png"
import dashboardStore from "src/GlobalStore"

const AppFooter = () => {
  const { theme } = dashboardStore()

  return (
    <CFooter>
      <div className="ms-1 mt-2">
        <img
          src={logo}
          alt="Grobal Compliance"
          style={{ width: "90px", marginTop: "-12px", marginLeft: "-6px", marginRight: "6px" }}
        />
        {/* <strong>TeamSoft</strong> */}
        <span style={{ marginLeft: "10px", fontSize: "0.8em" }}>OK-Voca Admin</span>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
