import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { persist, createJSONStorage } from "zustand/middleware"

const VocaQuzQstStore = (set) => ({
  action: "select",
  setAction: (_state) => set((state) => ({ action: _state })),

  vocaQuzQst: {},
  setVocaQuzQst: (_state) => set((state) => ({ vocaQuzQst: _state }))
})

const persistConfig = {
  name: "useVocaQuzQstStore",
  storage: createJSONStorage(() => sessionStorage)
}

const useVocaQuzQstStore = create(devtools(persist(VocaQuzQstStore, persistConfig), { name: persistConfig.name }))
export default useVocaQuzQstStore
