import React, { useCallback, useMemo, useEffect, useRef } from "react"

import {
  CRow,
  CCol,
  CHeader,
  CHeaderNav,
  CContainer,
  CButton,
  CInputGroup,
  CFormInput,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CModalBody
} from "@coreui/react-pro"
import CIcon from "@coreui/icons-react"
import { cilMediaStop, cilReload } from "@coreui/icons"

import { AgGridReact } from "ag-grid-react"
import "ag-grid-enterprise"
import "ag-grid-enterprise/dist/styles/ag-grid.css"
import "ag-grid-enterprise/dist/styles/ag-theme-alpine.css"
import "ag-grid-enterprise/dist/styles/ag-theme-alpine-dark.css"

//utiles
import { svcGridCommonOptions, sideBarConfig, defaultColConfig, autoGroupColumnDef } from "src/common/grid/GridConfig"
import sysCodeInfo from "src/assets/system/system.info.json"

//store
import useGlobalStore from "src/GlobalStore"
import useGridStore from "src/common/grid/store/GridStore"
import useModalStore from "../store/ModalStore"
import useVocaCatStore from "src/views/vocaCat/store/VocaCatStore"
import useVocaQuzStore from "src/views/vocaQuz/store/VocaQuzStore"
//api
import { useVocaCatMapInsert } from "src/views/vocaCatMap/api/VocaCatMapApi"
//lib
import moment from "moment"
import "moment/locale/ko"

const VocaGridModal = ({
  modalData,
  dataRegister,
  clickToSet,
  callDataApi,
  modalStyle,
  navigate,
  modalType,
  headerComponent: HeaderComponet
}) => {
  const {
    useYn,
    dataSearch,
    windowHeight,
    searchToggle,
    setWindowHeight,
    setGridStateOnReload,
    setRowDoubleClicked,
    onUseYnChange,
    onColumnRowGroupChanged,
    onSearchChange,
    onKeyDown,
    onGridReadyStore,
    onFirstDataRenderedStore,
    onRowDataUpdatedStore,
    onSortChanged,
    onFilterChanged,
    onGridSizeChanged,
    onModelUpdatedStore
  } = useGridStore()

  const pageName = "VocaGridModal"

  const gridRef = useRef(null)

  const { siteLanguage, gridLanguage } = useGlobalStore()
  const { quzCatId, vocaCat } = useVocaCatStore()
  const { quzAnsTyp } = useVocaQuzStore()
  const { setCloseModal } = useModalStore()

  //callDataApi 함수는 PORPS 전달받은 함수
  //Detail 에서는 카테고리 선택해서 들어가니깐. 이렇게 해주는게 맞는데~ 나머지 퀴즈쪽에서는 어떻게 해결을 할까
  //header componet 설정한 상태에 따라 데이터가 변겨오디어야 한다.
  const { data: vocaList, isLoading, isValidating } = callDataApi(useYn.yn, modalType === "quz_page" ? quzCatId : vocaCat?.cat_id)
  const { trigger: requestCatVocaMapInsert } = useVocaCatMapInsert()

  //store
  const auth = "S"

  const columnDefs = useMemo(
    () => [
      {
        field: "",
        width: 30,
        minWidth: 30,
        maxWidth: 30,
        editable: false,
        //check box
        // clickToSet === undefined && true,
        headerCheckboxSelection: () => clickToSet === undefined && true,
        checkboxSelection: (params) => clickToSet === undefined && true
      },
      {
        headerName: "ID",
        field: "voca_id",
        hide: true,
        width: 100,
        minWidth: 100,
        filter: "agNumberColumnFilter"
      },
      {
        headerName: siteLanguage.gridDefult.no,
        field: "id",
        cellStyle: { textAlign: "right", paddingLeft: "2px", paddingRight: "2px" },
        valueGetter: "node.rowIndex + 1",
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        getQuickFilterText: () => ""
      },
      {
        headerName: "타입",
        field: "voca_typ",
        tooltipField: "voca_typ",
        width: 100,
        minWidth: 100,
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          const catTypeObject = sysCodeInfo?.filter((code) => code.cod_mgr_no === "VOCA001" && code.cod_cd === params?.value)
          return (
            <div
              className={`rounded-pill ${catTypeObject[0]?.cod_etc} text-white h-50 d-flex justify-content-center align-items-center`}
              style={{ width: "80px" }}
            >
              {catTypeObject[0]?.cod_dsc}
            </div>
          )
        }
      },
      {
        headerName: "단어",
        field: "voca_tg",
        tooltipField: "voca_tg",
        width: 120,
        minWidth: 120,
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return (
            <div className="mt-2">
              <strong
                className="align-items-center"
                style={{
                  border: "0px solid silver",
                  borderRadius: 20,
                  padding: "3px 10px",
                  // backgroundColor: "white",
                  color: "black"
                }}
              >
                <span>{params.value}</span>
              </strong>
            </div>
          )
        }
      },
      {
        headerName: "뜻",
        field: "voca_mean",
        tooltipField: "voca_mean",
        width: 120,
        minWidth: 120,
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return (
            <div className="mt-2">
              <strong
                className="align-items-center"
                style={{
                  border: "0px solid silver",
                  borderRadius: 20,
                  padding: "3px 10px",
                  // backgroundColor: "white",
                  color: "black"
                }}
              >
                <span>{params.value}</span>
              </strong>
            </div>
          )
        }
      },
      {
        headerName: "레벨",
        cellStyle: { textAlign: "center" },
        field: "voca_lvl",
        tooltipField: "voca_lvl",
        width: 100,
        minWidth: 100,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return (
            params.value && (
              <div
                className={`rounded-pill bg-warning text-black h-50 d-flex justify-content-center align-items-center`}
                style={{ width: "80px" }}
              >
                {params.value}
              </div>
            )
          )
        }
      },
      // {
      //   headerName: "등록일",
      //   field: "reg_dtm",
      //   tooltipField: "reg_dtm",
      //   width: 100,
      //   minWidth: 100,
      //   filter: "agTextColumnFilter",
      //   cellRenderer: (params) => {
      //     return (
      //       <div className="mt-2">
      //         <span>{moment(params).format("YYYY-MM-DD")}</span>
      //       </div>
      //     )
      //   }
      // },
      {
        headerName: "수정일",
        field: "upd_dtm",
        tooltipField: "upd_dtm",
        width: 100,
        minWidth: 100,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return (
            <div>
              <span>{params.value && moment.utc(params.value).locale("ko").format("YYYY-MM-DD")}</span>
            </div>
          )
        }
      }
    ],
    [siteLanguage]
  )

  //s 필수 Grid처리 ===============================================================================
  const onGridReady = useCallback(
    (params) => {
      onGridReadyStore(params, pageName)
    },
    [vocaList]
  )

  const onFirstDataRendered = useCallback(
    (params) => {
      //   onFirstDataRenderedStore(params)
    },
    [vocaList]
  )

  const onRowDataUpdated = useCallback(
    (params) => {
      onRowDataUpdatedStore(isLoading, params)
    },
    [isLoading]
  )

  const onModelUpdated = useCallback((params) => {
    onModelUpdatedStore(params)
  }, [])

  //e 필수 Grid처리 ===============================================================================

  /* S: Action처리 ===============================================================================*/

  const clickDataReload = useCallback(() => {
    console.log(`${pageName} - clickDataReload`)
    setGridStateOnReload(gridRef)
  }, [])

  //상세 더블클릭
  const onRowDoubleClicked = useCallback(
    (params) => {
      if (params.data && clickToSet) {
        clickToSet(params.data)
      }
    },
    [clickToSet]
  )

  //검색바 동작
  const searchChange = useCallback((e) => {
    onSearchChange(e)
  }, [])

  //검색바 엔터처리
  const searchKeyDown = useCallback((e) => {
    onKeyDown(e, gridRef)
  }, [])

  const clickDataRegist = useCallback(async (e) => {
    const listData = gridRef?.current?.api?.getSelectedRows()
    // 후에 vocaGrid 에 데이터 저장하는 방식은 함수로 props 넘겨서 처리해줄것!

    if (listData.length === 0) {
      window.alert("등록할 단어를 선택해주세요.")
      return
    }

    if (dataRegister) {
      //넘기는 타입은 퀴즈 보기 그리드에서 나오는 단어를 voca_tg, voca_mean 이 결정되는 값이다.
      //VocaQuzOptpage, VocaQuzQstPage 둘다 사용하는데, vocaQuzOptPage에서는 파라미터 2 (listData, type), vocaQuzQstPage listData만 받는다
      const response = await dataRegister(listData, quzAnsTyp)
      if (response === "200") {
        setCloseModal()
      }
    }
  })

  /* E: Action처리 ===============================================================================*/

  useEffect(() => {
    window.addEventListener("resize", () => setWindowHeight(window.innerHeight))
    if (window.opener !== null) {
      document.body.classList.add("popup-no-scrollbars")
    }
    return () => {
      window.removeEventListener("resize", () => setWindowHeight(window.innerHeight))
    }
  }, [window.innerHeight])

  if (vocaList?.length === 0) {
    gridRef.current?.api?.showNoRowsOverlay()
    // return <NavigateToInsertVoca />
  }

  return (
    <div style={{ width: "1417px" }}>
      <CHeader className="header-content mt-2 mx-3 mb-0" style={{ width: "1369px" }}>
        <HeaderComponet />
        <CHeaderNav className="py-1 me-3">
          <>
            <CButton onClick={(e) => clickDataRegist(e)} color="primary" className="px-4 mx-1" size="sm">
              등록
            </CButton>
          </>
        </CHeaderNav>
      </CHeader>
      <CModalBody className={modalStyle}>
        <CContainer>
          <CRow>
            <CCol className="px-3 py-2 mb-2 border">
              <CRow>
                <CCol xs={12}>
                  <CInputGroup size="sm">
                    <CButton size="sm" className="px-3" onClick={clickDataReload}>
                      <CIcon icon={cilReload} />
                    </CButton>
                    <CDropdown variant="input-group">
                      <CDropdownToggle
                        disabled={!auth}
                        color="secondary"
                        variant="outline"
                        className="px-3"
                        style={{ fontSize: "0.8em" }}
                      >
                        {useYn.desc}
                      </CDropdownToggle>
                      <CDropdownMenu>
                        {searchToggle.map((item, index) => (
                          <CDropdownItem
                            key={index}
                            onClick={() => onUseYnChange(item.value, item.dsc)}
                            style={{ fontSize: "0.8em" }}
                          >
                            {item.name}
                          </CDropdownItem>
                        ))}
                      </CDropdownMenu>
                    </CDropdown>

                    <CFormInput onChange={searchChange} onKeyUp={searchKeyDown} value={dataSearch} placeholder="Search" />
                    <CButton type="button" color="primary" id="button-addon2" className="px-4">
                      {siteLanguage.btnName.search}
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow>
            <CCol className="p-0">
              <div className="ag-theme-alpine" style={{ height: `${windowHeight - 250}px` }}>
                <AgGridReact
                  ref={gridRef}
                  rowData={vocaList}
                  localeText={gridLanguage}
                  //Grid Config
                  columnDefs={columnDefs}
                  sideBar={useMemo(() => sideBarConfig())}
                  defaultColDef={useMemo(() => defaultColConfig())}
                  gridOptions={useMemo(() => svcGridCommonOptions())}
                  // sideBar={sideBarConfig}
                  // defaultColDef={defaultColConfig}
                  // gridOptions={svcGridCommonOptions}
                  rowGroupPanelShow="always"
                  //Grid Group Config
                  // autoGroupColumnDef={autoGroupColumnDef}
                  autoGroupColumnDef={autoGroupColumnDef}
                  //groupMaintainOrder={true}
                  //groupDisplayType={'singleColumn'}
                  //suppressDragLeaveHidesColumns={true}
                  //suppressMakeColumnVisibleAfterUnGroup={true}
                  //suppressRowGroupHidesColumns={true}
                  //groupHideOpenParents={true}
                  //masterDetail={false}
                  groupSelectsChildren={true}
                  suppressAggFuncInHeader={true}
                  groupDefaultExpanded={-1}
                  // onColumnRowGroupChanged={onColumnRowGroupChanged}
                  // noRowsOverlayComponent={noRowsOverlayComponent}
                  //Grid 이벤트
                  onRowDataUpdated={onRowDataUpdated}
                  onGridReady={onGridReady}
                  onFirstDataRendered={onFirstDataRendered}
                  onGridSizeChanged={onGridSizeChanged}
                  onRowDoubleClicked={onRowDoubleClicked}
                  onSortChanged={onSortChanged}
                  onFilterChanged={onFilterChanged}
                  onColumnRowGroupChanged={onColumnRowGroupChanged}
                  onModelUpdated={onModelUpdated}
                  //onPaginationChanged={useCallback((params) => onPaginationChanged(params))}
                  //onCellClicked={onCellClicked}
                  //Master, Detail설정
                  masterDetail={false}
                  //DateComponent
                  // domLayout="autoHeight"
                  //그외설정
                  //overlayNoRowsTemplate={"No Data"}
                  rowSelection={"multiple"}
                  suppressRowClickSelection={true}
                  suppressContextMenu={true}
                  checkboxSelection={true}
                  isRowSelectable={() => {
                    return true
                  }}
                />
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
    </div>
  )
}

export default VocaGridModal
