import React from "react"
import CIcon from "@coreui/icons-react"
import { cilBarChart, cilClipboard, cilDrop, cilList, cilLayers, cilIndustry } from "@coreui/icons"
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react-pro"
import dashboardStore from "src/GlobalStore"

const useNavInst = () => {
  const { siteLanguage } = dashboardStore()

  const navInst = [
    {
      component: CNavItem,
      name: "기관 관리",
      to: "/inst/manage",
      icon: <CIcon icon={cilIndustry} customClassName="nav-icon" />
    }
  ]

  return {
    navInst
  }
}

export default useNavInst
